@charset "utf-8";

/* ==============================================
aboutUs トップページ
============================================== */
#recruitTop {
  overflow-x: hidden;

  .mainviewSeen{
    position: fixed;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    background-color: #00232E;
    transition: opacity .8s, visibility .8s;
    z-index: 999;

    @include mq(tab) {
      max-height: 90vh;
    }

    &.none{
      display: none;
    }
    &.hidden{
      opacity: 0;
      visibility: hidden;
    }

    .scroll{
      opacity: 0;
      position: absolute;
      @include props(b, 35);
      left: 50%;
      transform: translateX(-50%);
      @include props(w, 46);
      @include props(h, 46);
      border: 1px solid #7594cf;
      font-family: $Teko;
      @include fz_vw(18);
      letter-spacing: 0.72px;
      text-align: center;
      color: #7594cf;
      background-color: #7594cf;
      border-radius: 100%;
      z-index: 9;
      mix-blend-mode: revert;
      transition: opacity .8s;

      &.isShow{
        opacity: 1;
      }

      &.isGradation{
        mix-blend-mode: multiply;
      }

      span{
        display: block;
        @include props(mt, 50);
      }

      &::after {
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        content: '';
        @include props(w, 16);
        @include props(h, 16);
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        box-sizing: border-box;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: -1;
        content: '';
        width: 0;
        height: 0;
        background-color: rgba($color: #7594cf, $alpha: .4);
        //box-shadow: 0 0 0 0 rgba($color: #7594cf, $alpha: .4);
        border-radius: 100%;
        opacity: 0;
        animation: sdb03 1.5s infinite;
      }

      @include mq(tabMin) {
        @include props(b, 135, 960);
        @include props(w, 62, 960);
        @include props(h, 62, 960);
        @include fz_vw(30);
        letter-spacing: 0.35px;

        span{
          display: none;
        }

        &::after {
          @include props(w, 22, 960);
          @include props(h, 22, 960);
        }
        &::before {
          animation: sdbSp03 1.5s infinite;
          @include props(w, 58, 960);
          @include props(h, 58, 960);
          background-color: inherit;
          box-shadow: 0 0 0 0 rgba($color: #7594cf, $alpha: .8);
        }
      }

      @include mq(md) {
        //bottom: calc(50% - 61vw);
        @include props(b, 165, 750);
        @include props(w, 62, 750);
        @include props(h, 62, 750);
        @include fz_vw(30);
        letter-spacing: 0.35px;

        span{
          display: none;
        }

        &::after {
          @include props(w, 22, 750);
          @include props(h, 22, 750);
        }
        &::before {
          animation: sdbSp03 3s infinite;
          @include props(w, 58, 750);
          @include props(h, 58, 750);
        }
      }
    }

    .commonSeen{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      transition: opacity .8s;

      &.hide{
        opacity: 0;
      }

      &.isShow{
        &::before{
          opacity: 1;
          transform: translate(-50%,-50%) scale(1);
        }
      }

      &::before{
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0;
        width: 150vmax;
        height: 150vmax;
        border-radius: 50%;
        background-image: linear-gradient(to bottom, #9fcfd3, #fcf9f5 49%, #e6c3d8);
        transform: translate(-50%, -50%) scale(0, 0);
        transition: transform 1s,opacity .8s;
        backface-visibility: hidden;
        perspective: 1000;
      }

      .wrap{
        position: absolute;
        max-width: 1366px;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        @include props(h, 768);

        @include mq(tabMin) {
          max-height: 100vh;
          height: 100vh;
        }

        @include mq(md) {
          max-height: 100vh;
          height: 100vh;
        }
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .seen05{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;

      .backImg{
        width: 100%;
        height: 100%;
        background-image: url(/images/mainview/s_5_bg_pc@2x.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include mq(tabMin) {
          background-image: url(/images/mainview/s_5_bg_sp@2x.png);
        }

        @include mq(md) {
          background-image: url(/images/mainview/s_5_bg_sp@2x.png);
        }
      }

      // .wrap{
      //   max-height: 100%;
      // }

      .mainviewTit{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: scale(0);
        transition: transform .4s;
        @include props(w, 1303);
        max-height: 100vh;
        z-index: 1;

        @include mq(tabMin) {
          display: block;
          max-width: 100%;
          height: 82%;
          @include props(t, 5, 960);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        @include mq(md) {
          display: block;
          max-width: 100%;
          height: 82%;
          @include props(t, 5, 750);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      .imgFade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
        will-change: opacity;
      }
      .img01{
        position: absolute;
        @include props(b, 40);
        left: 50%;
        transform: translateX(-50%);
        @include props(w, 1100);
        max-height: 89.5%;

        @include mq(tabMin) {
          //@include props(b, 220, 960);
          bottom: calc(50% - 33.91667vw);
          left: 50%;
          transform: translateX(-50%);
          @include props(w, 710, 960);
        }

        @include mq(md) {
          transform: none;
          //@include props(b, 220, 750);
          bottom: auto;
          top: calc(50% - 35.33333vw);
          @include props(l, 20, 750);
          @include props(w, 710, 750);
        }
      }
      .img02{
        position: absolute;
        @include props(b, 40);
        left: 50%;
        transform: translateX(-50%);
        @include props(w, 280);
        max-height: 60.2%;

        @include mq(tabMin) {
          bottom: auto;
          @include props(t, 484, 960);
          left: 50%;
          transform: translateX(-50%);
          @include props(w, 246, 960);
        }

        @include mq(md) {
          transform: none;
          bottom: auto;
          @include props(t, 484, 960);
          @include props(l, 252, 750);
          @include props(w, 246, 750);
        }
      }
      .img03{
        position: absolute;
        animation: spaceAnime02 3s linear infinite alternate;
        @include props(t, 41);
        @include props(r, 412);
        @include props(w, 190);
        max-height: 25.3%;

        @include mq(tabMin) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          @include props(t, 221, 960);
          @include props(r, 97, 960);
          @include props(w, 155, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          //@include props(t, 261, 750);
          top: calc(50% - 58.8vw);
          @include props(r, 97, 750);
          @include props(w, 155, 750);
        }
      }
    }
    .seen06{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 6;

      .wrap{
        max-height: 768px;

        @include mq(tabMin) {
          max-height: 100vh;
        }

        @include mq(md) {
          max-height: 100vh;
        }
      }

      .backImg{
        width: 100%;
        height: 100%;
        background-image: url(/images/mainview/s6_bg_pc.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include mq(tabMin) {
          background-image: url(/images/mainview/s_6_bg_sp@2x.png);
          background-size: 97.2%;
        }

        @include mq(md) {
          background-image: url(/images/mainview/s_6_bg_sp@2x.png);
          background-size: 97.2%;
        }
      }

      .mainviewTit{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: scale(0);
        transition: transform .4s;
        @include props(w, 1350);
        max-height: 100vh;
        z-index: 1;

        @include mq(tabMin) {
          display: block;
          max-width: 100%;
          height: 80%;
          @include props(t, 0);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        @include mq(md) {
          display: block;
          max-width: 100%;
          height: 80%;
          @include props(t, 0);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      .imgFade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
        will-change: opacity;
      }
      .img01{
        position: absolute;
        @include props(b, 61);
        @include props(r, 219);
        @include props(w, 855);

        @include mq(tabMin) {
          //@include props(b, 258, 960);
          bottom: calc(50% - 35.875vw);
          @include props(r, 0, 960);
          @include props(w, 800, 960);
        }

        @include mq(md) {
          //@include props(b, 258, 750);
          bottom: calc(50% - 48.4vw);
          @include props(r, 0, 750);
          @include props(w, 750, 750);
        }
      }
      .img02{
        position: absolute;
        animation: spaceAnime01 3s linear infinite alternate;
        @include props(t, 108);
        @include props(r, 28);
        @include props(w, 382);

        @include mq(tabMin) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(t, 408, 960);
          @include props(r, 31, 960);
          @include props(w, 298, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(t, 498, 750);
          @include props(r, 31, 750);
          @include props(w, 248, 750);
        }
      }
    }
  }

  .spaceArea {
    position: relative;
    background-image: url(/images/recruit/bg_outerspace.jpg);
    background-size: contain;
    background-position: top center;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1366px;
      width: 100%;
      @include props(h, 768);
      background-image: url(/images/recruit/bg_star_reverse.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      z-index: -1;
    }

    @include mq(tabMin) {
      background-image: url(/images/recruit/bg_outerspace_sp.jpg);

      &::before {
        display: none;
      }
    }
    @include mq(md) {
      background-image: url(/images/recruit/bg_outerspace_sp.jpg);

      &::before {
        display: none;
      }
    }
  }

  .gradationArea{
    @include props(pt, 100);
    @include props(pb, 120);
    background-image: linear-gradient(to bottom, rgba(159, 207, 211, .5), rgba(252, 249, 245, .5) 50%, rgba(230, 195, 216, .5));

    @include mq(tabMin) {
      @include props(pt, 110, 960);
      @include props(pb, 120, 960);
    }

    @include mq(md) {
      @include props(pt, 110, 750);
      @include props(pb, 120, 750);
    }
  }

  .mainview {
    position: relative;
    z-index: 1;
    @include props(pt, 176);

    @include mq(tabMin) {
      @include props(pt, 245, 960);
      @include props(pb, 40, 960);
    }

    @include mq(md) {
      @include props(pt, 245, 750);
      @include props(pb, 40, 750);
    }

    .container{
      position: relative;
    }

    .mainviewTit {
      opacity: 0;
      visibility: hidden;
      -webkit-text-stroke: 2px #fff;
      font-family: $Teko;
      @include fz_vw(120);
      font-weight: bold;
      line-height: 1.08;
      letter-spacing: 3.6px;
      color: rgba($color: #e0ecf4, $alpha: 0);

      &.isShow{
        opacity: 1;
        visibility: visible;
        transition: opacity .3s, visibility .3s;
      }

      @include mq(tabMin) {
        letter-spacing: 1.8px;
      }
  
      @include mq(md) {
        letter-spacing: 1.8px;
      }

      .img{
        @include props(w, 398);

        @include mq(tabMin) {
          @include props(w, 398, 960);
        }
    
        @include mq(md) {
          @include props(w, 398, 750);
        }
      }

      svg{
        max-width: 100%;
        width: 100%;
        height: auto;
        fill: transparent;
        stroke-width: 2px;
        stroke: #fff;
      }

      .lead {
        display: flex;
        align-items: center;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: normal;
        color: #fff;

        &::before {
          content: "";
          display: block;
          @include props(w, 45);
          height: 1px;
          background-color: #fff;
          @include props(mr, 15);
        }

        @include mq(tabMin) {
          @include fz_vw(28);

          &::before {
            @include props(w, 45, 750);
            @include props(mr, 15, 750);
          }
        }
    
        @include mq(md) {
          @include fz_vw(28);

          &::before {
            @include props(w, 45, 750);
            @include props(mr, 15, 750);
          }
        }
      }
    }

    .imgBox{
      @include mq(tabMin) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      @include mq(md) {
        display: flex;
        align-items: center;
      }
    }

    .img01{
      position: absolute;
      @include props(w, 121);
      @include props(t, 48);
      @include props(r, 60);
      animation: spaceAnime01 3.5s linear infinite alternate;

      @include mq(tabMin) {
        @include props(w, 121, 960);
        top: auto;
        @include props(b, -45, 960);
        @include props(r, 40, 960);
        animation: spaceAnimeSp01 3.5s linear infinite alternate;
      }
  
      @include mq(md) {
        @include props(w, 121, 750);
        top: auto;
        @include props(b, -45, 750);
        @include props(r, 40, 750);
        animation: spaceAnimeSp01 3.5s linear infinite alternate;
      }
    }
  }

  .recruitAbout{
    @include props(pt, 445);
    @include props(pb, 120);

    @include mq(tabMin) {
      @include props(pt, 548, 960);
      @include props(pb, 120, 960);
    }

    @include mq(md) {
      @include props(pt, 548, 750);
      @include props(pb, 120, 750);
    }

    .recruitAboutTitArea{
      position: absolute;
      @include props(t, -72);
      left: 50%;
      transform: translateX(-50%);
      background-image: url(/images/recruit/recruit_fv_01_pc@2x.png);
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0;
      visibility: hidden;
      @include props(w, 786);
      @include props(h, 468);

      &.isShow{
        opacity: 1;
        visibility: visible;
        transition: opacity .8s, visibility .8s;
      }

      .recruitAboutTit{
        position: absolute;
        @include props(b, 67);
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        @include props(w, 577);

        &.isShow{
          &::after{
            transform: translateX(100%);
          }
        }

        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: -50%;
          width: 150%;
          height: 100%;
          transform: translateX(0);
          background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
          transition: transform 2s;
        }
      }

      @include mq(tabMin) {
        top: 0;
        background-image: url(/images/recruit/recruit_fv_01_sp@2x.png);
        @include props(w, 670, 960);
        @include props(h, 468, 960);

        .recruitAboutTit{
          @include props(b, 64, 960);
          @include props(w, 532, 960);
        }
      }
  
      @include mq(md) {
        top: 0;
        background-image: url(/images/recruit/recruit_fv_01_sp@2x.png);
        @include props(w, 670, 750);
        @include props(h, 468, 750);

        .recruitAboutTit{
          @include props(b, 64, 750);
          @include props(w, 532, 750);
        }
      }
    }

    .recruitAboutTxt{
      @include fz_vw(18);
      font-weight: 500;
      line-height: 2;
      letter-spacing: 1.08px;
      text-align: center;
      color: #fff;

      @include mq(tabMin) {
        @include fz_vw(32);
        text-align: left;
        letter-spacing: .96px;
      }
  
      @include mq(md) {
        @include fz_vw(32);
        text-align: left;
        letter-spacing: .96px;
      }
    }
  }

  .workFlow{
    @include props(pb, 55);

    @include mq(tabMin) {
      @include props(pb, 353, 960);
    }

    @include mq(md) {
      @include props(pb, 353, 750);
    }

    .container{
      position: relative;
      z-index: 0;

      &::before{
        position: absolute;
        @include props(t, 75);
        @include props(l, 0);
        content: "";
        display: block;
        background-image: url(/images/recruit/recruit_flow_direct@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        @include props(w, 251);
        @include props(h, 224);
        z-index: -1;
        animation: spaceAnime01 3.5s linear infinite alternate;

        @include mq(fixed) {
          @include props(l, -113);
        }
        @include mq(tabMin) {
          top: auto;
          @include props(b, -353, 960);
          @include props(l, 80, 960);
          @include props(w, 341, 960);
          @include props(h, 303, 960);
          animation: spaceAnimeSp01 3.5s linear infinite alternate;
        }
        @include mq(md) {
          top: auto;
          @include props(b, -353, 750);
          @include props(l, 80, 750);
          @include props(w, 341, 750);
          @include props(h, 303, 750);
          animation: spaceAnimeSp01 3.5s linear infinite alternate;
        }
      }
      &::after{
        position: absolute;
        @include props(b, -40);
        @include props(r, -10);
        content: "";
        display: block;
        background-image: url(/images/recruit/recruit_flow_taco@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        @include props(w, 108);
        @include props(h, 84);
        animation: spaceAnime03 3.5s linear infinite alternate;

        @include mq(tabMin) {
          top: auto;
          @include props(b, -281, 960);
          @include props(r, 122, 960);
          @include props(w, 147, 960);
          @include props(h, 114, 960);
          animation: spaceAnimeSp03 3.5s linear infinite alternate;
        }
        @include mq(md) {
          top: auto;
          @include props(b, -281, 750);
          @include props(r, 122, 750);
          @include props(w, 147, 750);
          @include props(h, 114, 750);
          animation: spaceAnimeSp03 3.5s linear infinite alternate;
        }
      }
    }

    .workFlowTit{
      -webkit-text-stroke: 1px #333;
      font-family: $Teko;
      @include fz_vw(80);
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: 2.4px;
      text-align: center;
      color: rgba($color: #e0ecf4, $alpha: 0);
      @include props(mb, 88);

      .lead {
        display: block;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: 0.54px;
        color: #555;
      }

      @include mq(tabMin) {
        @include fz_vw(120);
        letter-spacing: 1.8px;
        @include props(mb, 117, 960);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
  
      @include mq(md) {
        @include fz_vw(120);
        letter-spacing: 1.8px;
        @include props(mb, 117, 750);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
    }

    .workFlowCon{
      max-width: 980px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background-image: url(/images/recruit/recruit_flow_line_pc.svg);
      background-repeat: no-repeat;
      background-size: 90%;
      background-position: center;
      margin: 0 auto;

      @include mq(tabMin) {
        display: block;
        background-image: none;
        position: relative;
        z-index: 0;

        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 5%;
          @include props(r, 50, 960);
          @include props(w, 15, 960);
          height: 90%;
          background-color: #a1d5d8;
          z-index: -1;
        }
      }
  
      @include mq(md) {
        display: block;
        background-image: none;
        position: relative;
        z-index: 0;

        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 5%;
          @include props(r, 50, 750);
          @include props(w, 15, 750);
          height: 90%;
          background-color: #a1d5d8;
          z-index: -1;
        }
      }

      .box{
        max-width: 23%;
        width: 100%;
        border-radius: 20px;
        border: solid 1px #e0ecf4;
        background-color: #fff;
        @include props(pt, 20);
        @include props(pb, 30);
        @include props(pr, 20);
        @include props(pl, 20);
        @include props(mr, 28);

        &:last-of-type{
          margin-right: 0;
          margin-bottom: 0;
        }

        &:nth-of-type(even){
          @include props(mt, 57);
        }

        &:nth-of-type(1){
          .workFlowConTit{
            @include props(pl, 38);
          }
        }
        &:nth-of-type(4){
          .workFlowConTit{
            @include props(pl, 52);
          }
        }

        @include mq(tabMin) {
          max-width: 100%;
          @include props(pt, 30, 960);
          @include props(pb, 40, 960);
          @include props(pr, 30, 960);
          @include props(pl, 30, 960);
          @include props(mr, 0);
          @include props(mb, 76, 960);

          &:nth-of-type(even){
            @include props(mt, 0);
          }

          &:nth-of-type(1){
            .workFlowConTit{
              @include props(pl, 53, 960);
            }
          }
          &:nth-of-type(4){
            .workFlowConTit{
              @include props(pl, 71, 960);
            }
          }
        }
    
        @include mq(md) {
          max-width: 100%;
          @include props(pt, 30, 750);
          @include props(pb, 40, 750);
          @include props(pr, 30, 750);
          @include props(pl, 30, 750);
          @include props(mr, 0);
          @include props(mb, 76, 750);

          &:nth-of-type(even){
            @include props(mt, 0);
          }

          &:nth-of-type(1){
            .workFlowConTit{
              @include props(pl, 53, 750);
            }
          }
          &:nth-of-type(4){
            .workFlowConTit{
              @include props(pl, 71, 750);
            }
          }
        }
      }

      .workFlowConTit{
        position: relative;
        @include fz_vw(20);
        font-weight: 500;
        line-height: 1.2;
        color: #333;
        @include props(pl, 48);
        @include props(mb, 30);

        .num{
          position: absolute;
          @include props(t, -48);
          left: 0;
          font-family: $Teko;
          @include fz_vw(100);
          font-weight: normal;
          line-height: 1;
          color: #a1d5d8;
        }

        @include mq(tabMin) {
          @include fz_vw(36);
          font-weight: bold;
          line-height: 1.33;
          @include props(pl, 66, 960);
          @include props(mb, 30, 960);

          .num{
            @include props(t, -60, 960);
            @include fz_vw(140);
          }
        }
    
        @include mq(md) {
          @include fz_vw(36);
          font-weight: bold;
          line-height: 1.33;
          @include props(pl, 66, 750);
          @include props(mb, 30, 750);

          .num{
            @include props(t, -60, 750);
            @include fz_vw(140);
          }
        }
      }

      .workFlowConTxt{
        @include fz_vw(14);
        line-height: 1.75;
        color: #333;

        @include mq(tabMin) {
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.32px;
        }
    
        @include mq(md) {
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.32px;
        }
      }
    }
  }

  .wanted{
    //@include props(pt, 55);
    @include props(pb, 50);

    @include mq(tabMin) {
      //@include props(pt, 80, 960);
      @include props(pb, 60, 960);
    }

    @include mq(md) {
      //@include props(pt, 80, 750);
      @include props(pb, 60, 750);
    }

    .wantedTit{
      -webkit-text-stroke: 1px #333;
      font-family: $Teko;
      @include fz_vw(80);
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: 2.4px;
      text-align: center;
      color: rgba($color: #e0ecf4, $alpha: 0);
      @include props(mb, 40);

      .lead {
        display: block;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: 0.54px;
        color: #555;
      }

      @include mq(tabMin) {
        @include fz_vw(100);
        line-height: 1.3;
        letter-spacing: 1.5px;
        @include props(mb, 60, 960);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
  
      @include mq(md) {
        @include fz_vw(100);
        line-height: 1.3;
        letter-spacing: 1.5px;
        @include props(mb, 60, 750);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
    }

    .wantedList{
      max-width: 980px;
      display: flex;
      flex-wrap: wrap;
      border-top: solid 1px #ccc;
      margin: 0 auto;
      @include props(pt, 40);
      @include props(pl, 110);

      @include mq(tabMin) {
        @include props(pt, 0);
        @include props(pl, 0);
      }
  
      @include mq(md) {
        @include props(pt, 0);
        @include props(pl, 0);
      }

      .wantedListTit{
        max-width: 22.99%;
        width: 100%;
        @include fz_vw(16);
        line-height: 2;
        letter-spacing: 0.96px;
        color: #333;
        @include props(mr, 20);
        @include props(mb, 25);

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(tabMin) {
          max-width: 100%;
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.32px;
          @include props(pt, 40, 960);
          @include props(pb, 30, 960);
          @include props(mr, 0);
          @include props(mb, 0);
        }
    
        @include mq(md) {
          max-width: 100%;
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.32px;
          @include props(pt, 40, 750);
          @include props(pb, 30, 750);
          @include props(mr, 0);
          @include props(mb, 0);
        }
      }
      .wantedListCon{
        max-width: 74.713%;
        width: 100%;
        @include fz_vw(16);
        font-weight: 500;
        line-height: 2;
        letter-spacing: 0.96px;
        color: #333;
        @include props(mb, 25);

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(tabMin) {
          max-width: 100%;
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.32px;
          border-bottom: solid 1px #ccc;
          @include props(pb, 30, 960);
          @include props(mb, 0);
        }
    
        @include mq(md) {
          max-width: 100%;
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.32px;
          border-bottom: solid 1px #ccc;
          @include props(pb, 30, 750);
          @include props(mb, 0);
        }
      }
    }
  }

  .application{
    @include props(pt, 50);

    @include mq(tabMin) {
      @include props(pt, 60, 960);
    }

    @include mq(md) {
      @include props(pt, 60, 750);
    }

    .container{
      position: relative;
      display: flex;
      justify-content: center;
      z-index: 0;
      @include props(pt, 30);

      &::before{
        position: absolute;
        @include props(t, 86);
        @include props(r, 47);
        content: "";
        display: block;
        background-image: url(/images/recruit/recruit_apply_illu_02@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        @include props(w, 120);
        @include props(h, 87);
        animation: spaceAnime01 3s linear infinite alternate;
      }

      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 96.492%;
        @include props(h, 367);
        border-radius: 184px;
        background-color: rgba($color: #fff, $alpha: 1);
        background-image: url(/images/recruit/recruit_apply_bg_pc.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
      }

      @include mq(tabMin) {
        flex-flow: column;
        @include props(pt, 45, 960);

        &::before{
          top: auto;
          @include props(b, 283, 960);
          @include props(r, 60, 960);
          @include props(w, 156, 960);
          @include props(h, 113, 960);
          animation: spaceAnimeSp01 3s linear infinite alternate;
        }

        &::after{
          width: calc(100% - 40px);
          @include props(h, 930, 960);
          border-radius: 15px;
          background-image: url(/images/recruit/recruit_apply_bg_sp@2x.png);
          background-size: contain;
        }
      }
  
      @include mq(md) {
        flex-flow: column;
        @include props(pt, 45, 750);

        &::before{
          top: auto;
          @include props(b, 283, 750);
          @include props(r, 60, 750);
          @include props(w, 156, 750);
          @include props(h, 113, 750);
          animation: spaceAnimeSp01 3s linear infinite alternate;
        }

        &::after{
          width: calc(100% - 40px);
          @include props(h, 930, 750);
          border-radius: 15px;
          background-image: url(/images/recruit/recruit_apply_bg_sp@2x.png);
          background-size: contain;
        }
      }
    }

    .imgBox{
      @include props(w, 534);
      @include props(mt, 90);
      @include props(mr, 42);
      @include props(ml, 20);

      @include mq(tabMin) {
        order: 2;
        margin: 0 auto;
        @include props(w, 670, 960);
        @include props(pr, 40, 960);
        @include props(pl, 40, 960);
        @include props(mt, 98, 960);
      }
  
      @include mq(md) {
        order: 2;
        margin: 0 auto;
        @include props(w, 670, 750);
        @include props(pr, 40, 750);
        @include props(pl, 40, 750);
        @include props(mt, 98, 750);
      }
    }
    .txtBox{
      @include props(mr, 80);

      @include mq(tabMin) {
        order: 1;
        @include props(pr, 40, 960);
        @include props(pl, 40, 960);
        @include props(mr, 0);
      }
  
      @include mq(md) {
        order: 1;
        @include props(pr, 40, 750);
        @include props(pl, 40, 750);
        @include props(mr, 0);
      }
    }

    .applicationTit{
      -webkit-text-stroke: 1px #333;
      font-family: $Teko;
      @include fz_vw(60);
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 1.8px;
      color: rgba($color: #e0ecf4, $alpha: 0);
      @include props(mb, 20);

      .lead {
        display: flex;
        align-items: center;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: 0.54px;
        color: #555;

        &::before {
          content: "";
          display: block;
          @include props(w, 20);
          height: 1px;
          background-color: #707070;
          @include props(mr, 10);
        }
      }

      @include mq(tabMin) {
        @include fz_vw(100);
        line-height: 1.1;
        letter-spacing: 1.5px;
        @include props(mb, 60, 960);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
          @include props(mt, 10, 960);
        }
      }
  
      @include mq(md) {
        @include fz_vw(100);
        line-height: 1.3;
        letter-spacing: 1.5px;
        text-align: center;
        @include props(mb, 60, 750);

        .lead {
          display: block;
          @include fz_vw(28);
          letter-spacing: 0.42px;

          &::before{
            display: none;
          }
        }
      }
    }

    .applicationList{
      counter-reset: listCounter;
      @include props(mb, 10);

      @include mq(tabMin) {
        @include props(mb, 20, 960);
      }
  
      @include mq(md) {
        @include props(mb, 20, 750);
      }

      .list{
        display: flex;
        align-items: center;
        @include fz_vw(16);
        line-height: 2.29;
        color: #333;

        span{
          @include fz_vw(14);
        }

        &:before {
          content: counter(listCounter);
          counter-increment: listCounter;
          border: 1px solid;
          border-radius: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          @include fz_vw(12);
          line-height: 1.5;
          @include props(w, 16);
          @include props(h, 16);
          @include props(mr, 2);
        }

        @include mq(tabMin) {
          @include fz_vw(28);
          align-items: baseline;
          line-height: 1.5;
          @include props(mb, 5, 960);

          span{
            @include fz_vw(28);
          }

          &:before {
            @include fz_vw(24);
            line-height: 2;
            @include props(w, 30, 960);
            @include props(h, 30, 960);
            @include props(mr, 2, 960);
          }
        }
    
        @include mq(md) {
          @include fz_vw(28);
          align-items: baseline;
          line-height: 1.5;
          @include props(mb, 5, 750);

          span{
            @include fz_vw(28);
          }

          &:before {
            @include fz_vw(24);
            line-height: 2;
            @include props(w, 30, 750);
            @include props(h, 30, 750);
            @include props(mr, 2, 750);
          }
        }
      }
    }

    .applicationTxt{
      @include fz_vw(13);
      line-height: 1.38;
      color: #555;
      border-top: solid 1px #e5e5e5;
      @include props(pt, 15);

      @include mq(tabMin) {
        @include fz_vw(28);
        line-height: 1.52;
        @include props(pt, 0);
      }
  
      @include mq(md) {
        @include fz_vw(21);
        line-height: 1.52;
        @include props(pt, 0);
      }
    }
  }

  .fadeInRecruit{
    opacity: 0;
  }
}