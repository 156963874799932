@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

