@charset "utf-8";

/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;

  &.isShow{
    z-index: 999;
    opacity: 1;
    visibility: visible;
  }

  .logo{
    position: fixed;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .5s;
    @include props(t, 40);
    @include props(l, 40);
    @include props(w, 160);

    &.isShow{
      transition: opacity .5s .55s, visibility .5s .55s;
      opacity: 1;
      visibility: visible;
    }

    &.isBottomShow{
      transition: opacity .5s, visibility .5s;
      opacity: 1;
      visibility: visible;
    }

    @include mq(tabMin) {
      @include props(t, 40, 960);
      @include props(l, 40, 960);
      @include props(w, 207, 960);
    }

    @include mq(md) {
      @include props(t, 40, 750);
      @include props(l, 40, 750);
      @include props(w, 207, 750);
    }
  }

  .navBtn{
    position: fixed;
    z-index: 2;
    @include props(t, 40);
    @include props(r, 40);
    @include props(w, 80);
    @include props(h, 80);
    @include props(pt, 18);
    border-radius: 100%;
    box-shadow: 0 3px 6px 0 #00000029;
    cursor: pointer;
    background-color: #e0ecf4;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .5s;

    &.isShow{
      opacity: 1;
      visibility: visible;
    }

    @include mq(tabMin) {
      @include props(t, 40, 960);
      @include props(r, 40, 960);
      @include props(w, 110, 960);
      @include props(h, 110, 960);
      @include props(pt, 27, 960);
      box-shadow: 0 2px 3px 0 #00000029;
    }

    @include mq(md) {
      @include props(t, 40, 750);
      @include props(r, 40, 750);
      @include props(w, 110, 750);
      @include props(h, 110, 750);
      @include props(pt, 27, 750);
      box-shadow: 0 2px 3px 0 #00000029;
    }

    .deco{
      position: relative;
      margin: 0 auto;
      @include props(w, 26);
      @include props(h, 8);

      @include mq(tabMin) {
        @include props(w, 48, 960);
        @include props(h, 12, 960);
      }

      @include mq(md) {
        @include props(w, 48, 750);
        @include props(h, 12, 750);
      }
    }

    span {
      display: inline-block;
      position: absolute;
      left: 0;
      @include props(w, 26);
      height: 1px;
      background-color: #333;
      transition: .4s ease-in-out;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        bottom: 0;
      }

      @include mq(tabMin) {
        @include props(w, 48, 960);
      }

      @include mq(md) {
        @include props(w, 48, 750);
      }
    }

    &.isOpen {
      background-color: #fff;
      span {
        &:nth-of-type(1) {
          transform: rotate(-150deg);
        }
        &:nth-of-type(2) {
          transform: rotate(150deg);
          top: 0;
        }
      }
    }

    .navBtnTxt{
      font-family: $Teko;
      @include fz_vw(16);
      font-weight: 300;
      line-height: 1.88;
      letter-spacing: 0.48px;
      text-align: center;
      color: #333;
      @include props(mt, 2);

      @include mq(tabMin) {
        @include fz_vw(30);
        letter-spacing: 0.45px;
        @include props(mt, 5, 960);
      }

      @include mq(md) {
        @include fz_vw(30);
        letter-spacing: 0.45px;
        @include props(mt, 5, 750);
      }
    }
  }

  .globalNavArea{
    // opacity: 0;
    visibility: hidden;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to bottom, #9fcfd3 , #fcf9f5 50%, #e6c3d8 );
    z-index: 1;
    transform: translate(-50%, -50%) scale(0, 0);
    transition: transform .3s .55s, opacity .4s .55s, visibility .4s .55s;
    overflow-y: scroll;
    -webkit-backface-visibility: hidden;

    &::before{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(/images/common/menu_bg_pc@2x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 0;
    }
    
    .wrap{
      width: 100%;
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      @include props(pt, 140);
      @include props(pb, 180);
    }

    .globalNavAreaContents{
      text-align: center;
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
      transition: transform .5s, opacity .5s, visibility .5s;
      backface-visibility: hidden;
      perspective: 1000;
      will-change: opacity,transform;
    }

    &.isShow{
      transition: transform .3s, opacity .4s ease-out, visibility .4s ease-out;
      transform: translate(-50%,-50%) scale(1, 1);
      opacity: 1;
      visibility: visible;

      .globalNavAreaContents{
        transition: transform .5s .55s, opacity .5s .55s, visibility .5s .55s;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }

    @include mq(tabMin) {
      &::before{
        background-image: url(/images/common/menu_bg_sp@2x.png);
      }
      .wrap{
        @include props(pt, 240, 960);
        @include props(pb, 240, 960);
      }
    }

    @include mq(md) {
      &::before{
        background-image: url(/images/common/menu_bg_sp@2x.png);
      }
      .wrap{
        @include props(pt, 240, 750);
        @include props(pb, 240, 750);
      }
    }

    .globalNav{
      font-family: $Teko;
      @include fz_vw(60);
      font-weight: 300;
      letter-spacing: 1.8px;
      text-align: center;
      color: #333;
      @include props(mb, 10);

      a{
        color: #333;
      }

      @include mq(tabMin) {
        @include fz_vw(80);
        letter-spacing: 1.2px;
        @include props(mb, 20, 960);
      }

      @include mq(md) {
        @include fz_vw(80);
        letter-spacing: 1.2px;
        @include props(mb, 20, 750);
      }
    }

    .accordionBtn{
      position: relative;
      display: inline-block;
      cursor: pointer;

      &.isOpen{
        &::after{
          transform: translateY(-50%) rotate(0deg);
        }
      }

      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        background-image: url(/images/common/icon_arow_up.svg);
        background-repeat: no-repeat;
        background-size: contain;
        transition: all .3s;
        @include props(w, 17);
        @include props(h, 15);
        @include props(r, -37);

        @include mq(tabMin) {
          @include props(w, 17, 960);
          @include props(h, 15, 960);
          @include props(r, -40, 960);
        }

        @include mq(md) {
          @include props(w, 17, 750);
          @include props(h, 15, 750);
          @include props(r, -40, 750);
        }
      }
    }

    dd{
      display: none;
      @include props(pb, 20);

      @include mq(tabMin) {
        @include props(pb, 60, 960);
      }

      @include mq(md) {
        @include props(pb, 60, 750);
      }
    }

    .globalNavCon{
      font-family: $Teko;
      @include fz_vw(28);
      font-weight: 300;
      letter-spacing: 0.84px;
      text-align: center;
      color: #333;
      @include props(mb, 10);

      &:last-of-type{
        margin-bottom: 0;
      }

      a{
        color: #333;
      }

      @include mq(tabMin) {
        @include fz_vw(48);
        @include props(mb, 20, 960);
      }

      @include mq(md) {
        @include fz_vw(48);
        @include props(mb, 20, 750);
      }
    }

    .mailBtn{
      @include props(mt, 40);
      @include props(mb, 35);

      @include mq(tabMin) {
        @include props(mt, 87, 960);
        @include props(mb, 100, 960);
      }

      @include mq(md) {
        @include props(mt, 87, 750);
        @include props(mb, 100, 750);
      }
    }

    .snsBtn{
      @include props(w, 30);
      margin: 0 auto;

      @include mq(tabMin) {
        @include props(w, 62, 960);
      }

      @include mq(md) {
        @include props(w, 62, 750);
      }
    }
  }
}