@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
  @include mq(tabMin02) {
    width: 3px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(tabMin) {
    display: none !important;
  }
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(tabMin) {
    display: block !important;
  }
  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;

  &.fixedBody {
    position: fixed !important;
    width: 100%;
    height: 100vh;
  }

  &.noScroll {
    overflow: hidden !important;
  }
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.container {
  max-width: 1140px;
  padding: 0 20px;
  margin: auto;
}

a {
  color: #555;
}
/* 共通デザイン
------------------------------------------------------------- */
.loaderBg {
  width: 100%;
  height: 100%;
  background-color: #00232E;
  background-image: url(/images/common/loading_bg_pc.jpg);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @include mq(tabMin) {
    background-image: url(/images/common/loading_bg_sp.jpg);
    background-size: contain;
  }

  @include mq(md) {
    background-image: url(/images/common/loading_bg_sp.jpg);
    background-size: contain;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // .spaceship {
    //   &__line {
    //     transform-origin: center right;
    //     stroke-dasharray: 38;
    //     animation: line 0.8s ease-in-out infinite;
    //     animation-fill-mode: both;
        
    //     &--top {
    //       animation-delay: 0s;
    //     }
        
    //     &--middle {
    //       animation-delay: 0.2s;
    //     }
        
    //     &--bottom {
    //       animation-delay: 0.4s;
    //     }
    //   }

    //   @include mq(tabMin) {
    //     width: 40px;
    //   }
  
    //   @include mq(md) {
    //     width: 40px;
    //   }
    // }

    span{
      transform-origin: center right;
      display: block;
      background-color: #fff;
      @include props(w, 37);
      height: 2px;
      @include props(mb, 10);
      transform: scale(0 ,1);
      animation-fill-mode: both;

      &:nth-of-type(1){
        animation: line02 1s -0.1s ease-in-out infinite;
      }
      &:nth-of-type(2){
        animation: line02 1s -0.5s ease-in-out infinite;
      }
      &:nth-of-type(3){
        animation: line02 1s -0.7s ease-in-out infinite;
        @include props(mb, 0);
      }

      @include mq(tabMin) {
        @include props(w, 60, 960);
        @include props(mb, 15, 960);
      }
  
      @include mq(md) {
        @include props(w, 60, 750);
        @include props(mb, 15, 750);
      }
    }

    .imgBox{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img02{
      @include props(w, 103);
      @include props(ml, 15);
      flex-shrink: 0;
      animation: spaceAnime02 .8s linear infinite alternate;

      @include mq(tabMin) {
        @include props(w, 165, 960);
        @include props(ml, 13, 960);
      }
  
      @include mq(md) {
        @include props(w, 165, 750);
        @include props(ml, 13, 750);
      }
    }

    p{
      font-family: $Teko;
      @include fz_vw(26);
      font-weight: 500;
      line-height: 0.83;
      letter-spacing: 2.6px;
      text-align: center;
      color: #fff;
      @include props(mt, 20);

      @include mq(tabMin) {
        @include fz_vw(48);
        letter-spacing: 2.4px;
        @include props(mt, 40, 960);
      }
  
      @include mq(md) {
        @include fz_vw(48);
        letter-spacing: 2.4px;
        @include props(mt, 40, 750);
      }
    }
  }
}

.btnWhite01{
  @include props(w, 220);

  a{
    position: relative;
    display: block;
    width: 100%;
    @include fz_vw(14);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1.4px;
    text-align: center;
    text-transform: uppercase;
    color: #333;
    border-radius: 28px;
    box-shadow: 0 3px 6px 0 #00000029;
    background-color:#fff;
    transition: all .3s;
    @include pd(18, 25);

    &::after{
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(/images/common/icon_arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: all .3s;
      @include props(w, 6);
      @include props(h, 12);
      @include props(r, 20);
    }

    &:hover{
      color: #fff;
      background-color:#333;

      &::after{
        background-image: url(/images/common/icon_arrow_white.svg);
      }
    }
  }

  &.back{
    a{
      &::after{
        transform: translateY(-50%) rotate(180deg);
        right: auto;
        @include props(l, 20);
      }
    }
  }

  @include mq(tabMin) {
    @include props(w, 406, 960);

    a{
      @include fz_vw(28);
      border-radius: 80px;
      box-shadow: 0 2px 3px 0 #00000029;
      @include pd(30, 55, 960);
  
      &::after{
        @include props(w, 15, 960);
        @include props(h, 30, 960);
        @include props(r, 40, 960);
      }
    }

    &.back{
      a{
        &::after{
          @include props(l, 40, 960);
        }
      }
    }
  }

  @include mq(md) {
    @include props(w, 406, 750);

    a{
      @include fz_vw(28);
      border-radius: 80px;
      box-shadow: 0 2px 3px 0 #00000029;
      @include pd(30, 55, 750);
  
      &::after{
        @include props(w, 15, 750);
        @include props(h, 30, 750);
        @include props(r, 40, 750);
      }
    }

    &.back{
      a{
        &::after{
          @include props(l, 40, 750);
        }
      }
    }
  }
}

.mailBtn{
  @include props(w, 234);
  margin: 0 auto;

  a{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $Teko;
    @include fz_vw(24);
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.72px;
    color: #555;
    border-radius: 27px;
    border: solid 1px #555;
    background-color: #fff;
    transition: all .3s;
    @include pd(10, 0);

    &::before{
      content: "";
      display: block;
      flex-shrink: 0;
      background-image: url(/images/common/icon_contact.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: all .3s;
      @include props(w, 21);
      @include props(h, 16);
      @include props(mr, 20);
    }

    &:hover{
      color: #fff;
      background-color:#555;

      &::before{
        background-image: url(/images/common/icon_contact_white.svg);
      }
    }
  }

  @include mq(tabMin) {
    @include props(w, 430, 960);

    a{
      @include fz_vw(40);
      letter-spacing: 0.6px;
      border-radius: 80px;
      @include pd(20, 0, 960);

      &::before{
        @include props(w, 41, 960);
        @include props(h, 32, 960);
        @include props(mr, 24, 960);
      }
    }
  }

  @include mq(md) {
    @include props(w, 430, 750);

    a{
      @include fz_vw(40);
      letter-spacing: 0.6px;
      border-radius: 80px;
      @include pd(20, 0, 750);

      &::before{
        @include props(w, 41, 750);
        @include props(h, 32, 750);
        @include props(mr, 24, 750);
      }
    }
  }
}

#lastContant{
  position: relative;
  z-index: 1;

  &.scrollDown{
    &::before{
      opacity: 1;
      visibility: visible;
    }
  }

  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .8s;
    z-index: 1;
  }
}

#notFoundTop{
  .spaceArea{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-height: 100vh;
    text-align: center;
    background-image: url(/images/works/bg_outerspace.jpg);
    background-position: center;
    padding: 0 20px;

    @include mq(tabMin) {
      background-image: url(/images/works/bg_outerspace_sp.jpg);
    }
    @include mq(md) {
      background-image: url(/images/works/bg_outerspace_sp.jpg);
    }

    h2{
      -webkit-text-stroke: 2px #fff;
      font-family: $Teko;
      @include fz_vw(120);
      font-weight: bold;
      line-height: 1;
      letter-spacing: 3.6px;
      text-align: center;
      color: rgba($color: #e0ecf4, $alpha: 0);
  
      @include mq(tabMin) {
        letter-spacing: 1.8px;
      }
    
      @include mq(md) {
        letter-spacing: 1.8px;
      }
    }

    .lead {
      @include fz_vw(24);
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 1.44px;
      text-align: center;
      color: #fff;
      @include props(mt, 30);

      @include mq(tabMin) {
        @include fz_vw(42);
        @include props(mt, 60, 960);
      }
  
      @include mq(md) {
        @include fz_vw(42);
        @include props(mt, 60, 750);
      }
    }

    p{
      @include fz_vw(16);
      line-height: 1.8;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      @include props(mt, 10);

      @include mq(tabMin) {
        display: inline-block;
        @include fz_vw(32);
        text-align: left;
        @include props(mt, 30, 960);
      }
    
      @include mq(md) {
        display: inline-block;
        @include fz_vw(32);
        text-align: left;
        @include props(mt, 30, 750);
      }
    }

    .btnWhite01{
      @include props(mt, 80);

      @include mq(tabMin) {
        margin: 0 auto;
        @include props(mt, 100, 960);
      }
      @include mq(md) {
        margin: 0 auto;
        @include props(mt, 100, 750);
      }
    }
  }
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.bottom{
  .breadcrumb{
    background-color: rgba(230, 195, 216, .5);
  }
}
.breadcrumb {
  position: relative;
  z-index: 3;
  width: 100%;
  margin: 0 auto;
  clear: both;
  padding: 15px 0;
  background-color: #e6c3d8;
  overflow: hidden;

  &.scrollDown{
    &::before{
      opacity: 1;
      visibility: visible;
    }
  }

  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 102%;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .8s;
    z-index: -1;
  }

  ol {
    @include clearfix;
    position: relative;
    width: 100%;
    word-break: break-all;
    text-align: left;
    line-height: 1;
    color: #acabab;
    padding: 15px 25px;

    @include mq(tabMin) {
      max-width: initial;
      padding: 10px 20px;
    }

    @include mq(md) {
      max-width: initial;
      padding: 10px 20px;
    }

    &>li { 
      position: relative;
      display: inline;
      @include fz_vw(12);
      line-height: 1.5;
      letter-spacing: .42px;
      text-align: left;
      color: #808080;

      @include mq(tabMin) {
        @include fz_vw(22);
      }

      @include mq(md) {
        @include fz_vw(22);
      }

      &:first-of-type{
        @include props(pl, 20);

        @include mq(tabMin) {
          @include props(pl, 30, 960);
        }

        @include mq(md) {
          @include props(pl, 30, 750);
        }
        
        &::before {
          position: absolute;
          top: 55%;
          left: 0;
          transform: translateY(-50%);
          @include props(w, 14);
          @include props(h, 8);
          margin: auto;
          content: "";
          background-image: url("/images/common/icon_home.svg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;

          @include mq(tabMin) {
            @include props(w, 20, 960);
            @include props(h, 20, 960);
          }
    
          @include mq(md) {
            @include props(w, 20, 750);
            @include props(h, 20, 750);
          }
        }
      }

      a {
        @include fz_vw(12);
        line-height: 1.5;
        letter-spacing: .42px;
        text-align: left;
        color: #808080;

        @include mq(tabMin) {
          @include fz_vw(22);
        }

        @include mq(md) {
          @include fz_vw(22);
        }
      }

      &+li::before {
        @include props(pr, 12);
        @include props(pl, 10);
        color: #ACABAB;
        content: ">";

        @include mq(tabMin) {
          @include props(pr, 14, 960);
          @include props(pl, 10, 960);
        }

        @include mq(md) {
          @include props(pr, 14, 750);
          @include props(pl, 10, 750);
        }
      }
    }
  }
}