@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#indexTop{
  overflow-x: hidden;
  
  .mainview{
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    background-color: #00232E;

    @include mq(tab) {
      max-height: 90vh;
    }

    &.scrollDown{
      .commonSeen{
        display: none;
      }
    }

    .scroll{
      opacity: 0;
      position: absolute;
      @include props(b, 35);
      left: 50%;
      transform: translateX(-50%);
      @include props(w, 46);
      @include props(h, 46);
      border: 1px solid #7594cf;
      font-family: $Teko;
      @include fz_vw(18);
      letter-spacing: 0.72px;
      text-align: center;
      color: #7594cf;
      background-color: #7594cf;
      border-radius: 100%;
      z-index: 9;
      mix-blend-mode: revert;
      transition: opacity .8s;

      &.isShow{
        opacity: 1;
      }

      &.isGradation{
        mix-blend-mode: multiply;
      }

      span{
        display: block;
        @include props(mt, 50);
      }

      &::after {
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        content: '';
        @include props(w, 16);
        @include props(h, 16);
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        box-sizing: border-box;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: -1;
        content: '';
        width: 0;
        height: 0;
        background-color: rgba($color: #7594cf, $alpha: .4);
        //box-shadow: 0 0 0 0 rgba($color: #7594cf, $alpha: .4);
        border-radius: 100%;
        opacity: 0;
        animation: sdb03 1.5s infinite;
      }

      @include mq(tabMin) {
        @include props(b, 135, 960);
        @include props(w, 62, 960);
        @include props(h, 62, 960);
        @include fz_vw(30);
        letter-spacing: 0.35px;

        span{
          display: none;
        }

        &::after {
          @include props(w, 22, 960);
          @include props(h, 22, 960);
        }
        &::before {
          animation: sdbSp03 1.5s infinite;
          @include props(w, 58, 960);
          @include props(h, 58, 960);
          background-color: inherit;
          box-shadow: 0 0 0 0 rgba($color: #7594cf, $alpha: .8);
        }
      }

      @include mq(md) {
        //bottom: calc(50% - 61vw);
        @include props(b, 165, 750);
        @include props(w, 62, 750);
        @include props(h, 62, 750);
        @include fz_vw(30);
        letter-spacing: 0.35px;

        span{
          display: none;
        }

        &::after {
          @include props(w, 22, 750);
          @include props(h, 22, 750);
        }
        &::before {
          animation: sdbSp03 3s infinite;
          @include props(w, 58, 750);
          @include props(h, 58, 750);
        }
      }
    }

    .seen01{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(/images/mainview/s_1_bg@2x.jpg);
      background-position: center;
      background-size: cover;
      z-index: 1;
      transition: opacity .8s;
      opacity: 0;

      &.isShow{
        opacity: 1;
      }

      @include mq(tabMin) {
        background-image: url(/images/mainview/s_1_bg_sp@2x.png);
        background-color: #08222d;
      }

      @include mq(md) {
        background-image: url(/images/mainview/s_1_bg_sp@2x.png);
        background-color: #08222d;
      }

      .wrap{
        position: absolute;
        max-width: 1366px;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        @include props(h, 768);

        @include mq(tabMin) {
          max-height: 100vh;
          height: 100vh;
        }

        @include mq(md) {
          max-height: 100vh;
          height: 100vh;
        }
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      .mainviewTit{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        @include props(w, 714);
        z-index: 1;
        opacity: 0;

        &.isShow{
          opacity: 1;
          .boundAnime{
            animation: bound .9s linear;
          }
        }

        @include mq(tabMin) {
          @include props(w, 660, 960);
        }

        @include mq(md) {
          @include props(w, 660, 750);
        }
      }
      .fade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
      }
      .move01{
        position: relative;
        top: -70px;
        left: -80px;

        @include mq(md) {
          top: -35px;
          left: -40px;
        }
      }
      .move02{
        position: relative;
        top: -60px;
        left: -30px;

        @include mq(md) {
          top: -30px;
          left: -15px;
        }
      }
      .move03{
        position: relative;
        top: -60px;
        right: -90px;

        @include mq(md) {
          top: -30px;
          right: -45px;
        }
      }
      .move04{
        position: absolute;
        bottom: -60px;
        right: -60px;

        @include mq(md) {
          bottom: -30px;
          left: -30px;
        }
      }
      .move05{
        position: absolute;
        bottom: -60px;
        left: -60px;

        @include mq(md) {
          bottom: -30px;
          right: -30px;
        }
      }
      .img01{
        position: absolute;
        @include props(t, 18);
        @include props(l, 34);
        @include props(w, 487);
        transform-origin: center bottom;
        animation: spaceAnime01 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(t, 51, 960);
          @include props(l, 13, 960);
          @include props(w, 417, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(t, 51, 750);
          @include props(l, 13, 750);
          @include props(w, 417, 750);
        }
      }
      .img02{
        position: absolute;
        @include props(t, 29);
        @include props(l, 502);
        @include props(w, 143);
        animation: spaceAnime02 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          @include props(t, 33, 960);
          @include props(l, 458, 960);
          @include props(w, 107, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          @include props(t, 33, 750);
          @include props(l, 458, 750);
          @include props(w, 107, 750);
        }
      }
      .img03{
        position: absolute;
        @include props(t, 29);
        @include props(r, 29);
        @include props(w, 414);
        animation: spaceAnime03 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp03 3s linear infinite alternate;
          @include props(t, 165, 960);
          @include props(r, 18, 960);
          @include props(w, 334, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp03 3s linear infinite alternate;
          @include props(t, 165, 750);
          @include props(r, 18, 750);
          @include props(w, 334, 750);
        }
      }
      .img04{
        position: absolute;
        @include props(b, 10);
        @include props(r, 19);
        @include props(w, 556);
        animation: spaceAnime01 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(b, 45, 960);
          @include props(r, 18, 960);
          @include props(w, 481, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(b, 45, 750);
          @include props(r, 18, 750);
          @include props(w, 481, 750);
        }
      }
      .img05{
        position: absolute;
        @include props(b, 18);
        @include props(l, 75);
        @include props(w, 407);
        animation: spaceAnime02 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          @include props(b, 239, 960);
          @include props(l, 33, 960);
          @include props(w, 333, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          @include props(b, 239, 750);
          @include props(l, 33, 750);
          @include props(w, 333, 750);
        }
      }
    }

    .commonSeen{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      transition: opacity .8s;

      &.hide{
        opacity: 0;
      }

      &.isShow{
        &::before{
          opacity: 1;
          transform: translate(-50%,-50%) scale(1);
        }
      }

      &::before{
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0;
        width: 150vmax;
        height: 150vmax;
        border-radius: 50%;
        background-image: linear-gradient(to bottom, #9fcfd3, #fcf9f5 49%, #e6c3d8);
        transform: translate(-50%, -50%) scale(0, 0);
        transition: transform 1s,opacity .8s;
        backface-visibility: hidden;
        perspective: 1000;
      }

      .wrap{
        position: absolute;
        max-width: 1366px;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        @include props(h, 768);

        @include mq(tabMin) {
          max-height: 100vh;
          height: 100vh;
        }

        @include mq(md) {
          max-height: 100vh;
          height: 100vh;
        }
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .seen02{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      @include pd(20, 8);

      @include mq(tabMin) {
        @include pd(26, 0, 960);
      }

      @include mq(md) {
        @include pd(26, 0, 750);
      }

      .backImg{
        width: 100%;
        height: 100%;
        background-image: url(/images/mainview/s_2_bg_pc@2x.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include mq(tabMin) {
          background-image: url(/images/mainview/s_2_bg_sp@2x.png);
        }

        @include mq(md) {
          background-image: url(/images/mainview/s_2_bg_sp@2x.png);
        }
      }

      .mainviewTit{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: scale(0);
        transition: transform .4s;
        z-index: 1;
        @include props(w, 1257);
        max-height: 100vh;

        @include mq(tabMin) {
          display: block;
          max-width: 100%;
          height: 76%;
          @include props(t, 45, 960);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        @include mq(md) {
          display: block;
          max-width: 100%;
          height: 76%;
          @include props(t, 45, 750);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      .imgFade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
        animation: spaceAnime01 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
        }

        @include mq(md) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
        }
      }
      .img01{
        position: absolute;
        @include props(t, 20);
        @include props(r, 54);
        @include props(w, 555);

        @include mq(tabMin) {
          top: calc(50% - 29.16667vw);
          //@include props(t, 280, 960);
          @include props(r, -18, 960);
          @include props(w, 433, 960);
        }

        @include mq(md) {
          top: calc(50% - 42.66667vw);
          //@include props(t, 320, 750);
          @include props(r, -58, 750);
          @include props(w, 433, 750);
        }
      }
      .img02{
        position: absolute;
        @include props(t, 217);
        @include props(l, 26);
        @include props(w, 597);

        @include mq(tabMin) {
          top: auto;
          bottom: calc(50% - 31.66667vw);
          //@include props(b, 304, 960);
          @include props(l, -65, 960);
          @include props(w, 474, 960);
        }

        @include mq(md) {
          top: auto;
          bottom: calc(50% - 45.2vw);
          //@include props(b, 264, 750);
          @include props(l, -105, 750);
          @include props(w, 474, 750);
        }
      }
    }
    .seen03{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;

      .wrap{
        max-height: 768px;

        @include mq(tabMin) {
          max-height: 100vh;
        }

        @include mq(md) {
          max-height: 100vh;
        }
      }

      .backImg{
        width: 100%;
        height: 100%;
        background-image: url(/images/mainview/s_3_bg_pc@2x.png);
        background-size: contain;
        background-position: center;

        @include mq(tabMin) {
          background-image: url(/images/mainview/s_3_bg_sp@2x.png);
        }

        @include mq(md) {
          background-image: url(/images/mainview/s_3_bg_sp@2x.png);
        }
      }

      .mainviewTit{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: scale(0);
        transition: transform .4s;
        z-index: 1;
        @include props(w, 1289);
        max-height: 100vh;

        @include mq(tabMin) {
          display: block;
          max-width: 100%;
          height: 76%;
          @include props(t, 45, 960);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        @include mq(md) {
          display: block;
          max-width: 100%;
          height: 76%;
          @include props(t, 45, 750);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      .imgFade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
      }
      .img01{
        position: absolute;
        @include props(t, 95);
        @include props(r, 107);
        @include props(w, 543);
        animation: spaceAnime03 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp03 3s linear infinite alternate;
          //@include props(t, 230, 960);
          top: calc(36% - 25.95833vw);
          @include props(r, 56, 960);
          @include props(w, 423, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp03 3s linear infinite alternate;
          //@include props(t, 230, 750);
          top: calc(36% - 32.66667vw);
          @include props(r, 56, 750);
          @include props(w, 423, 750);
        }
      }
      .img02{
        position: absolute;
        @include props(b, 98);
        @include props(l, 93);
        @include props(w, 544);
        animation: spaceAnime01 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          //@include props(b, 295, 960);
          bottom: calc(45% - 24.72917vw);
          @include props(l, 62, 960);
          @include props(w, 419, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          //@include props(b, 295, 750);
          bottom: calc(45% - 36.33333vw);
          @include props(l, 62, 750);
          @include props(w, 419, 750);
        }
      }
    }
    .seen04{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      @include pd(25, 0);

      @include mq(tabMin) {
        @include pd(50, 0, 960);
      }

      @include mq(md) {
        @include pd(50, 0, 750);
      }

      // .wrap{
      //   max-width: 100%;
      //   max-height: 100%;
      // }

      .backImg{
        width: 100%;
        height: 100%;
        background-image: url(/images/mainview/s4_bg_pc.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include mq(full) {
          background-size: contain;
        }

        @include mq(tabMin) {
          background-image: url(/images/mainview/s_4_bg_sp@2x.png);
          background-size: contain;
        }

        @include mq(md) {
          background-image: url(/images/mainview/s_4_bg_sp@2x.png);
          background-size: contain;
        }
      }

      .mainviewTit{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: scale(0);
        transition: transform .4s;
        @include props(w, 1326);
        max-height: 100vh;
        z-index: 1;

        @include mq(tabMin) {
          display: block;
          max-width: 100%;
          height: 82%;
          @include props(t, 0, 960);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        @include mq(md) {
          display: block;
          max-width: 100%;
          height: 82%;
          @include props(t, 0, 750);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      .imgFade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
        animation: spaceAnime02 3s linear infinite alternate;

        @include mq(tabMin) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
        }

        @include mq(md) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
        }
      }
      .img01{
        position: absolute;
        @include props(t, 25);
        @include props(r, 78);
        @include props(w, 856);

        @include mq(fixed) {
          right: 12%;
          max-width: 50%;
        }

        @include mq(tabMin) {
          //@include props(t, 284, 960);
          top: calc(50% - 39.58333vw);
          @include props(r, 79, 960);
          @include props(w, 747, 960);
        }

        @include mq(md) {
          //@include props(t, 324, 750);
          top: calc(50% - 43.2vw);
          @include props(r, 39, 750);
          @include props(w, 647, 750);
        }
      }
      .img02{
        position: absolute;
        @include props(b, 97);
        @include props(l, 88);
        @include props(w, 733);

        @include mq(fixed) {
          bottom: 11%;
          left: 10%;
          max-width: 50%;
        }

        @include mq(tabMin) {
          //@include props(b, 209, 960);
          bottom: calc(50% - 41.77083vw);
          @include props(l, 11, 960);
          @include props(w, 442, 960);
        }

        @include mq(md) {
          //@include props(b, 219, 750);
          bottom: calc(50% - 49.2vw);
          @include props(l, 11, 750);
          @include props(w, 442, 750);
        }
      }
    }
    .seen05{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;

      .backImg{
        width: 100%;
        height: 100%;
        background-image: url(/images/mainview/s_5_bg_pc@2x.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include mq(tabMin) {
          background-image: url(/images/mainview/s_5_bg_sp@2x.png);
        }

        @include mq(md) {
          background-image: url(/images/mainview/s_5_bg_sp@2x.png);
        }
      }

      // .wrap{
      //   max-height: 100%;
      // }

      .mainviewTit{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: scale(0);
        transition: transform .4s;
        @include props(w, 1303);
        max-height: 100vh;
        z-index: 1;

        @include mq(tabMin) {
          display: block;
          max-width: 100%;
          height: 82%;
          @include props(t, 5, 960);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        @include mq(md) {
          display: block;
          max-width: 100%;
          height: 82%;
          @include props(t, 5, 750);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      .imgFade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
        will-change: opacity;
      }
      .img01{
        position: absolute;
        @include props(b, 40);
        left: 50%;
        transform: translateX(-50%);
        @include props(w, 1100);
        max-height: 89.5%;

        @include mq(tabMin) {
          //@include props(b, 220, 960);
          bottom: calc(50% - 33.91667vw);
          left: 50%;
          transform: translateX(-50%);
          @include props(w, 710, 960);
        }

        @include mq(md) {
          transform: none;
          //@include props(b, 220, 750);
          bottom: auto;
          top: calc(50% - 35.33333vw);
          @include props(l, 20, 750);
          @include props(w, 710, 750);
        }
      }
      .img02{
        position: absolute;
        @include props(b, 40);
        left: 50%;
        transform: translateX(-50%);
        @include props(w, 280);
        max-height: 60.2%;

        @include mq(tabMin) {
          bottom: auto;
          @include props(t, 484, 960);
          left: 50%;
          transform: translateX(-50%);
          @include props(w, 246, 960);
        }

        @include mq(md) {
          transform: none;
          bottom: auto;
          @include props(t, 484, 960);
          @include props(l, 252, 750);
          @include props(w, 246, 750);
        }
      }
      .img03{
        position: absolute;
        animation: spaceAnime02 3s linear infinite alternate;
        @include props(t, 41);
        @include props(r, 412);
        @include props(w, 190);
        max-height: 25.3%;

        @include mq(tabMin) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          @include props(t, 221, 960);
          @include props(r, 97, 960);
          @include props(w, 155, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp02 3s linear infinite alternate;
          //@include props(t, 261, 750);
          top: calc(50% - 58.8vw);
          @include props(r, 97, 750);
          @include props(w, 155, 750);
        }
      }
    }
    .seen06{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 6;

      .wrap{
        max-height: 768px;

        @include mq(tabMin) {
          max-height: 100vh;
        }

        @include mq(md) {
          max-height: 100vh;
        }
      }

      .backImg{
        width: 100%;
        height: 100%;
        background-image: url(/images/mainview/s6_bg_pc.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include mq(tabMin) {
          background-image: url(/images/mainview/s_6_bg_sp@2x.png);
          background-size: 97.2%;
        }

        @include mq(md) {
          background-image: url(/images/mainview/s_6_bg_sp@2x.png);
          background-size: 97.2%;
        }
      }

      .mainviewTit{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: scale(0);
        transition: transform .4s;
        @include props(w, 1350);
        max-height: 100vh;
        z-index: 1;

        @include mq(tabMin) {
          display: block;
          max-width: 100%;
          height: 80%;
          @include props(t, 0);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        @include mq(md) {
          display: block;
          max-width: 100%;
          height: 80%;
          @include props(t, 0);
          margin: 0 auto;
          z-index: 1;

          img{
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      .imgFade{
        opacity: 0;
        backface-visibility: hidden;
        perspective: 1000;
        will-change: opacity;
      }
      .img01{
        position: absolute;
        @include props(b, 61);
        @include props(r, 219);
        @include props(w, 855);

        @include mq(tabMin) {
          //@include props(b, 258, 960);
          bottom: calc(50% - 35.875vw);
          @include props(r, 0, 960);
          @include props(w, 800, 960);
        }

        @include mq(md) {
          //@include props(b, 258, 750);
          bottom: calc(50% - 48.4vw);
          @include props(r, 0, 750);
          @include props(w, 750, 750);
        }
      }
      .img02{
        position: absolute;
        animation: spaceAnime01 3s linear infinite alternate;
        @include props(t, 108);
        @include props(r, 28);
        @include props(w, 382);

        @include mq(tabMin) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(t, 408, 960);
          @include props(r, 31, 960);
          @include props(w, 298, 960);
        }

        @include mq(md) {
          animation: spaceAnimeSp01 3s linear infinite alternate;
          @include props(t, 498, 750);
          @include props(r, 31, 750);
          @include props(w, 248, 750);
        }
      }
    }
  }

  .spaceArea{
    background-image: url(/images/index/onair_bg_pc@2x.jpg);
    background-position: center;
    background-size: contain;

    @include mq(tabMin) {
      background-image: url(/images/index/onair_bg_sp@2x.jpg);
    }
    @include mq(md) {
      background-image: url(/images/index/onair_bg_sp@2x.jpg);
    }
  }

  .onAir{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: #00232E;
    //background-image: url(/images/index/bg_star_reverse@2x.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    min-height: 100vh;
    @include props(pt, 170);
    @include props(pb, 197);

    @include mq(tabMin) {
      //background-image: url(/images/index/bg_star_sp.png);
      @include props(pt, 195, 960);
      @include props(pb, 160, 960);
    }

    @include mq(md) {
      //background-image: url(/images/index/bg_star_sp.png);
      @include props(pt, 195, 750);
      @include props(pb, 160, 750);
    }

    .wrap{
      display: flex;
      justify-content: space-between;
      max-width: 1286px;
      width: 100%;
      @include props(pl, 93);
      margin: 0 auto;

      @include mq(tabMin) {
        display: block;
        @include props(pl, 40, 960);
      }

      @include mq(md) {
        display: block;
        @include props(pl, 40, 750);
      }
    }

    .txtBox{
      max-width: 18.441%;
      width: 100%;
      @include props(mr, 110);

      @include mq(tabMin) {
        max-width: 100%;
        @include props(mr, 0);
      }

      @include mq(md) {
        max-width: 100%;
        @include props(mr, 0);
      }
    }

    .onAirTit{
      @include props(mb, 32);

      svg{
        width: 100%;
        height: auto;
      }

      .cls-1 {
        fill: none;
        stroke-dashoffset: 0;
        stroke: rgba($color: #e0ecf4, $alpha: 0);
        stroke-width: 2px;
      }

      @include mq(tabMin) {
        @include props(w, 378, 960);
        @include props(mb, 46, 960);
      }

      @include mq(md) {
        @include props(w, 378, 750);
        @include props(mb, 46, 750);
      }
    }
    .fadeOnAir{
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
    }
    .onAirTitLead{
      display: flex;
      align-items: center;
      @include fz_vw(18);
      font-weight: 500;
      color: #fff;
      @include props(mb, 50);

      &::before{
        content: "";
        display: block;
        flex-shrink: 0;
        @include props(w, 45);
        height: 1px;
        @include props(mr, 15);
        background-color: #fff;
      }

      @include mq(tabMin) {
        @include fz_vw(32);
        @include props(mb, 700, 960);

        &::before{
          @include props(w, 45, 750);
          @include props(mr, 20, 750);
        }
      }

      @include mq(md) {
        @include fz_vw(32);
        @include props(mb, 700, 750);

        &::before{
          @include props(w, 45, 750);
          @include props(mr, 20, 750);
        }
      }
    }
    .btnWhite01{
      @include mq(tabMin) {
        margin: 0 auto;
      }
      @include mq(md) {
        margin: 0 auto;
      }
    }

    .onAirProgramListWrap{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @include props(w, 905);
      max-width: none;
      @include props(h, 440);
      overflow: hidden;

      &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 7%;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(0,35,46,1) 0%, rgba(0,35,46,0.8827906162464986) 16%, rgba(0,35,46,0) 100%);
        z-index: 1;
      }

      @include mq(full) {
        @include props(w, 855);
        max-width: none;
      }

      @include mq(tab) {
        @include props(w, 734, 1024);
      }

      @include mq(tabMin) {
        display: flex;
        align-items: flex-end;
        @include props(l, 0);
        @include props(t, 457, 960);
        transform: none;
        width: 100%;
        @include props(h, 540, 960);

        &::before{
          @include props(w, 80, 960);
        }
      }

      @include mq(md) {
        @include props(l, 0);
        @include props(t, 457, 750);
        transform: none;
        width: 100%;
        @include props(h, 480, 750);

        &::before{
          @include props(w, 80, 750);
        }
      }
    }

    .onAirProgramList{
      position: absolute;
      display: flex;
      padding: 0 0 0 40%;

      @include mq(md) {
        padding: 0 0 0 80%;
      }

      .onAirProgram{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-flow: column;
        background-color: #fff;
        border-radius: 100%;
        text-align: center;
        overflow: hidden;
        @include props(w, 440);
        @include props(h, 440);
        @include props(mr, 60);
        @include props(pt, 40);
        @include props(pb, 20);

        &:last-of-type{
          @include props(mr, 440);
        }

        @include mq(tabMin) {
          justify-content: center;
          @include props(w, 540, 960);
          @include props(h, 540, 960);
          @include props(pt, 20, 960);
           @include props(pb, 10, 960);
          @include props(mr, 60, 960);

          &:last-of-type{
            @include props(mr, 480, 960);
          }
        }

        @include mq(md) {
          justify-content: center;
          @include props(w, 480, 750);
          @include props(h, 480, 750);
          @include props(pt, 20, 750);
          @include props(pb, 10, 750);
          @include props(mr, 60, 750);

          &:last-of-type{
            @include props(mr, 480, 750);
          }
        }
      }

      .onAirProgramImg{
        @include props(w, 280);
        @include props(h, 210);
        overflow: hidden;

        img{
          object-fit: cover;
          height: 100%;
          width: 100%;
          object-position: center;
        }

        @include mq(tabMin) {
          @include props(w, 316, 960);
          @include props(h, 238, 960);
          @include props(mb, 15, 960);
        }

        @include mq(md) {
          @include props(w, 316, 750);
          @include props(h, 238, 750);
          @include props(mb, 15, 750);
        }
      }
      .onAirProgramTit{
        max-width: 88%;
        @include fz_vw(22);
        font-weight: bold;
        line-height: 1.64;
        color: #333;

        @include mq(tabMin) {
          @include fz_vw(28);
          line-height: 1.29;
          @include props(mb, 10, 960);
        }

        @include mq(md) {
          @include fz_vw(28);
          line-height: 1.29;
          @include props(mb, 10, 750);
        }
      }
      .onAirProgramTag{
        max-width: 88%;
        @include fz_vw(15);
        color: #555;

        @include mq(tabMin) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(24);
        }
      }
    }
  }

  .aboutUs{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom, #9fcfd3 , #fcf9f5 50%, #e6c3d8 );
    min-height: 100vh;
    overflow: hidden;
    @include props(pt, 130);
    @include props(pb, 100);

    @include mq(tabMin) {
      min-height: auto;
      height: auto;
      @include props(pt, 145, 960);
      @include props(pb, 120, 960);
    }

    @include mq(md) {
      min-height: auto;
      height: auto;
      @include props(pt, 145, 750);
      @include props(pb, 120, 750);
    }

    .wrap{
      display: flex;
      justify-content: space-between;
      max-width: 1306px;
      width: 100%;
      @include props(pr, 20);
      @include props(pl, 93);
      margin: 0 auto;

      @include mq(tabMin) {
        flex-flow: column;
        @include props(pr, 40, 960);
        @include props(pl, 40, 960);
      }

      @include mq(md) {
        flex-flow: column;
        @include props(pr, 40, 750);
        @include props(pl, 40, 750);
      }
    }
    .txtBox{
      max-width: 34.871%;
      width: 100%;

      @include mq(tabMin) {
        max-width: 100%;
      }

      @include mq(md) {
        max-width: 100%;
      }
    }
    .imgBox{
      @include props(w, 682);

      img{
        width: 100%;
      }

      &.anime{
        .img{
          animation: spaceAnime02 3s linear infinite alternate;

          @include mq(tabMin) {
            animation: spaceAnimeSp02 3s linear infinite alternate;
          }

          @include mq(md) {
            animation: spaceAnimeSp02 3s linear infinite alternate;
          }
        }
      }

      @include mq(tabMin) {
        margin: 0 auto;
        @include props(w, 670, 960);
        @include props(mb, 100, 960);
      }

      @include mq(md) {
        margin: 0 auto;
        @include props(w, 670, 750);
        @include props(mb, 100, 750);
      }
    }
    .btnWhite01{
      @include mq(tabMin) {
        margin: 0 auto;
      }

      @include mq(md) {
        margin: 0 auto;
      }
    }

    .aboutUsTit{
      opacity: 0;
      @include props(mb, 32);

      svg{
        width: 100%;
        height: auto;
      }

      .cls-1 {
        fill: none;
        stroke: rgba($color: #333, $alpha: 1.0);
        stroke-width: 2px;
      }

      @include mq(tabMin) {
        @include props(w, 552, 960);
        @include props(mb, 56, 960);
      }

      @include mq(md) {
        @include props(w, 552, 750);
        @include props(mb, 56, 750);
      }
    }
    .fadeAbout{
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);

      @include mq(tabMin) {
        opacity: 1;
        visibility: visible;
        transform: none;
      }

      @include mq(md) {
        opacity: 1;
        visibility: visible;
        transform: none;
      }
    }
    .aboutUsTitLead{
      display: flex;
      align-items: center;
      @include fz_vw(18);
      font-weight: 500;
      color: #555;
      @include props(mb, 30);

      &::before{
        content: "";
        display: block;
        flex-shrink: 0;
        @include props(w, 45);
        height: 1px;
        @include props(mr, 15);
        background-color: #555;
      }

      @include mq(tabMin) {
        @include fz_vw(32);
        @include props(mb, 30, 960);

        &::before{
          @include props(w, 45, 960);
          @include props(mr, 20, 960);
        }
      }

      @include mq(md) {
        @include fz_vw(32);
        @include props(mb, 30, 750);

        &::before{
          @include props(w, 45, 750);
          @include props(mr, 20, 750);
        }
      }
    }
    .aboutUsTxt{
      @include fz_vw(16);
      font-weight: 400;
      line-height: 2;
      letter-spacing: 0.96px;
      color: #333;
      @include props(mb, 30);

      @include mq(tabMin) {
        @include fz_vw(28);
        @include props(mb, 80, 960);
      }

      @include mq(md) {
        @include fz_vw(28);
        @include props(mb, 80, 750);
      }
    }
  }

  .recruit{
    position: relative;
    background-image: linear-gradient(to bottom, #9fcfd3 , #fcf9f5 50%, #e6c3d8 );
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    @include props(pt, 130);
    @include props(pb, 100);
    z-index: 1;

    &.scrollDown{
      &::before{
        opacity: 1;
        visibility: visible;
      }
    }

    @include mq(tabMin) {
      height: auto;
      @include props(pt, 145, 960);
      @include props(pb, 120, 960);
    }

    @include mq(md) {
      height: auto;
      @include props(pt, 145, 750);
      @include props(pb, 120, 750);
    }

    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: all .8s;
      z-index: 1;
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      background-image: url(/images/index/recruit_bg_pc@2x.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;

      @include mq(tabMin) {
        top: auto;
        transform: none;
        background-image: url(/images/index/recruit_bg_sp@2x.png);
        @include props(h, 500, 960);
        @include props(b, 321, 960);
      }

      @include mq(md) {
        top: auto;
        transform: none;
        background-image: url(/images/index/recruit_bg_sp@2x.png);
        @include props(h, 500, 750);
        @include props(b, 321, 750);
      }
    }

    .wrap{
      display: flex;
      justify-content: space-between;
      max-width: 1306px;
      width: 100%;
      @include props(pl, 20);
      @include props(pr, 93);
      margin: 0 auto;

      @include mq(tabMin) {
        flex-flow: column;
        @include props(pr, 40, 960);
        @include props(pl, 40, 960);
      }

      @include mq(md) {
        flex-flow: column;
        @include props(pr, 40, 750);
        @include props(pl, 40, 750);
      }
    }
    .txtBox{
      max-width: 39.481%;
      width: 100%;
      order: 2;

      @include mq(tabMin) {
        max-width: 100%;
        order: 1;
      }

      @include mq(md) {
        max-width: 100%;
        order: 1;
      }
    }
    .imgBox{
      position: relative;
      @include props(w, 690);
      @include props(h, 443);
      order: 1;

      img{
        width: 100%;
      }

      &.anime{
        .img{
          &:nth-of-type(2){
            animation: spaceAnime01 3s linear infinite alternate;

            @include mq(tabMin) {
              animation: spaceAnimeSp01 3s linear infinite alternate;
            }

            @include mq(md) {
              animation: spaceAnimeSp01 3s linear infinite alternate;
            }
          }
        }
      }

      .img{
        &:nth-of-type(1){
          position: absolute;
          right: 0;
          bottom: 0;
          @include props(w, 615);
        }
        &:nth-of-type(2){
          position: absolute;
          left: 0;
          top: 0;
          @include props(w, 246);
        }
      }

      @include mq(tabMin) {
        @include props(w, 670, 960);
        @include props(h, 484, 960);
        margin: 0 auto;
        @include props(mb, 100, 960);
        order: 2;

        .img{
          &:nth-of-type(1){
            @include props(w, 615, 960);
          }
          &:nth-of-type(2){
            @include props(w, 246, 960);
          }
        }
      }

      @include mq(md) {
        @include props(w, 670, 750);
        @include props(h, 484, 750);
        margin: 0 auto;
        @include props(mb, 100, 750);
        order: 2;

        .img{
          &:nth-of-type(1){
            @include props(w, 615, 750);
          }
          &:nth-of-type(2){
            @include props(w, 246, 750);
          }
        }
      }
    }
    .btnWhite01{
      @include mq(tabMin) {
        order: 3;
        margin: 0 auto;
      }
      @include mq(md) {
        order: 3;
        margin: 0 auto;
      }
    }

    .recruitTit{
      opacity: 0;
      @include props(mb, 32);

      svg{
        width: 100%;
        height: auto;
      }

      .cls-1 {
        fill: none;
        stroke: #333;
        stroke-width: 2px;
      }

      @include mq(tabMin) {
        @include props(w, 466, 960);
        @include props(mb, 56, 960);
      }

      @include mq(md) {
        @include props(w, 466, 750);
        @include props(mb, 56, 750);
      }
    }
    .fadeRecruit{
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);

      @include mq(tabMin) {
        opacity: 1;
        visibility: visible;
        transform: none;
      }

      @include mq(md) {
        opacity: 1;
        visibility: visible;
        transform: none;
      }
    }
    .recruitTitLead{
      display: flex;
      align-items: center;
      @include fz_vw(18);
      font-weight: 500;
      color: #555;
      @include props(mb, 30);

      &::before{
        content: "";
        display: block;
        flex-shrink: 0;
        @include props(w, 45);
        height: 1px;
        @include props(mr, 15);
        background-color: #555;
      }

      @include mq(tabMin) {
        @include fz_vw(32);
        @include props(mb, 30, 960);

        &::before{
          @include props(w, 45, 960);
          @include props(mr, 20, 960);
        }
      }

      @include mq(md) {
        @include fz_vw(32);
        @include props(mb, 30, 750);

        &::before{
          @include props(w, 45, 750);
          @include props(mr, 20, 750);
        }
      }
    }
    .recruitTxt{
      max-width: 88.323%;
      width: 100%;
      @include fz_vw(16);
      font-weight: 400;
      line-height: 2;
      letter-spacing: 0.96px;
      color: #333;
      @include props(mb, 30);

      @include mq(tabMin) {
        @include fz_vw(28);
        @include props(mb, 80, 960);
      }

      @include mq(md) {
        @include fz_vw(28);
        @include props(mb, 80, 750);
      }
    }
  }
}