@charset "utf-8";

/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity .8s .6s, transform .5s .5s;
  backface-visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes spaceAnime01 {

  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }

  50% {
    transform: translateY(-20px) translateX(-3px);
  }
}

@keyframes spaceAnime02 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes spaceAnime03 {

  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }

  50% {
    transform: translateY(20px) translateX(6px);
  }
}

@keyframes spaceAnimeSp01 {

  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }

  50% {
    transform: translateY(-10px) translateX(-2px);
  }
}

@keyframes spaceAnimeSp02 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes spaceAnimeSp03 {

  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }

  50% {
    transform: translateY(10px) translateX(3px);
  }
}

@keyframes bound {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.7);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes spaceFooterAnime01 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes spaceFooterAnime02 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}

@keyframes sdb03 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    width: 180px;
    height: 180px;
    background-color: rgba(117, 148, 207, .1);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdbSp03 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    box-shadow: 0 0 0 22px rgba(117, 148, 207, .4);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 38;
  }

  25% {
    stroke-dashoffset: 38;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -38;
  }

  100% {
    stroke-dashoffset: -38;
  }
}

@keyframes line02 {
  0% {
    transform: scale(0, 1);
    transform-origin: center right;
  }

  25% {
    transform: scale(0, 1);
    transform-origin: center right;
  }

  50% {
    transform: scale(1, 1);
    transform-origin: center right;
  }

  51% {
    transform: scale(1, 1);
    transform-origin: center left;
  }

  80% {
    transform: scale(0, 1);
    transform-origin: center left;
  }

  100% {
    transform: scale(0, 1);
    transform-origin: center left;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}