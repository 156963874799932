@charset "utf-8";

/* ==============================================
works トップページ
============================================== */
#worksTop {
  overflow-x: hidden;

  .spaceArea {
    background-image: url(/images/works/bg_outerspace.jpg);
    background-position: center;

    @include mq(tabMin) {
      background-image: url(/images/works/bg_outerspace_sp.jpg);
    }
    @include mq(md) {
      background-image: url(/images/works/bg_outerspace_sp.jpg);
    }
  }

  .mainview {
    position: relative;
    z-index: 1;
    @include props(pt, 176);
    @include props(pb, 136);

    @include mq(tabMin) {
      @include props(pt, 245, 960);
      @include props(pb, 25, 960);

      &::after{
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1366px;
        width: 100%;
        @include props(h, 768, 960);
        background-image: url(/images/works/bg_star_sp.png);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        z-index: -1;
      }
    }

    @include mq(md) {
      @include props(pt, 245, 750);
      @include props(pb, 25, 750);

      &::after{
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1366px;
        width: 100%;
        @include props(h, 768, 750);
        background-image: url(/images/works/bg_star_sp.png);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        z-index: -1;
      }
    }

    .container{
      position: relative;
    }

    .mainviewTit {
      opacity: 0;
      visibility: hidden;
      -webkit-text-stroke: 2px #fff;
      font-family: $Teko;
      @include fz_vw(120);
      font-weight: bold;
      line-height: 1.08;
      letter-spacing: 3.6px;
      color: rgba($color: #e0ecf4, $alpha: 0);

      &.isShow{
        opacity: 1;
        visibility: visible;
        transition: opacity .3s, visibility .3s;
      }

      @include mq(tabMin) {
        letter-spacing: 1.8px;
      }
  
      @include mq(md) {
        letter-spacing: 1.8px;
      }

      .img{
        @include props(w, 361);

        @include mq(tabMin) {
          @include props(w, 361, 960);
        }
    
        @include mq(md) {
          @include props(w, 361, 750);
        }
      }

      svg{
        max-width: 100%;
        width: 100%;
        height: auto;
        fill: transparent;
        stroke-width: 2px;
        stroke: #fff;
      }

      .lead {
        display: flex;
        align-items: center;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: normal;
        color: #fff;

        &::before {
          content: "";
          display: block;
          @include props(w, 45);
          height: 1px;
          background-color: #fff;
          @include props(mr, 15);
        }

        @include mq(tabMin) {
          @include fz_vw(28);

          &::before {
            @include props(w, 45, 750);
            @include props(mr, 15, 750);
          }
        }
    
        @include mq(md) {
          @include fz_vw(28);

          &::before {
            @include props(w, 45, 750);
            @include props(mr, 15, 750);
          }
        }
      }
    }

    .imgBox{
      @include mq(tabMin) {
        display: flex;
        align-items: center;
        justify-content: center;
        @include props(mt, 25, 960);
      }
  
      @include mq(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        @include props(mt, 25, 750);
      }
    }

    .img01{
      position: absolute;
      @include props(w, 255);
      @include props(t, -25);
      @include props(l, 512);
      animation: spaceAnime01 3.5s linear infinite alternate;

      @include mq(tabMin) {
        @include props(w, 324, 960);
        @include props(mt, 41, 960);
        @include props(mr, 25, 960);
        position: static;
        animation: spaceAnimeSp01 3.5s linear infinite alternate;
      }
  
      @include mq(md) {
        @include props(w, 324, 750);
        @include props(mt, 41, 750);
        @include props(mr, 25, 750);
        position: static;
        animation: spaceAnimeSp01 3.5s linear infinite alternate;
      }
    }
    .img02{
      position: absolute;
      @include props(w, 253);
      @include props(t, -60);
      @include props(l, 787);
      animation: spaceAnime03 3.5s linear infinite alternate;

      @include mq(tabMin) {
        @include props(w, 321, 960);
        position: static;
        animation: spaceAnimeSp03 3.5s linear infinite alternate;
      }
  
      @include mq(md) {
        @include props(w, 321, 750);
        position: static;
        animation: spaceAnimeSp03 3.5s linear infinite alternate;
      }
    }
  }

  .onAirArea {
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include props(pt, 60);
    @include props(pb, 100);

    @include mq(tabMin) {
      @include props(pt, 202, 960);
      @include props(pb, 160, 960);
    }

    @include mq(md) {
      @include props(pt, 202, 750);
      @include props(pb, 160, 750);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1366px;
      width: 100%;
      @include props(h, 768);
      background-image: url(/images/works/bg_star_reverse.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      z-index: -1;

      @include mq(tabMin) {
        @include props(h, 768, 960);
        background-image: url(/images/works/bg_star_reverse_sp.png);
      }
  
      @include mq(md) {
        @include props(h, 768, 750);
        background-image: url(/images/works/bg_star_reverse_sp.png);
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      @include props(b, 43);
      left: 50%;
      transform: translateX(-50%);
      max-width: 1366px;
      width: 100%;
      @include props(h, 768);
      background-image: url(/images/works/bg_star.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      z-index: -1;

      @include mq(tabMin) {
        position: absolute;
        content: "";
        display: block;
        background-image: url(/images/works/onair_camera@2x.png);
        background-repeat: no-repeat;
        background-size: contain;
        @include props(w, 139, 960);
        @include props(h, 100, 960);
        @include props(b, 104, 960);
        @include props(l, 491, 960);
        animation: spaceAnimeSp02 3s linear infinite alternate;
      }
  
      @include mq(md) {
        position: absolute;
        content: "";
        display: block;
        background-image: url(/images/works/onair_camera@2x.png);
        background-repeat: no-repeat;
        background-size: contain;
        @include props(w, 139, 750);
        @include props(h, 100, 750);
        @include props(b, 104, 750);
        @include props(l, 491, 750);
        animation: spaceAnimeSp02 3s linear infinite alternate;
      }
    }

    .onAirAreaTit {
      position: relative;
      -webkit-text-stroke: 1px #fff;
      font-family: $Teko;
      @include fz_vw(80);
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 2.4px;
      text-align: center;
      color: rgba($color: #e0ecf4, $alpha: 0);
      @include props(pb, 80);

      .lead {
        display: block;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: 0.54px;
        color: #fff;
      }

      &::before{
        position: absolute;
        content: "";
        display: block;
        background-image: url(/images/works/onair_ufo@2x.png);
        background-repeat: no-repeat;
        background-size: contain;
        @include props(w, 144);
        @include props(h, 112);
        @include props(l, 168);
        @include props(b, 24);
        animation: spaceAnime02 3s linear infinite alternate;
      }

      &::after{
        position: absolute;
        content: "";
        display: block;
        background-image: url(/images/works/onair_heedphone@2x.png);
        background-repeat: no-repeat;
        background-size: contain;
        @include props(w, 75);
        @include props(h, 97);
        @include props(r, 232);
        @include props(b, 8);
        animation: spaceAnime02 3s linear infinite alternate;
      }

      @include mq(tabMin) {
        @include fz_vw(104);
        line-height: 1;
        letter-spacing: 1.56px;
        @include props(pb, 162, 960);
        @include props(mb, 480, 960);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }

        &::before{
          @include props(w, 133, 960);
          @include props(h, 104, 960);
          @include props(l, 0);
          @include props(b, 100, 960);
          animation: spaceAnimeSp02 3s linear infinite alternate;
        }
  
        &::after{
          @include props(w, 100, 960);
          @include props(h, 128, 960);
          bottom: auto;
          @include props(r, 30, 960);
          @include props(t, -100, 960);
          animation: spaceAnimeSp02 3s linear infinite alternate;
        }
      }
  
      @include mq(md) {
        @include fz_vw(104);
        line-height: 1;
        letter-spacing: 1.56px;
        @include props(pb, 162, 750);
        @include props(mb, 480, 750);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }

        &::before{
          @include props(w, 133, 750);
          @include props(h, 104, 750);
          @include props(l, 0);
          @include props(b, 100, 750);
          animation: spaceAnimeSp02 3s linear infinite alternate;
        }
  
        &::after{
          @include props(w, 100, 750);
          @include props(h, 128, 750);
          bottom: auto;
          @include props(r, 30, 750);
          @include props(t, -100, 750);
          animation: spaceAnimeSp02 3s linear infinite alternate;
        }
      }
    }

    .onAirProgramListWrap{
      @include mq(tabMin) {
        position: absolute;
        @include props(b, 160, 960);
        max-width: none;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        @include props(l, 0);
        width: 100%;
        @include props(h, 540, 960);

        &::before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          @include props(w, 80, 960);
          height: 100%;
          background-image: linear-gradient(90deg, rgba(0,35,46,1) 0%, rgba(0,35,46,0.8827906162464986) 16%, rgba(0,35,46,0) 100%);
          z-index: 1;
        }
      }

      @include mq(md) {
        position: absolute;
        @include props(b, 160, 750);
        max-width: none;
        overflow: hidden;
        @include props(l, 0);
        width: 100%;
        @include props(h, 480, 750);

        &::before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          @include props(w, 80, 750);
          height: 100%;
          background-image: linear-gradient(90deg, rgba(0,35,46,1) 0%, rgba(0,35,46,0.8827906162464986) 16%, rgba(0,35,46,0) 100%);
          z-index: 1;
        }
      }
    }

    .onAirProgramList {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      @include props(w, 1100);

      &::after{
        position: absolute;
        content: "";
        display: block;
        background-image: url(/images/works/onair_camera@2x.png);
        background-repeat: no-repeat;
        background-size: contain;
        @include props(w, 121);
        @include props(h, 87);
        @include props(b, 333);
        @include props(l, -60);
        animation: spaceAnime02 3s linear infinite alternate;
      }

      @include mq(tabMin) {
        position: absolute;
        max-width: none;
        width: auto;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0 0 0 40%;

        &::after{
          display: none;
        }
      }
  
      @include mq(md) {
        position: absolute;
        max-width: none;
        width: auto;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0 0 0 40%;

        &::after{
          display: none;
        }
      }

      .onAirProgram {
        position: relative;
        width: 100%;
        max-width: 30.91%;
        z-index: 1;
        background-color: #fff;
        border-radius: 100%; 
        overflow: hidden;
        backface-visibility: hidden;
        perspective: 1000;
        // padding: 30px 0 0;
        // margin: 0 3.6% 23px 0;
        @include props(w, 340);
        @include props(h, 340);
        @include props(mr, 38);
        @include props(mb, 23);

        // &::after {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   padding-bottom: 100%;
        //   background-color: #fff;
        //   border-radius: 100%;
        //   z-index: -1;
        // }

        &:nth-of-type(3n+1) {
          @include props(mt, 47);
        }

        &:nth-of-type(3n) {
          @include props(mr, 0);
          @include props(mt, 47);
        }

        a{
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: column;
          text-align: center;
          border-radius: 100%; 
          @include props(pt, 40);
          @include props(pb, 20);
        }

        @include mq(tabMin) {
          opacity: 1;
          transform: none;
          flex-shrink: 0;
          background-color: #fff;
          border-radius: 100%;
          @include props(w, 540, 960);
          @include props(h, 540, 960);
          @include props(mr, 60, 960);
          @include props(mb, 0);

          &::after {
            display: none;
          }

          &:nth-of-type(3n+1) {
            @include props(mt, 0);
          }
  
          &:nth-of-type(3n) {
            @include props(mr, 60, 960);
            @include props(mt, 0);
          }

          a{
            justify-content: center;
            @include props(pt, 20, 960);
            @include props(pb, 10, 960);
          }
        }

        @include mq(md) {
          opacity: 1;
          transform: none;
          flex-shrink: 0;
          background-color: #fff;
          border-radius: 100%;
          @include props(w, 480, 750);
          @include props(h, 480, 750);
          @include props(mr, 60, 750);
          @include props(mb, 0);

          &::after {
            display: none;
          }

          &:nth-of-type(3n+1) {
            @include props(mt, 0);
          }
  
          &:nth-of-type(3n) {
            @include props(mr, 60, 750);
            @include props(mt, 0);
          }

          a{
            justify-content: center;
            @include props(pt, 20, 750);
            @include props(pb, 10, 750);
          }
        }
      }

      .onAirProgramImg {
        @include props(w, 220);
        @include props(h, 166);
        @include props(mb, 18);
        overflow: hidden;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          object-position: center;
        }

        @include mq(tabMin) {
          @include props(w, 316, 960);
          @include props(h, 238, 960);
          @include props(mb, 15, 960);
        }

        @include mq(md) {
          @include props(w, 316, 750);
          @include props(h, 238, 750);
          @include props(mb, 15, 750);
        }
      }

      .onAirProgramTit {
        max-width: 88%;
        @include fz_vw(16);
        font-weight: bold;
        line-height: 1.64;
        color: #333;
        @include props(mb, 18);

        @include mq(tabMin) {
          @include fz_vw(28);
          line-height: 1.29;
          @include props(mb, 10, 960);
        }

        @include mq(md) {
          @include fz_vw(28);
          line-height: 1.29;
          @include props(mb, 10, 750);
        }
      }

      .onAirProgramTag {
        max-width: 88%;
        @include fz_vw(15);
        color: #555;

        @include mq(tabMin) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(24);
        }
      }
    }
  }

  .archivesArea {
    @include props(pt, 100);
    @include props(pb, 120);
    background-image: linear-gradient(to bottom, rgba(159, 207, 211, .5), rgba(252, 249, 245, .5) 50%, rgba(230, 195, 216, .5));

    @include mq(tabMin) {
      @include props(pt, 100, 960);
      @include props(pb, 120, 960);
    }

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pb, 120, 750);
    }

    .archivesAreaTit {
      -webkit-text-stroke: 1px #333;
      font-family: $Teko;
      @include fz_vw(80);
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 2.4px;
      text-align: center;
      color: rgba($color: #e0ecf4, $alpha: 0);
      @include props(mb, 80);

      .lead {
        display: block;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: 0.54px;
        color: #555;
      }

      @include mq(tabMin) {
        @include fz_vw(100);
        line-height: 1.2;
        letter-spacing: 1.5px;
        @include props(mb, 126, 960);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
  
      @include mq(md) {
        @include fz_vw(100);
        line-height: 1.2;
        letter-spacing: 1.5px;
        @include props(mb, 126, 750);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
    }

    .archivesAreaList{
      position: relative;
      @include props(mb, 80);
      
      &:last-of-type{
        margin-bottom: 0;
      }

      &:nth-of-type(1){
        &::after{
          position: absolute;
          content: "";
          display: block;
          background-image: url(/images/works/achieve_taco@2x.png);
          background-repeat: no-repeat;
          background-size: contain;
          @include props(w, 157);
          @include props(h, 112);
          @include props(l, 86);
          @include props(t, -80);
          animation: spaceAnime02 3s linear infinite alternate;
        }
      }

      @include mq(tabMin) {
        @include props(mb, 100, 960);

        &:nth-of-type(1){
          &::after{
            @include props(w, 157, 960);
            @include props(h, 112, 960);
            @include props(l, 30, 960);
            @include props(t, -86, 960);
            animation: spaceAnimeSp02 3s linear infinite alternate;
          }
        }
      }
  
      @include mq(md) {
        @include props(mb, 100, 750);

        &:nth-of-type(1){
          &::after{
            @include props(w, 157, 750);
            @include props(h, 112, 750);
            @include props(l, 30, 750);
            @include props(t, -86, 750);
            animation: spaceAnimeSp02 3s linear infinite alternate;
          }
        }
      }

      dd{
        @include props(mb, 40);
      
        &:last-of-type{
          margin-bottom: 0;
        }

        ul{
          display: flex;
          flex-wrap: wrap;
        }

        @include mq(tabMin) {
          @include props(mb, 80, 960);

          ul{
            display: block;
          }
        }
    
        @include mq(md) {
          @include props(mb, 80, 750);

          ul{
            display: block;
          }
        }
      }

      .archivesAreaListTit{
        @include fz_vw(28);
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: #333;
        border-radius: 10px;
        background-color: #fff;
        @include props(pt, 15);
        @include props(pb, 15);
        @include props(mb, 40);

        @include mq(tabMin) {
          @include fz_vw(36);
          background-color: #f7f7f7;
          @include props(pt, 20, 960);
          @include props(pb, 20, 960);
          @include props(mb, 60, 960);
        }
    
        @include mq(md) {
          @include fz_vw(36);
          background-color: #f7f7f7;
          @include props(pt, 20, 750);
          @include props(pb, 20, 750);
          @include props(mb, 60, 750);
        }
      }

      .programCategory{
        @include fz_vw(16);
        font-weight: bold;
        line-height: 1.5;
        color: #333;
        border-bottom: solid 1px #ccc;
        @include props(pr, 20);
        @include props(pl, 20);
        @include props(pb, 15);

        @include mq(tabMin) {
          @include fz_vw(32);
          @include props(pr, 0);
          @include props(pl, 0);
          @include props(pb, 30, 960);
        }
    
        @include mq(md) {
          @include fz_vw(32);
          @include props(pr, 0);
          @include props(pl, 0);
          @include props(pb, 30, 750);
        }
      }

      .programName{
        position: relative;
        max-width: 50%;
        width: 100%;
        @include fz_vw(14);
        line-height: 1.5;
        color: #333;
        @include props(mt, 14);
        @include props(pr, 20);
        @include props(pl, 40);

        &::before{
          content: "";
          position: absolute;
          @include props(t, 8);
          @include props(l, 23);
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 1px;
          background-color: #a1d5d8;
        }

        @include mq(tabMin) {
          max-width: 100%;
          @include fz_vw(28);
          @include props(pr, 0);
          @include props(pl, 30, 960);
          @include props(mt, 30, 960);

          &::before{
            @include props(t, 16, 960);
            @include props(l, 0, 960);
            @include props(w, 12, 960);
            @include props(h, 12, 960);
          }
        }
    
        @include mq(md) {
          max-width: 100%;
          @include fz_vw(28);
          @include props(pr, 0);
          @include props(pl, 30, 750);
          @include props(mt, 30, 750);

          &::before{
            @include props(t, 16, 750);
            @include props(l, 0, 750);
            @include props(w, 12, 750);
            @include props(h, 12, 750);
          }
        }
      }
    }
  }

  .fadeInworks{
    opacity: 0;
  }
}