@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;

// 基本カラー
$basicColor: #555;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;

// 基本フォント
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
$base-font:"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;700&display=swap');
$Teko: 'Teko', sans-serif;

$yakuhan:YakuHanJP_Noto,
"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 767px)",
  "tab": "screen and (min-width : 961px) and (max-width : 1024px)",
  "tabMin03": "screen and (min-width : 768px) and (max-width : 960px)",
  "tabMin02": "screen and (min-width : 768px) and (max-width : 1024px)",
  "tabMin": "screen and (min-width : 500px) and (max-width : 960px)",
  "pc": "screen and (min-width : 768px)",
  "fixed": "screen and (min-width : 1366px)",
  "big": "screen and (min-width : 1440px)",
  "full": "screen and (min-width : 1921px)",
) !default;

$properties: ('p': 'padding',
  'pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'm': 'margin',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'w': 'width',
  'h': 'height',
  'minw': 'min-width',
  'minh': 'min-height',
  't': 'top',
  'l': 'left',
  'b': 'bottom',
  'r': 'right',
) !default;

/* ================================================================================
importファイル
================================================================================ */

// @mixin定義
@import "foundation/mixin";

// @extend定義
@import "foundation/extend";

// リセット
@import "foundation/reset";

// 印刷
@import "foundation/print";

// 基本 
@import "layout/bace";

// ヘッダー
@import "layout/header";

// フッター
@import "layout/footer";

// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";

/************************************
トップページ
***********************************/

// トップページ
@import "page/index";

// worksページ
@import "page/works";

//aboutUs ページ
@import "page/about";

//recruit ページ
@import "page/recruit";