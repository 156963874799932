@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;700&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*タブレット設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #responsibleCheck {
    width: 3px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (min-width: 500px) and (max-width: 960px) {
  .pcOnly {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .spOnly {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}

body.fixedBody {
  position: fixed !important;
  width: 100%;
  height: 100vh;
}

body.noScroll {
  overflow: hidden !important;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.container {
  max-width: 1140px;
  padding: 0 20px;
  margin: auto;
}

a {
  color: #555;
}

/* 共通デザイン
------------------------------------------------------------- */
.loaderBg {
  width: 100%;
  height: 100%;
  background-color: #00232E;
  background-image: url(/images/common/loading_bg_pc.jpg);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .loaderBg {
    background-image: url(/images/common/loading_bg_sp.jpg);
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  .loaderBg {
    background-image: url(/images/common/loading_bg_sp.jpg);
    background-size: contain;
  }
}

.loaderBg .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loaderBg .loader span {
  transform-origin: center right;
  display: block;
  background-color: #fff;
  width: 2.70864vw;
  max-width: 37px;
  height: 2px;
  margin-bottom: 0.73206vw;
  transform: scale(0, 1);
  animation-fill-mode: both;
}

@media screen and (min-width: 1366px) {
  .loaderBg .loader span {
    margin-bottom: 10px;
  }
}

.loaderBg .loader span:nth-of-type(1) {
  animation: line02 1s -0.1s ease-in-out infinite;
}

.loaderBg .loader span:nth-of-type(2) {
  animation: line02 1s -0.5s ease-in-out infinite;
}

.loaderBg .loader span:nth-of-type(3) {
  animation: line02 1s -0.7s ease-in-out infinite;
  margin-bottom: 0vw;
}

@media screen and (min-width: 1366px) {
  .loaderBg .loader span:nth-of-type(3) {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .loaderBg .loader span {
    width: 6.25vw;
    max-width: 60px;
    margin-bottom: 1.5625vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .loaderBg .loader span {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .loaderBg .loader span {
    width: 8vw;
    max-width: 60px;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .loaderBg .loader span {
    margin-bottom: 15px;
  }
}

.loaderBg .loader .imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderBg .loader .img02 {
  width: 7.54026vw;
  max-width: 103px;
  margin-left: 1.0981vw;
  flex-shrink: 0;
  animation: spaceAnime02 .8s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  .loaderBg .loader .img02 {
    margin-left: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .loaderBg .loader .img02 {
    width: 17.1875vw;
    max-width: 165px;
    margin-left: 1.35417vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .loaderBg .loader .img02 {
    margin-left: 13px;
  }
}

@media screen and (max-width: 767px) {
  .loaderBg .loader .img02 {
    width: 22vw;
    max-width: 165px;
    margin-left: 1.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .loaderBg .loader .img02 {
    margin-left: 13px;
  }
}

.loaderBg .loader p {
  font-family: "Teko", sans-serif;
  font-size: 2.6rem;
  font-size: 1.80556vw;
  font-weight: 500;
  line-height: 0.83;
  letter-spacing: 2.6px;
  text-align: center;
  color: #fff;
  margin-top: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  .loaderBg .loader p {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .loaderBg .loader p {
    font-size: 2.70833vw;
  }
}

@media screen and (max-width: 767px) {
  .loaderBg .loader p {
    font-size: 3.46667vw;
  }
}

@media screen and (min-width: 1366px) {
  .loaderBg .loader p {
    margin-top: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .loaderBg .loader p {
    font-size: 4.8rem;
    font-size: 3.33333vw;
    letter-spacing: 2.4px;
    margin-top: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .loaderBg .loader p {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  .loaderBg .loader p {
    font-size: 5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  .loaderBg .loader p {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .loaderBg .loader p {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .loaderBg .loader p {
    font-size: 4.8rem;
    font-size: 3.33333vw;
    letter-spacing: 2.4px;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .loaderBg .loader p {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  .loaderBg .loader p {
    font-size: 5vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .loaderBg .loader p {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .loaderBg .loader p {
    margin-top: 40px;
  }
}

.btnWhite01 {
  width: 16.10542vw;
  max-width: 220px;
}

.btnWhite01 a {
  position: relative;
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-size: 0.97222vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;
  color: #333;
  border-radius: 28px;
  box-shadow: 0 3px 6px 0 #00000029;
  background-color: #fff;
  transition: all .3s;
  padding: 1.31772vw 1.83016vw;
}

@media screen and (min-width: 1366px) {
  .btnWhite01 a {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .btnWhite01 a {
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) {
  .btnWhite01 a {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1366px) {
  .btnWhite01 a {
    padding: 18px 25px;
  }
}

.btnWhite01 a::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(/images/common/icon_arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s;
  width: 0.43924vw;
  max-width: 6px;
  height: 0.87848vw;
  max-height: 12px;
  right: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  .btnWhite01 a::after {
    right: 20px;
  }
}

.btnWhite01 a:hover {
  color: #fff;
  background-color: #333;
}

.btnWhite01 a:hover::after {
  background-image: url(/images/common/icon_arrow_white.svg);
}

.btnWhite01.back a::after {
  transform: translateY(-50%) rotate(180deg);
  right: auto;
  left: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  .btnWhite01.back a::after {
    left: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .btnWhite01 {
    width: 42.29167vw;
    max-width: 406px;
  }
  .btnWhite01 a {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    border-radius: 80px;
    box-shadow: 0 2px 3px 0 #00000029;
    padding: 3.125vw 5.72917vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .btnWhite01 a {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  .btnWhite01 a {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  .btnWhite01 a {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .btnWhite01 a {
    padding: 30px 55px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .btnWhite01 a::after {
    width: 1.5625vw;
    max-width: 15px;
    height: 3.125vw;
    max-height: 30px;
    right: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .btnWhite01 a::after {
    right: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .btnWhite01.back a::after {
    left: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .btnWhite01.back a::after {
    left: 40px;
  }
}

@media screen and (max-width: 767px) {
  .btnWhite01 {
    width: 54.13333vw;
    max-width: 406px;
  }
  .btnWhite01 a {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    border-radius: 80px;
    box-shadow: 0 2px 3px 0 #00000029;
    padding: 4vw 7.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .btnWhite01 a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  .btnWhite01 a {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btnWhite01 a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .btnWhite01 a {
    padding: 30px 55px;
  }
}

@media screen and (max-width: 767px) {
  .btnWhite01 a::after {
    width: 2vw;
    max-width: 15px;
    height: 4vw;
    max-height: 30px;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .btnWhite01 a::after {
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .btnWhite01.back a::after {
    left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .btnWhite01.back a::after {
    left: 40px;
  }
}

.mailBtn {
  width: 17.13031vw;
  max-width: 234px;
  margin: 0 auto;
}

.mailBtn a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Teko", sans-serif;
  font-size: 2.4rem;
  font-size: 1.66667vw;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.72px;
  color: #555;
  border-radius: 27px;
  border: solid 1px #555;
  background-color: #fff;
  transition: all .3s;
  padding: 0.73206vw 0vw;
}

@media screen and (min-width: 1366px) {
  .mailBtn a {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .mailBtn a {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) {
  .mailBtn a {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  .mailBtn a {
    padding: 10px 0px;
  }
}

.mailBtn a::before {
  content: "";
  display: block;
  flex-shrink: 0;
  background-image: url(/images/common/icon_contact.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s;
  width: 1.53734vw;
  max-width: 21px;
  height: 1.1713vw;
  max-height: 16px;
  margin-right: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  .mailBtn a::before {
    margin-right: 20px;
  }
}

.mailBtn a:hover {
  color: #fff;
  background-color: #555;
}

.mailBtn a:hover::before {
  background-image: url(/images/common/icon_contact_white.svg);
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .mailBtn {
    width: 44.79167vw;
    max-width: 430px;
  }
  .mailBtn a {
    font-size: 4rem;
    font-size: 2.77778vw;
    letter-spacing: 0.6px;
    border-radius: 80px;
    padding: 2.08333vw 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .mailBtn a {
    font-size: 4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  .mailBtn a {
    font-size: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  .mailBtn a {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .mailBtn a {
    padding: 20px 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .mailBtn a::before {
    width: 4.27083vw;
    max-width: 41px;
    height: 3.33333vw;
    max-height: 32px;
    margin-right: 2.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .mailBtn a::before {
    margin-right: 24px;
  }
}

@media screen and (max-width: 767px) {
  .mailBtn {
    width: 57.33333vw;
    max-width: 430px;
  }
  .mailBtn a {
    font-size: 4rem;
    font-size: 2.77778vw;
    letter-spacing: 0.6px;
    border-radius: 80px;
    padding: 2.66667vw 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .mailBtn a {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  .mailBtn a {
    font-size: 4.16667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .mailBtn a {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .mailBtn a {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 767px) {
  .mailBtn a::before {
    width: 5.46667vw;
    max-width: 41px;
    height: 4.26667vw;
    max-height: 32px;
    margin-right: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .mailBtn a::before {
    margin-right: 24px;
  }
}

#lastContant {
  position: relative;
  z-index: 1;
}

#lastContant.scrollDown::before {
  opacity: 1;
  visibility: visible;
}

#lastContant::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .8s;
  z-index: 1;
}

#notFoundTop .spaceArea {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  min-height: 100vh;
  text-align: center;
  background-image: url(/images/works/bg_outerspace.jpg);
  background-position: center;
  padding: 0 20px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea {
    background-image: url(/images/works/bg_outerspace_sp.jpg);
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea {
    background-image: url(/images/works/bg_outerspace_sp.jpg);
  }
}

#notFoundTop .spaceArea h2 {
  -webkit-text-stroke: 2px #fff;
  font-family: "Teko", sans-serif;
  font-size: 12rem;
  font-size: 8.33333vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 3.6px;
  text-align: center;
  color: rgba(224, 236, 244, 0);
}

@media screen and (min-width: 1366px) {
  #notFoundTop .spaceArea h2 {
    font-size: 12rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea h2 {
    font-size: 12.5vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea h2 {
    font-size: 16vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea h2 {
    letter-spacing: 1.8px;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea h2 {
    letter-spacing: 1.8px;
  }
}

#notFoundTop .spaceArea .lead {
  font-size: 2.4rem;
  font-size: 1.66667vw;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1.44px;
  text-align: center;
  color: #fff;
  margin-top: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #notFoundTop .spaceArea .lead {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea .lead {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea .lead {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #notFoundTop .spaceArea .lead {
    margin-top: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea .lead {
    font-size: 4.2rem;
    font-size: 2.91667vw;
    margin-top: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #notFoundTop .spaceArea .lead {
    font-size: 4.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea .lead {
    font-size: 4.375vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #notFoundTop .spaceArea .lead {
    font-size: 5.6vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #notFoundTop .spaceArea .lead {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea .lead {
    font-size: 4.2rem;
    font-size: 2.91667vw;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #notFoundTop .spaceArea .lead {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea .lead {
    font-size: 4.375vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .spaceArea .lead {
    font-size: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #notFoundTop .spaceArea .lead {
    margin-top: 60px;
  }
}

#notFoundTop .spaceArea p {
  font-size: 1.6rem;
  font-size: 1.11111vw;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-top: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #notFoundTop .spaceArea p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea p {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea p {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #notFoundTop .spaceArea p {
    margin-top: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea p {
    display: inline-block;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    text-align: left;
    margin-top: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #notFoundTop .spaceArea p {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea p {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #notFoundTop .spaceArea p {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #notFoundTop .spaceArea p {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea p {
    display: inline-block;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    text-align: left;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #notFoundTop .spaceArea p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea p {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .spaceArea p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #notFoundTop .spaceArea p {
    margin-top: 30px;
  }
}

#notFoundTop .spaceArea .btnWhite01 {
  margin-top: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #notFoundTop .spaceArea .btnWhite01 {
    margin-top: 80px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #notFoundTop .spaceArea .btnWhite01 {
    margin: 0 auto;
    margin-top: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #notFoundTop .spaceArea .btnWhite01 {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .spaceArea .btnWhite01 {
    margin: 0 auto;
    margin-top: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #notFoundTop .spaceArea .btnWhite01 {
    margin-top: 100px;
  }
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.bottom .breadcrumb {
  background-color: rgba(230, 195, 216, 0.5);
}

.breadcrumb {
  position: relative;
  z-index: 3;
  width: 100%;
  margin: 0 auto;
  clear: both;
  padding: 15px 0;
  background-color: #e6c3d8;
  overflow: hidden;
}

.breadcrumb.scrollDown::before {
  opacity: 1;
  visibility: visible;
}

.breadcrumb::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 102%;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .8s;
  z-index: -1;
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  color: #acabab;
  padding: 15px 25px;
}

.breadcrumb ol::after {
  display: block;
  clear: both;
  content: "";
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol {
    max-width: initial;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol {
    max-width: initial;
    padding: 10px 20px;
  }
}

.breadcrumb ol > li {
  position: relative;
  display: inline;
  font-size: 1.2rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  letter-spacing: .42px;
  text-align: left;
  color: #808080;
}

@media screen and (min-width: 1366px) {
  .breadcrumb ol > li {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 1.6vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li {
    font-size: 2.2rem;
    font-size: 1.52778vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .breadcrumb ol > li {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li {
    font-size: 2.29167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.2rem;
    font-size: 1.52778vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .breadcrumb ol > li {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li {
    font-size: 2.29167vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.93333vw;
  }
}

.breadcrumb ol > li:first-of-type {
  padding-left: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  .breadcrumb ol > li:first-of-type {
    padding-left: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li:first-of-type {
    padding-left: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .breadcrumb ol > li:first-of-type {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li:first-of-type {
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .breadcrumb ol > li:first-of-type {
    padding-left: 30px;
  }
}

.breadcrumb ol > li:first-of-type::before {
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-50%);
  width: 1.02489vw;
  max-width: 14px;
  height: 0.58565vw;
  max-height: 8px;
  margin: auto;
  content: "";
  background-image: url("/images/common/icon_home.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li:first-of-type::before {
    width: 2.08333vw;
    max-width: 20px;
    height: 2.08333vw;
    max-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li:first-of-type::before {
    width: 2.66667vw;
    max-width: 20px;
    height: 2.66667vw;
    max-height: 20px;
  }
}

.breadcrumb ol > li a {
  font-size: 1.2rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  letter-spacing: .42px;
  text-align: left;
  color: #808080;
}

@media screen and (min-width: 1366px) {
  .breadcrumb ol > li a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li a {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 1.6vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li a {
    font-size: 2.2rem;
    font-size: 1.52778vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .breadcrumb ol > li a {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li a {
    font-size: 2.29167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.2rem;
    font-size: 1.52778vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .breadcrumb ol > li a {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li a {
    font-size: 2.29167vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.93333vw;
  }
}

.breadcrumb ol > li + li::before {
  padding-right: 0.87848vw;
  padding-left: 0.73206vw;
  color: #ACABAB;
  content: ">";
}

@media screen and (min-width: 1366px) {
  .breadcrumb ol > li + li::before {
    padding-right: 12px;
  }
}

@media screen and (min-width: 1366px) {
  .breadcrumb ol > li + li::before {
    padding-left: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  .breadcrumb ol > li + li::before {
    padding-right: 1.45833vw;
    padding-left: 1.04167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .breadcrumb ol > li + li::before {
    padding-right: 14px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  .breadcrumb ol > li + li::before {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    padding-right: 1.86667vw;
    padding-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .breadcrumb ol > li + li::before {
    padding-right: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .breadcrumb ol > li + li::before {
    padding-left: 10px;
  }
}

/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

#header.isShow {
  z-index: 999;
  opacity: 1;
  visibility: visible;
}

#header .logo {
  position: fixed;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, visibility .5s;
  top: 2.92826vw;
  left: 2.92826vw;
  width: 11.71303vw;
  max-width: 160px;
}

@media screen and (min-width: 1366px) {
  #header .logo {
    top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #header .logo {
    left: 40px;
  }
}

#header .logo.isShow {
  transition: opacity .5s .55s, visibility .5s .55s;
  opacity: 1;
  visibility: visible;
}

#header .logo.isBottomShow {
  transition: opacity .5s, visibility .5s;
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .logo {
    top: 4.16667vw;
    left: 4.16667vw;
    width: 21.5625vw;
    max-width: 207px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .logo {
    top: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .logo {
    left: 40px;
  }
}

@media screen and (max-width: 767px) {
  #header .logo {
    top: 5.33333vw;
    left: 5.33333vw;
    width: 27.6vw;
    max-width: 207px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .logo {
    top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .logo {
    left: 40px;
  }
}

#header .navBtn {
  position: fixed;
  z-index: 2;
  top: 2.92826vw;
  right: 2.92826vw;
  width: 5.85652vw;
  max-width: 80px;
  height: 5.85652vw;
  max-height: 80px;
  padding-top: 1.31772vw;
  border-radius: 100%;
  box-shadow: 0 3px 6px 0 #00000029;
  cursor: pointer;
  background-color: #e0ecf4;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, visibility .5s;
}

@media screen and (min-width: 1366px) {
  #header .navBtn {
    top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #header .navBtn {
    right: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #header .navBtn {
    padding-top: 18px;
  }
}

#header .navBtn.isShow {
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .navBtn {
    top: 4.16667vw;
    right: 4.16667vw;
    width: 11.45833vw;
    max-width: 110px;
    height: 11.45833vw;
    max-height: 110px;
    padding-top: 2.8125vw;
    box-shadow: 0 2px 3px 0 #00000029;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .navBtn {
    top: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .navBtn {
    right: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .navBtn {
    padding-top: 27px;
  }
}

@media screen and (max-width: 767px) {
  #header .navBtn {
    top: 5.33333vw;
    right: 5.33333vw;
    width: 14.66667vw;
    max-width: 110px;
    height: 14.66667vw;
    max-height: 110px;
    padding-top: 3.6vw;
    box-shadow: 0 2px 3px 0 #00000029;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .navBtn {
    top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .navBtn {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .navBtn {
    padding-top: 27px;
  }
}

#header .navBtn .deco {
  position: relative;
  margin: 0 auto;
  width: 1.90337vw;
  max-width: 26px;
  height: 0.58565vw;
  max-height: 8px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .navBtn .deco {
    width: 5vw;
    max-width: 48px;
    height: 1.25vw;
    max-height: 12px;
  }
}

@media screen and (max-width: 767px) {
  #header .navBtn .deco {
    width: 6.4vw;
    max-width: 48px;
    height: 1.6vw;
    max-height: 12px;
  }
}

#header .navBtn span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 1.90337vw;
  max-width: 26px;
  height: 1px;
  background-color: #333;
  transition: .4s ease-in-out;
}

#header .navBtn span:nth-of-type(1) {
  top: 0;
}

#header .navBtn span:nth-of-type(2) {
  bottom: 0;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .navBtn span {
    width: 5vw;
    max-width: 48px;
  }
}

@media screen and (max-width: 767px) {
  #header .navBtn span {
    width: 6.4vw;
    max-width: 48px;
  }
}

#header .navBtn.isOpen {
  background-color: #fff;
}

#header .navBtn.isOpen span:nth-of-type(1) {
  transform: rotate(-150deg);
}

#header .navBtn.isOpen span:nth-of-type(2) {
  transform: rotate(150deg);
  top: 0;
}

#header .navBtn .navBtnTxt {
  font-family: "Teko", sans-serif;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  font-weight: 300;
  line-height: 1.88;
  letter-spacing: 0.48px;
  text-align: center;
  color: #333;
  margin-top: 0.14641vw;
}

@media screen and (min-width: 1366px) {
  #header .navBtn .navBtnTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .navBtn .navBtnTxt {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #header .navBtn .navBtnTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #header .navBtn .navBtnTxt {
    margin-top: 2px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .navBtn .navBtnTxt {
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.45px;
    margin-top: 0.52083vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .navBtn .navBtnTxt {
    font-size: 3rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #header .navBtn .navBtnTxt {
    font-size: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #header .navBtn .navBtnTxt {
    font-size: 4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .navBtn .navBtnTxt {
    margin-top: 5px;
  }
}

@media screen and (max-width: 767px) {
  #header .navBtn .navBtnTxt {
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.45px;
    margin-top: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .navBtn .navBtnTxt {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #header .navBtn .navBtnTxt {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .navBtn .navBtnTxt {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .navBtn .navBtnTxt {
    margin-top: 5px;
  }
}

#header .globalNavArea {
  visibility: hidden;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to bottom, #9fcfd3, #fcf9f5 50%, #e6c3d8);
  z-index: 1;
  transform: translate(-50%, -50%) scale(0, 0);
  transition: transform .3s .55s, opacity .4s .55s, visibility .4s .55s;
  overflow-y: scroll;
  -webkit-backface-visibility: hidden;
}

#header .globalNavArea::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/images/common/menu_bg_pc@2x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 0;
}

#header .globalNavArea .wrap {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding-top: 10.2489vw;
  padding-bottom: 13.17716vw;
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .wrap {
    padding-top: 140px;
  }
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .wrap {
    padding-bottom: 180px;
  }
}

#header .globalNavArea .globalNavAreaContents {
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: transform .5s, opacity .5s, visibility .5s;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: opacity,transform;
}

#header .globalNavArea.isShow {
  transition: transform .3s, opacity .4s ease-out, visibility .4s ease-out;
  transform: translate(-50%, -50%) scale(1, 1);
  opacity: 1;
  visibility: visible;
}

#header .globalNavArea.isShow .globalNavAreaContents {
  transition: transform .5s .55s, opacity .5s .55s, visibility .5s .55s;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea::before {
    background-image: url(/images/common/menu_bg_sp@2x.png);
  }
  #header .globalNavArea .wrap {
    padding-top: 25vw;
    padding-bottom: 25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .wrap {
    padding-top: 240px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .wrap {
    padding-bottom: 240px;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea::before {
    background-image: url(/images/common/menu_bg_sp@2x.png);
  }
  #header .globalNavArea .wrap {
    padding-top: 32vw;
    padding-bottom: 32vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .wrap {
    padding-top: 240px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .wrap {
    padding-bottom: 240px;
  }
}

#header .globalNavArea .globalNav {
  font-family: "Teko", sans-serif;
  font-size: 6rem;
  font-size: 4.16667vw;
  font-weight: 300;
  letter-spacing: 1.8px;
  text-align: center;
  color: #333;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .globalNav {
    font-size: 6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNav {
    font-size: 6.25vw;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea .globalNav {
    font-size: 8vw;
  }
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .globalNav {
    margin-bottom: 10px;
  }
}

#header .globalNavArea .globalNav a {
  color: #333;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNav {
    font-size: 8rem;
    font-size: 5.55556vw;
    letter-spacing: 1.2px;
    margin-bottom: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .globalNav {
    font-size: 8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNav {
    font-size: 8.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #header .globalNavArea .globalNav {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .globalNav {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea .globalNav {
    font-size: 8rem;
    font-size: 5.55556vw;
    letter-spacing: 1.2px;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .globalNav {
    font-size: 8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNav {
    font-size: 8.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .globalNavArea .globalNav {
    font-size: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .globalNav {
    margin-bottom: 20px;
  }
}

#header .globalNavArea .accordionBtn {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

#header .globalNavArea .accordionBtn.isOpen::after {
  transform: translateY(-50%) rotate(0deg);
}

#header .globalNavArea .accordionBtn::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  background-image: url(/images/common/icon_arow_up.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s;
  width: 1.24451vw;
  max-width: 17px;
  height: 1.0981vw;
  max-height: 15px;
  right: -2.70864vw;
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .accordionBtn::after {
    right: -37px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .accordionBtn::after {
    width: 1.77083vw;
    max-width: 17px;
    height: 1.5625vw;
    max-height: 15px;
    right: -4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .accordionBtn::after {
    right: -40px;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea .accordionBtn::after {
    width: 2.26667vw;
    max-width: 17px;
    height: 2vw;
    max-height: 15px;
    right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .accordionBtn::after {
    right: -40px;
  }
}

#header .globalNavArea dd {
  display: none;
  padding-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea dd {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea dd {
    padding-bottom: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea dd {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea dd {
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea dd {
    padding-bottom: 60px;
  }
}

#header .globalNavArea .globalNavCon {
  font-family: "Teko", sans-serif;
  font-size: 2.8rem;
  font-size: 1.94444vw;
  font-weight: 300;
  letter-spacing: 0.84px;
  text-align: center;
  color: #333;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .globalNavCon {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNavCon {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea .globalNavCon {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .globalNavCon {
    margin-bottom: 10px;
  }
}

#header .globalNavArea .globalNavCon:last-of-type {
  margin-bottom: 0;
}

#header .globalNavArea .globalNavCon a {
  color: #333;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNavCon {
    font-size: 4.8rem;
    font-size: 3.33333vw;
    margin-bottom: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .globalNavCon {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNavCon {
    font-size: 5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #header .globalNavArea .globalNavCon {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .globalNavCon {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea .globalNavCon {
    font-size: 4.8rem;
    font-size: 3.33333vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .globalNavCon {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .globalNavCon {
    font-size: 5vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .globalNavArea .globalNavCon {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .globalNavCon {
    margin-bottom: 20px;
  }
}

#header .globalNavArea .mailBtn {
  margin-top: 2.92826vw;
  margin-bottom: 2.56223vw;
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .mailBtn {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #header .globalNavArea .mailBtn {
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .mailBtn {
    margin-top: 9.0625vw;
    margin-bottom: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .mailBtn {
    margin-top: 87px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #header .globalNavArea .mailBtn {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea .mailBtn {
    margin-top: 11.6vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .mailBtn {
    margin-top: 87px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .globalNavArea .mailBtn {
    margin-bottom: 100px;
  }
}

#header .globalNavArea .snsBtn {
  width: 2.19619vw;
  max-width: 30px;
  margin: 0 auto;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #header .globalNavArea .snsBtn {
    width: 6.45833vw;
    max-width: 62px;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNavArea .snsBtn {
    width: 8.26667vw;
    max-width: 62px;
  }
}

/* =====================================================
フッター
===================================================== */
.bottom #footer {
  background-color: rgba(230, 195, 216, 0.5);
}

#footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
  height: 90vh;
  z-index: 1;
  padding-top: 7.46706vw;
  padding-bottom: 0.73206vw;
  transition: all .8s;
  background-color: #e6c3d8;
}

@media screen and (min-width: 1366px) {
  #footer {
    padding-top: 102px;
  }
}

@media screen and (min-width: 1366px) {
  #footer {
    padding-bottom: 10px;
  }
}

#footer.scrollDown {
  background-color: #fff;
}

#footer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/images/common/footer_bg_01.png);
  background-position: center;
  background-repeat: repeat-x;
  z-index: -1;
  animation: spaceFooterAnime01 3s linear infinite alternate;
}

#footer::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/images/common/footer_bg_02.png);
  background-repeat: repeat-x;
  background-position: center;
  z-index: -1;
  animation: spaceFooterAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer {
    padding-top: 10.41667vw;
    padding-bottom: 2.60417vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer {
    padding-top: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer::before {
    background-image: url(/images/common/footer_bg_01_sp.png);
    background-size: contain;
  }
  #footer::after {
    background-image: url(/images/common/footer_bg_02_sp.png);
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  #footer {
    padding-top: 13.33333vw;
    padding-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  #footer::before {
    background-image: url(/images/common/footer_bg_01_sp.png);
    background-size: contain;
  }
  #footer::after {
    background-image: url(/images/common/footer_bg_02_sp.png);
    background-size: contain;
  }
}

#footer .footerLogo {
  margin: 0 auto;
  width: 14.64129vw;
  max-width: 200px;
  margin-bottom: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #footer .footerLogo {
    margin-bottom: 60px;
  }
}

#footer .footerLogo img {
  width: 100%;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .footerLogo {
    width: 28.125vw;
    max-width: 270px;
    margin-bottom: 9.89583vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .footerLogo {
    margin-bottom: 95px;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLogo {
    width: 36vw;
    max-width: 270px;
    margin-bottom: 12.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .footerLogo {
    margin-bottom: 95px;
  }
}

#footer .footerNavArea {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #footer .footerNavArea {
    margin-bottom: 20px;
  }
}

#footer .footerNavArea .footerNav {
  font-family: "Teko", sans-serif;
  font-size: 2.4rem;
  font-size: 1.66667vw;
  font-weight: 300;
  letter-spacing: 0.72px;
  color: #555555;
  margin-right: 1.0981vw;
  margin-left: 1.0981vw;
}

@media screen and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .footerNavArea .footerNav {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerNavArea .footerNav {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    margin-left: 15px;
  }
}

#footer .footerNavArea .footerNav a {
  color: #555555;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .footerNavArea {
    margin-bottom: 5.83333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .footerNavArea {
    margin-bottom: 56px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .footerNavArea .footerNav {
    font-size: 4.6rem;
    font-size: 3.19444vw;
    letter-spacing: .69px;
    margin-right: 2.60417vw;
    margin-left: 2.60417vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    font-size: 4.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #footer .footerNavArea .footerNav {
    font-size: 4.79167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #footer .footerNavArea .footerNav {
    font-size: 6.13333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    margin-right: 25px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    margin-left: 25px;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerNavArea {
    margin-bottom: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .footerNavArea {
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerNavArea .footerNav {
    font-size: 4.6rem;
    font-size: 3.19444vw;
    letter-spacing: .69px;
    margin-right: 3.33333vw;
    margin-left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #footer .footerNavArea .footerNav {
    font-size: 4.79167vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerNavArea .footerNav {
    font-size: 6.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .footerNavArea .footerNav {
    margin-left: 25px;
  }
}

#footer .mailBtn {
  margin-bottom: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #footer .mailBtn {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .mailBtn {
    margin-bottom: 8.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .mailBtn {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #footer .mailBtn {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .mailBtn {
    margin-bottom: 80px;
  }
}

#footer .snsBtn {
  width: 2.19619vw;
  max-width: 30px;
  margin: 0 auto;
  margin-bottom: 6.14934vw;
}

@media screen and (min-width: 1366px) {
  #footer .snsBtn {
    margin-bottom: 84px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .snsBtn {
    width: 5.72917vw;
    max-width: 55px;
    margin-bottom: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .snsBtn {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #footer .snsBtn {
    width: 7.33333vw;
    max-width: 55px;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .snsBtn {
    margin-bottom: 100px;
  }
}

#footer .scrPageTop {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Teko", sans-serif;
  font-size: 2rem;
  font-size: 1.38889vw;
  line-height: 1;
  letter-spacing: 0.6px;
  color: #808080;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  cursor: pointer;
  transition: transform .3s;
  padding-top: 3.73353vw;
  padding-bottom: 5.85652vw;
  right: 9.73646vw;
  z-index: 20;
}

@media screen and (min-width: 1366px) {
  #footer .scrPageTop {
    font-size: 2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .scrPageTop {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .scrPageTop {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #footer .scrPageTop {
    padding-top: 51px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .scrPageTop {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .scrPageTop {
    right: 133px;
  }
}

#footer .scrPageTop::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0.73206vw;
  background-image: url(/images/common/footer_pagetop_arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 0.43924vw;
  max-width: 6px;
  height: 3.00146vw;
  max-height: 41px;
  transition: all .3s;
}

@media screen and (min-width: 1366px) {
  #footer .scrPageTop::before {
    left: 10px;
  }
}

#footer .scrPageTop::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(/images/common/footer_pagetop_taco@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 6.22255vw;
  max-width: 85px;
  height: 4.31918vw;
  max-height: 59px;
  transition: all .3s;
}

#footer .scrPageTop:hover {
  transform: translateY(-55%);
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .scrPageTop {
    transform: none;
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.45px;
    padding-top: 8.64583vw;
    padding-bottom: 6.97917vw;
    top: 4.16667vw;
    right: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .scrPageTop {
    font-size: 3rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #footer .scrPageTop {
    font-size: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #footer .scrPageTop {
    font-size: 4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .scrPageTop {
    padding-top: 83px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .scrPageTop {
    padding-bottom: 67px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .scrPageTop {
    top: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .scrPageTop {
    right: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .scrPageTop::before {
    left: 1.5625vw;
    width: 1.25vw;
    max-width: 12px;
    height: 5.41667vw;
    max-height: 52px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .scrPageTop::before {
    left: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .scrPageTop::after {
    width: 7.60417vw;
    max-width: 73px;
    height: 5.41667vw;
    max-height: 52px;
  }
  #footer .scrPageTop:hover {
    transform: translateY(-15%);
  }
}

@media screen and (max-width: 767px) {
  #footer .scrPageTop {
    transform: none;
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.45px;
    padding-top: 11.06667vw;
    padding-bottom: 8.93333vw;
    top: 5.33333vw;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .scrPageTop {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #footer .scrPageTop {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .scrPageTop {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .scrPageTop {
    padding-top: 83px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .scrPageTop {
    padding-bottom: 67px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .scrPageTop {
    top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .scrPageTop {
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  #footer .scrPageTop::before {
    left: 2vw;
    width: 1.6vw;
    max-width: 12px;
    height: 6.93333vw;
    max-height: 52px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .scrPageTop::before {
    left: 15px;
  }
}

@media screen and (max-width: 767px) {
  #footer .scrPageTop::after {
    width: 9.73333vw;
    max-width: 73px;
    height: 6.93333vw;
    max-height: 52px;
  }
  #footer .scrPageTop:hover {
    transform: translateY(-15%);
  }
}

#footer .copyRight {
  display: block;
  font-size: 1rem;
  font-size: 0.69444vw;
  text-align: center;
  color: #808080;
}

@media screen and (min-width: 1366px) {
  #footer .copyRight {
    font-size: 1rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .copyRight {
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyRight {
    font-size: 1.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #footer .copyRight {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 2rem;
    font-size: 1.38889vw;
    border-top: solid 1px #cccccc;
    background-color: #fff;
    padding-top: 2.08333vw;
    padding-bottom: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .copyRight {
    font-size: 2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #footer .copyRight {
    font-size: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #footer .copyRight {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .copyRight {
    padding-top: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #footer .copyRight {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyRight {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 2rem;
    font-size: 1.38889vw;
    border-top: solid 1px #cccccc;
    background-color: #fff;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .copyRight {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #footer .copyRight {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .copyRight {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .copyRight {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .copyRight {
    padding-bottom: 20px;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity .8s .6s, transform .5s .5s;
  backface-visibility: hidden;
}

.fadeIn.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

@keyframes spaceAnime01 {
  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }
  50% {
    transform: translateY(-20px) translateX(-3px);
  }
}

@keyframes spaceAnime02 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes spaceAnime03 {
  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }
  50% {
    transform: translateY(20px) translateX(6px);
  }
}

@keyframes spaceAnimeSp01 {
  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }
  50% {
    transform: translateY(-10px) translateX(-2px);
  }
}

@keyframes spaceAnimeSp02 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes spaceAnimeSp03 {
  0%,
  100% {
    transform: translateY(0) translateX(0px);
  }
  50% {
    transform: translateY(10px) translateX(3px);
  }
}

@keyframes bound {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.7);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spaceFooterAnime01 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes spaceFooterAnime02 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

@keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    width: 180px;
    height: 180px;
    background-color: rgba(117, 148, 207, 0.1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sdbSp03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 22px rgba(117, 148, 207, 0.4);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 38;
  }
  25% {
    stroke-dashoffset: 38;
  }
  50% {
    stroke-dashoffset: 0;
  }
  51% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: -38;
  }
  100% {
    stroke-dashoffset: -38;
  }
}

@keyframes line02 {
  0% {
    transform: scale(0, 1);
    transform-origin: center right;
  }
  25% {
    transform: scale(0, 1);
    transform-origin: center right;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: center right;
  }
  51% {
    transform: scale(1, 1);
    transform-origin: center left;
  }
  80% {
    transform: scale(0, 1);
    transform-origin: center left;
  }
  100% {
    transform: scale(0, 1);
    transform-origin: center left;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop {
  overflow-x: hidden;
}

#indexTop .mainview {
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: #00232E;
}

@media screen and (min-width: 961px) and (max-width: 1024px) {
  #indexTop .mainview {
    max-height: 90vh;
  }
}

#indexTop .mainview.scrollDown .commonSeen {
  display: none;
}

#indexTop .mainview .scroll {
  opacity: 0;
  position: absolute;
  bottom: 2.56223vw;
  left: 50%;
  transform: translateX(-50%);
  width: 3.3675vw;
  max-width: 46px;
  height: 3.3675vw;
  max-height: 46px;
  border: 1px solid #7594cf;
  font-family: "Teko", sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  letter-spacing: 0.72px;
  text-align: center;
  color: #7594cf;
  background-color: #7594cf;
  border-radius: 100%;
  z-index: 9;
  mix-blend-mode: revert;
  transition: opacity .8s;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .scroll {
    bottom: 35px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .scroll {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .scroll {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .scroll {
    font-size: 2.4vw;
  }
}

#indexTop .mainview .scroll.isShow {
  opacity: 1;
}

#indexTop .mainview .scroll.isGradation {
  mix-blend-mode: multiply;
}

#indexTop .mainview .scroll span {
  display: block;
  margin-top: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .scroll span {
    margin-top: 50px;
  }
}

#indexTop .mainview .scroll::after {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  content: '';
  width: 1.1713vw;
  max-width: 16px;
  height: 1.1713vw;
  max-height: 16px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  box-sizing: border-box;
}

#indexTop .mainview .scroll::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(117, 148, 207, 0.4);
  border-radius: 100%;
  opacity: 0;
  animation: sdb03 1.5s infinite;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .scroll {
    bottom: 14.0625vw;
    width: 6.45833vw;
    max-width: 62px;
    height: 6.45833vw;
    max-height: 62px;
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.35px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .scroll {
    bottom: 135px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .scroll {
    font-size: 3rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .scroll {
    font-size: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .mainview .scroll {
    font-size: 4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .scroll span {
    display: none;
  }
  #indexTop .mainview .scroll::after {
    width: 2.29167vw;
    max-width: 22px;
    height: 2.29167vw;
    max-height: 22px;
  }
  #indexTop .mainview .scroll::before {
    animation: sdbSp03 1.5s infinite;
    width: 6.04167vw;
    max-width: 58px;
    height: 6.04167vw;
    max-height: 58px;
    background-color: inherit;
    box-shadow: 0 0 0 0 rgba(117, 148, 207, 0.8);
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .scroll {
    bottom: 22vw;
    width: 8.26667vw;
    max-width: 62px;
    height: 8.26667vw;
    max-height: 62px;
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .scroll {
    bottom: 165px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .scroll {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .scroll {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .mainview .scroll {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .scroll span {
    display: none;
  }
  #indexTop .mainview .scroll::after {
    width: 2.93333vw;
    max-width: 22px;
    height: 2.93333vw;
    max-height: 22px;
  }
  #indexTop .mainview .scroll::before {
    animation: sdbSp03 3s infinite;
    width: 7.73333vw;
    max-width: 58px;
    height: 7.73333vw;
    max-height: 58px;
  }
}

#indexTop .mainview .seen01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s_1_bg@2x.jpg);
  background-position: center;
  background-size: cover;
  z-index: 1;
  transition: opacity .8s;
  opacity: 0;
}

#indexTop .mainview .seen01.isShow {
  opacity: 1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 {
    background-image: url(/images/mainview/s_1_bg_sp@2x.png);
    background-color: #08222d;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 {
    background-image: url(/images/mainview/s_1_bg_sp@2x.png);
    background-color: #08222d;
  }
}

#indexTop .mainview .seen01 .wrap {
  position: absolute;
  max-width: 1366px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 56.22255vw;
  max-height: 768px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 .wrap {
    max-height: 100vh;
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .wrap {
    max-height: 100vh;
    height: 100vh;
  }
}

#indexTop .mainview .seen01 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#indexTop .mainview .seen01 .mainviewTit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 52.2694vw;
  max-width: 714px;
  z-index: 1;
  opacity: 0;
}

#indexTop .mainview .seen01 .mainviewTit.isShow {
  opacity: 1;
}

#indexTop .mainview .seen01 .mainviewTit.isShow .boundAnime {
  animation: bound .9s linear;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 .mainviewTit {
    width: 68.75vw;
    max-width: 660px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .mainviewTit {
    width: 88vw;
    max-width: 660px;
  }
}

#indexTop .mainview .seen01 .fade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
}

#indexTop .mainview .seen01 .move01 {
  position: relative;
  top: -70px;
  left: -80px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .move01 {
    top: -35px;
    left: -40px;
  }
}

#indexTop .mainview .seen01 .move02 {
  position: relative;
  top: -60px;
  left: -30px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .move02 {
    top: -30px;
    left: -15px;
  }
}

#indexTop .mainview .seen01 .move03 {
  position: relative;
  top: -60px;
  right: -90px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .move03 {
    top: -30px;
    right: -45px;
  }
}

#indexTop .mainview .seen01 .move04 {
  position: absolute;
  bottom: -60px;
  right: -60px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .move04 {
    bottom: -30px;
    left: -30px;
  }
}

#indexTop .mainview .seen01 .move05 {
  position: absolute;
  bottom: -60px;
  left: -60px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .move05 {
    bottom: -30px;
    right: -30px;
  }
}

#indexTop .mainview .seen01 .img01 {
  position: absolute;
  top: 1.31772vw;
  left: 2.48902vw;
  width: 35.65154vw;
  max-width: 487px;
  transform-origin: center bottom;
  animation: spaceAnime01 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img01 {
    top: 18px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img01 {
    left: 34px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 .img01 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    top: 5.3125vw;
    left: 1.35417vw;
    width: 43.4375vw;
    max-width: 417px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img01 {
    top: 51px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img01 {
    left: 13px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .img01 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    top: 6.8vw;
    left: 1.73333vw;
    width: 55.6vw;
    max-width: 417px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img01 {
    top: 51px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img01 {
    left: 13px;
  }
}

#indexTop .mainview .seen01 .img02 {
  position: absolute;
  top: 2.12299vw;
  left: 36.74963vw;
  width: 10.46852vw;
  max-width: 143px;
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img02 {
    top: 29px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img02 {
    left: 502px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 .img02 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    top: 3.4375vw;
    left: 47.70833vw;
    width: 11.14583vw;
    max-width: 107px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img02 {
    top: 33px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img02 {
    left: 458px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .img02 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    top: 4.4vw;
    left: 61.06667vw;
    width: 14.26667vw;
    max-width: 107px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img02 {
    top: 33px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img02 {
    left: 458px;
  }
}

#indexTop .mainview .seen01 .img03 {
  position: absolute;
  top: 2.12299vw;
  right: 2.12299vw;
  width: 30.30747vw;
  max-width: 414px;
  animation: spaceAnime03 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img03 {
    top: 29px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img03 {
    right: 29px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 .img03 {
    animation: spaceAnimeSp03 3s linear infinite alternate;
    top: 17.1875vw;
    right: 1.875vw;
    width: 34.79167vw;
    max-width: 334px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img03 {
    top: 165px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img03 {
    right: 18px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .img03 {
    animation: spaceAnimeSp03 3s linear infinite alternate;
    top: 22vw;
    right: 2.4vw;
    width: 44.53333vw;
    max-width: 334px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img03 {
    top: 165px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img03 {
    right: 18px;
  }
}

#indexTop .mainview .seen01 .img04 {
  position: absolute;
  bottom: 0.73206vw;
  right: 1.39092vw;
  width: 40.70278vw;
  max-width: 556px;
  animation: spaceAnime01 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img04 {
    bottom: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img04 {
    right: 19px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 .img04 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    bottom: 4.6875vw;
    right: 1.875vw;
    width: 50.10417vw;
    max-width: 481px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img04 {
    bottom: 45px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img04 {
    right: 18px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .img04 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    bottom: 6vw;
    right: 2.4vw;
    width: 64.13333vw;
    max-width: 481px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img04 {
    bottom: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img04 {
    right: 18px;
  }
}

#indexTop .mainview .seen01 .img05 {
  position: absolute;
  bottom: 1.31772vw;
  left: 5.49048vw;
  width: 29.79502vw;
  max-width: 407px;
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img05 {
    bottom: 18px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img05 {
    left: 75px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen01 .img05 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    bottom: 24.89583vw;
    left: 3.4375vw;
    width: 34.6875vw;
    max-width: 333px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img05 {
    bottom: 239px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img05 {
    left: 33px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen01 .img05 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    bottom: 31.86667vw;
    left: 4.4vw;
    width: 44.4vw;
    max-width: 333px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img05 {
    bottom: 239px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen01 .img05 {
    left: 33px;
  }
}

#indexTop .mainview .commonSeen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: opacity .8s;
}

#indexTop .mainview .commonSeen.hide {
  opacity: 0;
}

#indexTop .mainview .commonSeen.isShow::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

#indexTop .mainview .commonSeen::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  width: 150vmax;
  height: 150vmax;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #9fcfd3, #fcf9f5 49%, #e6c3d8);
  transform: translate(-50%, -50%) scale(0, 0);
  transition: transform 1s,opacity .8s;
  backface-visibility: hidden;
  perspective: 1000;
}

#indexTop .mainview .commonSeen .wrap {
  position: absolute;
  max-width: 1366px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 56.22255vw;
  max-height: 768px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .commonSeen .wrap {
    max-height: 100vh;
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .commonSeen .wrap {
    max-height: 100vh;
    height: 100vh;
  }
}

#indexTop .mainview .commonSeen img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#indexTop .mainview .seen02 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 1.46413vw 0.58565vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen02 {
    padding: 20px 8px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen02 {
    padding: 2.70833vw 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 {
    padding: 26px 0px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen02 {
    padding: 3.46667vw 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 {
    padding: 26px 0px;
  }
}

#indexTop .mainview .seen02 .backImg {
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s_2_bg_pc@2x.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen02 .backImg {
    background-image: url(/images/mainview/s_2_bg_sp@2x.png);
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen02 .backImg {
    background-image: url(/images/mainview/s_2_bg_sp@2x.png);
  }
}

#indexTop .mainview .seen02 .mainviewTit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
  transition: transform .4s;
  z-index: 1;
  width: 92.0205vw;
  max-width: 1257px;
  max-height: 100vh;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen02 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 76%;
    top: 4.6875vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 .mainviewTit {
    top: 45px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen02 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen02 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 76%;
    top: 6vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 .mainviewTit {
    top: 45px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen02 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

#indexTop .mainview .seen02 .imgFade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
  animation: spaceAnime01 3s linear infinite alternate;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen02 .imgFade {
    animation: spaceAnimeSp01 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen02 .imgFade {
    animation: spaceAnimeSp01 3s linear infinite alternate;
  }
}

#indexTop .mainview .seen02 .img01 {
  position: absolute;
  top: 1.46413vw;
  right: 3.95315vw;
  width: 40.62958vw;
  max-width: 555px;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img01 {
    top: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img01 {
    right: 54px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen02 .img01 {
    top: calc(50% - 29.16667vw);
    right: -1.875vw;
    width: 45.10417vw;
    max-width: 433px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img01 {
    right: -18px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen02 .img01 {
    top: calc(50% - 42.66667vw);
    right: -7.73333vw;
    width: 57.73333vw;
    max-width: 433px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img01 {
    right: -58px;
  }
}

#indexTop .mainview .seen02 .img02 {
  position: absolute;
  top: 15.8858vw;
  left: 1.90337vw;
  width: 43.70425vw;
  max-width: 597px;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img02 {
    top: 217px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img02 {
    left: 26px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen02 .img02 {
    top: auto;
    bottom: calc(50% - 31.66667vw);
    left: -6.77083vw;
    width: 49.375vw;
    max-width: 474px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img02 {
    left: -65px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen02 .img02 {
    top: auto;
    bottom: calc(50% - 45.2vw);
    left: -14vw;
    width: 63.2vw;
    max-width: 474px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen02 .img02 {
    left: -105px;
  }
}

#indexTop .mainview .seen03 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

#indexTop .mainview .seen03 .wrap {
  max-height: 768px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen03 .wrap {
    max-height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen03 .wrap {
    max-height: 100vh;
  }
}

#indexTop .mainview .seen03 .backImg {
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s_3_bg_pc@2x.png);
  background-size: contain;
  background-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen03 .backImg {
    background-image: url(/images/mainview/s_3_bg_sp@2x.png);
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen03 .backImg {
    background-image: url(/images/mainview/s_3_bg_sp@2x.png);
  }
}

#indexTop .mainview .seen03 .mainviewTit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
  transition: transform .4s;
  z-index: 1;
  width: 94.3631vw;
  max-width: 1289px;
  max-height: 100vh;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen03 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 76%;
    top: 4.6875vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen03 .mainviewTit {
    top: 45px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen03 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen03 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 76%;
    top: 6vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen03 .mainviewTit {
    top: 45px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen03 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

#indexTop .mainview .seen03 .imgFade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
}

#indexTop .mainview .seen03 .img01 {
  position: absolute;
  top: 6.95461vw;
  right: 7.83309vw;
  width: 39.7511vw;
  max-width: 543px;
  animation: spaceAnime03 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img01 {
    top: 95px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img01 {
    right: 107px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen03 .img01 {
    animation: spaceAnimeSp03 3s linear infinite alternate;
    top: calc(36% - 25.95833vw);
    right: 5.83333vw;
    width: 44.0625vw;
    max-width: 423px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img01 {
    right: 56px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen03 .img01 {
    animation: spaceAnimeSp03 3s linear infinite alternate;
    top: calc(36% - 32.66667vw);
    right: 7.46667vw;
    width: 56.4vw;
    max-width: 423px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img01 {
    right: 56px;
  }
}

#indexTop .mainview .seen03 .img02 {
  position: absolute;
  bottom: 7.17423vw;
  left: 6.8082vw;
  width: 39.8243vw;
  max-width: 544px;
  animation: spaceAnime01 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img02 {
    bottom: 98px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img02 {
    left: 93px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen03 .img02 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    bottom: calc(45% - 24.72917vw);
    left: 6.45833vw;
    width: 43.64583vw;
    max-width: 419px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img02 {
    left: 62px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen03 .img02 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    bottom: calc(45% - 36.33333vw);
    left: 8.26667vw;
    width: 55.86667vw;
    max-width: 419px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen03 .img02 {
    left: 62px;
  }
}

#indexTop .mainview .seen04 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  padding: 1.83016vw 0vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen04 {
    padding: 25px 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen04 {
    padding: 5.20833vw 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen04 {
    padding: 6.66667vw 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 {
    padding: 50px 0px;
  }
}

#indexTop .mainview .seen04 .backImg {
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s4_bg_pc.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (min-width: 1921px) {
  #indexTop .mainview .seen04 .backImg {
    background-size: contain;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen04 .backImg {
    background-image: url(/images/mainview/s_4_bg_sp@2x.png);
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen04 .backImg {
    background-image: url(/images/mainview/s_4_bg_sp@2x.png);
    background-size: contain;
  }
}

#indexTop .mainview .seen04 .mainviewTit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
  transition: transform .4s;
  width: 97.07174vw;
  max-width: 1326px;
  max-height: 100vh;
  z-index: 1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen04 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 82%;
    top: 0vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 .mainviewTit {
    top: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen04 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen04 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 82%;
    top: 0vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 .mainviewTit {
    top: 0px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen04 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

#indexTop .mainview .seen04 .imgFade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen04 .imgFade {
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen04 .imgFade {
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

#indexTop .mainview .seen04 .img01 {
  position: absolute;
  top: 1.83016vw;
  right: 5.7101vw;
  width: 62.66471vw;
  max-width: 856px;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img01 {
    top: 25px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img01 {
    right: 78px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img01 {
    right: 12%;
    max-width: 50%;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen04 .img01 {
    top: calc(50% - 39.58333vw);
    right: 8.22917vw;
    width: 77.8125vw;
    max-width: 747px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img01 {
    right: 79px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen04 .img01 {
    top: calc(50% - 43.2vw);
    right: 5.2vw;
    width: 86.26667vw;
    max-width: 647px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img01 {
    right: 39px;
  }
}

#indexTop .mainview .seen04 .img02 {
  position: absolute;
  bottom: 7.10102vw;
  left: 6.44217vw;
  width: 53.66032vw;
  max-width: 733px;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img02 {
    bottom: 97px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img02 {
    left: 88px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img02 {
    bottom: 11%;
    left: 10%;
    max-width: 50%;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen04 .img02 {
    bottom: calc(50% - 41.77083vw);
    left: 1.14583vw;
    width: 46.04167vw;
    max-width: 442px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img02 {
    left: 11px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen04 .img02 {
    bottom: calc(50% - 49.2vw);
    left: 1.46667vw;
    width: 58.93333vw;
    max-width: 442px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen04 .img02 {
    left: 11px;
  }
}

#indexTop .mainview .seen05 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#indexTop .mainview .seen05 .backImg {
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s_5_bg_pc@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen05 .backImg {
    background-image: url(/images/mainview/s_5_bg_sp@2x.png);
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen05 .backImg {
    background-image: url(/images/mainview/s_5_bg_sp@2x.png);
  }
}

#indexTop .mainview .seen05 .mainviewTit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
  transition: transform .4s;
  width: 95.38799vw;
  max-width: 1303px;
  max-height: 100vh;
  z-index: 1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen05 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 82%;
    top: 0.52083vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .mainviewTit {
    top: 5px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen05 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen05 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 82%;
    top: 0.66667vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .mainviewTit {
    top: 5px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen05 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

#indexTop .mainview .seen05 .imgFade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: opacity;
}

#indexTop .mainview .seen05 .img01 {
  position: absolute;
  bottom: 2.92826vw;
  left: 50%;
  transform: translateX(-50%);
  width: 80.52709vw;
  max-width: 1100px;
  max-height: 89.5%;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img01 {
    bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen05 .img01 {
    bottom: calc(50% - 33.91667vw);
    left: 50%;
    transform: translateX(-50%);
    width: 73.95833vw;
    max-width: 710px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen05 .img01 {
    transform: none;
    bottom: auto;
    top: calc(50% - 35.33333vw);
    left: 2.66667vw;
    width: 94.66667vw;
    max-width: 710px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img01 {
    left: 20px;
  }
}

#indexTop .mainview .seen05 .img02 {
  position: absolute;
  bottom: 2.92826vw;
  left: 50%;
  transform: translateX(-50%);
  width: 20.4978vw;
  max-width: 280px;
  max-height: 60.2%;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img02 {
    bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen05 .img02 {
    bottom: auto;
    top: 50.41667vw;
    left: 50%;
    transform: translateX(-50%);
    width: 25.625vw;
    max-width: 246px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img02 {
    top: 484px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen05 .img02 {
    transform: none;
    bottom: auto;
    top: 50.41667vw;
    left: 33.6vw;
    width: 32.8vw;
    max-width: 246px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img02 {
    top: 484px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img02 {
    left: 252px;
  }
}

#indexTop .mainview .seen05 .img03 {
  position: absolute;
  animation: spaceAnime02 3s linear infinite alternate;
  top: 3.00146vw;
  right: 30.16105vw;
  width: 13.90922vw;
  max-width: 190px;
  max-height: 25.3%;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img03 {
    top: 41px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img03 {
    right: 412px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen05 .img03 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    top: 23.02083vw;
    right: 10.10417vw;
    width: 16.14583vw;
    max-width: 155px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img03 {
    top: 221px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img03 {
    right: 97px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen05 .img03 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    top: calc(50% - 58.8vw);
    right: 12.93333vw;
    width: 20.66667vw;
    max-width: 155px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen05 .img03 {
    right: 97px;
  }
}

#indexTop .mainview .seen06 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
}

#indexTop .mainview .seen06 .wrap {
  max-height: 768px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen06 .wrap {
    max-height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen06 .wrap {
    max-height: 100vh;
  }
}

#indexTop .mainview .seen06 .backImg {
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s6_bg_pc.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen06 .backImg {
    background-image: url(/images/mainview/s_6_bg_sp@2x.png);
    background-size: 97.2%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen06 .backImg {
    background-image: url(/images/mainview/s_6_bg_sp@2x.png);
    background-size: 97.2%;
  }
}

#indexTop .mainview .seen06 .mainviewTit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
  transition: transform .4s;
  width: 98.8287vw;
  max-width: 1350px;
  max-height: 100vh;
  z-index: 1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen06 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 80%;
    top: 0vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .mainviewTit {
    top: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen06 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen06 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 80%;
    top: 0vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .mainviewTit {
    top: 0px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen06 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

#indexTop .mainview .seen06 .imgFade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: opacity;
}

#indexTop .mainview .seen06 .img01 {
  position: absolute;
  bottom: 4.46559vw;
  right: 16.03221vw;
  width: 62.59151vw;
  max-width: 855px;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img01 {
    bottom: 61px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img01 {
    right: 219px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen06 .img01 {
    bottom: calc(50% - 35.875vw);
    right: 0vw;
    width: 83.33333vw;
    max-width: 800px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img01 {
    right: 0px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen06 .img01 {
    bottom: calc(50% - 48.4vw);
    right: 0vw;
    width: 100vw;
    max-width: 750px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img01 {
    right: 0px;
  }
}

#indexTop .mainview .seen06 .img02 {
  position: absolute;
  animation: spaceAnime01 3s linear infinite alternate;
  top: 7.9063vw;
  right: 2.04978vw;
  width: 27.96486vw;
  max-width: 382px;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img02 {
    top: 108px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img02 {
    right: 28px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .mainview .seen06 .img02 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    top: 42.5vw;
    right: 3.22917vw;
    width: 31.04167vw;
    max-width: 298px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img02 {
    top: 408px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img02 {
    right: 31px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .seen06 .img02 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    top: 66.4vw;
    right: 4.13333vw;
    width: 33.06667vw;
    max-width: 248px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img02 {
    top: 498px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .mainview .seen06 .img02 {
    right: 31px;
  }
}

#indexTop .spaceArea {
  background-image: url(/images/index/onair_bg_pc@2x.jpg);
  background-position: center;
  background-size: contain;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .spaceArea {
    background-image: url(/images/index/onair_bg_sp@2x.jpg);
  }
}

@media screen and (max-width: 767px) {
  #indexTop .spaceArea {
    background-image: url(/images/index/onair_bg_sp@2x.jpg);
  }
}

#indexTop .onAir {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 12.4451vw;
  padding-bottom: 14.42167vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir {
    padding-top: 170px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir {
    padding-bottom: 197px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir {
    padding-top: 20.3125vw;
    padding-bottom: 16.66667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir {
    padding-top: 195px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir {
    padding-top: 26vw;
    padding-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir {
    padding-top: 195px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir {
    padding-bottom: 160px;
  }
}

#indexTop .onAir .wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1286px;
  width: 100%;
  padding-left: 6.8082vw;
  margin: 0 auto;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .wrap {
    padding-left: 93px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .wrap {
    display: block;
    padding-left: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .wrap {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .wrap {
    display: block;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .wrap {
    padding-left: 40px;
  }
}

#indexTop .onAir .txtBox {
  max-width: 18.441%;
  width: 100%;
  margin-right: 8.05271vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .txtBox {
    margin-right: 110px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .txtBox {
    max-width: 100%;
    margin-right: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .txtBox {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .txtBox {
    max-width: 100%;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .txtBox {
    margin-right: 0px;
  }
}

#indexTop .onAir .onAirTit {
  margin-bottom: 2.34261vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirTit {
    margin-bottom: 32px;
  }
}

#indexTop .onAir .onAirTit svg {
  width: 100%;
  height: auto;
}

#indexTop .onAir .onAirTit .cls-1 {
  fill: none;
  stroke-dashoffset: 0;
  stroke: rgba(224, 236, 244, 0);
  stroke-width: 2px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirTit {
    width: 39.375vw;
    max-width: 378px;
    margin-bottom: 4.79167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTit {
    margin-bottom: 46px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirTit {
    width: 50.4vw;
    max-width: 378px;
    margin-bottom: 6.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTit {
    margin-bottom: 46px;
  }
}

#indexTop .onAir .fadeOnAir {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

#indexTop .onAir .onAirTitLead {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  color: #fff;
  margin-bottom: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead {
    margin-bottom: 50px;
  }
}

#indexTop .onAir .onAirTitLead::before {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 3.29429vw;
  max-width: 45px;
  height: 1px;
  margin-right: 1.0981vw;
  background-color: #fff;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    margin-bottom: 72.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead {
    margin-bottom: 700px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirTitLead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2.66667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead::before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    margin-bottom: 93.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .onAir .onAirTitLead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead {
    margin-bottom: 700px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirTitLead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirTitLead::before {
    margin-right: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .btnWhite01 {
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .btnWhite01 {
    margin: 0 auto;
  }
}

#indexTop .onAir .onAirProgramListWrap {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 66.25183vw;
  max-width: 905px;
  max-width: none;
  height: 32.21083vw;
  max-height: 440px;
  overflow: hidden;
}

#indexTop .onAir .onAirProgramListWrap::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 7%;
  height: 100%;
  background-image: linear-gradient(90deg, #00232e 0%, rgba(0, 35, 46, 0.882791) 16%, rgba(0, 35, 46, 0) 100%);
  z-index: 1;
}

@media screen and (min-width: 1921px) {
  #indexTop .onAir .onAirProgramListWrap {
    width: 62.59151vw;
    max-width: 855px;
    max-width: none;
  }
}

@media screen and (min-width: 961px) and (max-width: 1024px) {
  #indexTop .onAir .onAirProgramListWrap {
    width: 71.67969vw;
    max-width: 734px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramListWrap {
    display: flex;
    align-items: flex-end;
    left: 0vw;
    top: 47.60417vw;
    transform: none;
    width: 100%;
    height: 56.25vw;
    max-height: 540px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramListWrap {
    left: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramListWrap {
    top: 457px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramListWrap::before {
    width: 8.33333vw;
    max-width: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramListWrap {
    left: 0vw;
    top: 60.93333vw;
    transform: none;
    width: 100%;
    height: 64vw;
    max-height: 480px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramListWrap {
    left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramListWrap {
    top: 457px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramListWrap::before {
    width: 10.66667vw;
    max-width: 80px;
  }
}

#indexTop .onAir .onAirProgramList {
  position: absolute;
  display: flex;
  padding: 0 0 0 40%;
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList {
    padding: 0 0 0 80%;
  }
}

#indexTop .onAir .onAirProgramList .onAirProgram {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: column;
  background-color: #fff;
  border-radius: 100%;
  text-align: center;
  overflow: hidden;
  width: 32.21083vw;
  max-width: 440px;
  height: 32.21083vw;
  max-height: 440px;
  margin-right: 4.39239vw;
  padding-top: 2.92826vw;
  padding-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    margin-right: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    padding-bottom: 20px;
  }
}

#indexTop .onAir .onAirProgramList .onAirProgram:last-of-type {
  margin-right: 32.21083vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram:last-of-type {
    margin-right: 440px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    justify-content: center;
    width: 56.25vw;
    max-width: 540px;
    height: 56.25vw;
    max-height: 540px;
    padding-top: 2.08333vw;
    padding-bottom: 1.04167vw;
    margin-right: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    padding-top: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    margin-right: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgram:last-of-type {
    margin-right: 50vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram:last-of-type {
    margin-right: 480px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    justify-content: center;
    width: 64vw;
    max-width: 480px;
    height: 64vw;
    max-height: 480px;
    padding-top: 2.66667vw;
    padding-bottom: 1.33333vw;
    margin-right: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram {
    margin-right: 60px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgram:last-of-type {
    margin-right: 64vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgram:last-of-type {
    margin-right: 480px;
  }
}

#indexTop .onAir .onAirProgramList .onAirProgramImg {
  width: 20.4978vw;
  max-width: 280px;
  height: 15.37335vw;
  max-height: 210px;
  overflow: hidden;
}

#indexTop .onAir .onAirProgramList .onAirProgramImg img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramImg {
    width: 32.91667vw;
    max-width: 316px;
    height: 24.79167vw;
    max-height: 238px;
    margin-bottom: 1.5625vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramImg {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramImg {
    width: 42.13333vw;
    max-width: 316px;
    height: 31.73333vw;
    max-height: 238px;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramImg {
    margin-bottom: 15px;
  }
}

#indexTop .onAir .onAirProgramList .onAirProgramTit {
  max-width: 88%;
  font-size: 2.2rem;
  font-size: 1.52778vw;
  font-weight: bold;
  line-height: 1.64;
  color: #333;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.29167vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    line-height: 1.29;
    margin-bottom: 1.04167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    line-height: 1.29;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTit {
    margin-bottom: 10px;
  }
}

#indexTop .onAir .onAirProgramList .onAirProgramTag {
  max-width: 88%;
  font-size: 1.5rem;
  font-size: 1.04167vw;
  color: #555;
}

@media screen and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 2vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
    font-size: 1.66667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .onAir .onAirProgramList .onAirProgramTag {
    font-size: 3.2vw;
  }
}

#indexTop .aboutUs {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #9fcfd3, #fcf9f5 50%, #e6c3d8);
  min-height: 100vh;
  overflow: hidden;
  padding-top: 9.51684vw;
  padding-bottom: 7.32064vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs {
    padding-top: 130px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs {
    min-height: auto;
    height: auto;
    padding-top: 15.10417vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs {
    padding-top: 145px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs {
    min-height: auto;
    height: auto;
    padding-top: 19.33333vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs {
    padding-top: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs {
    padding-bottom: 120px;
  }
}

#indexTop .aboutUs .wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1306px;
  width: 100%;
  padding-right: 1.46413vw;
  padding-left: 6.8082vw;
  margin: 0 auto;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .wrap {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .wrap {
    padding-left: 93px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .wrap {
    flex-flow: column;
    padding-right: 4.16667vw;
    padding-left: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .wrap {
    padding-right: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .wrap {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .wrap {
    flex-flow: column;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .wrap {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .wrap {
    padding-left: 40px;
  }
}

#indexTop .aboutUs .txtBox {
  max-width: 34.871%;
  width: 100%;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .txtBox {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .txtBox {
    max-width: 100%;
  }
}

#indexTop .aboutUs .imgBox {
  width: 49.92679vw;
  max-width: 682px;
}

#indexTop .aboutUs .imgBox img {
  width: 100%;
}

#indexTop .aboutUs .imgBox.anime .img {
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .imgBox.anime .img {
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .imgBox.anime .img {
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .imgBox {
    margin: 0 auto;
    width: 69.79167vw;
    max-width: 670px;
    margin-bottom: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .imgBox {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .imgBox {
    margin: 0 auto;
    width: 89.33333vw;
    max-width: 670px;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .imgBox {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .btnWhite01 {
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .btnWhite01 {
    margin: 0 auto;
  }
}

#indexTop .aboutUs .aboutUsTit {
  opacity: 0;
  margin-bottom: 2.34261vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTit {
    margin-bottom: 32px;
  }
}

#indexTop .aboutUs .aboutUsTit svg {
  width: 100%;
  height: auto;
}

#indexTop .aboutUs .aboutUsTit .cls-1 {
  fill: none;
  stroke: #333333;
  stroke-width: 2px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTit {
    width: 57.5vw;
    max-width: 552px;
    margin-bottom: 5.83333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTit {
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTit {
    width: 73.6vw;
    max-width: 552px;
    margin-bottom: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTit {
    margin-bottom: 56px;
  }
}

#indexTop .aboutUs .fadeAbout {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .fadeAbout {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .fadeAbout {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

#indexTop .aboutUs .aboutUsTitLead {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  color: #555;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead {
    margin-bottom: 30px;
  }
}

#indexTop .aboutUs .aboutUsTitLead::before {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 3.29429vw;
  max-width: 45px;
  height: 1px;
  margin-right: 1.0981vw;
  background-color: #555;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    margin-bottom: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTitLead::before {
    width: 4.6875vw;
    max-width: 45px;
    margin-right: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead::before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTitLead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTitLead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTitLead::before {
    margin-right: 20px;
  }
}

#indexTop .aboutUs .aboutUsTxt {
  font-size: 1.6rem;
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.96px;
  color: #333;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTxt {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    margin-bottom: 8.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutUs .aboutUsTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .aboutUs .aboutUsTxt {
    margin-bottom: 80px;
  }
}

#indexTop .recruit {
  position: relative;
  background-image: linear-gradient(to bottom, #9fcfd3, #fcf9f5 50%, #e6c3d8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  padding-top: 9.51684vw;
  padding-bottom: 7.32064vw;
  z-index: 1;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit {
    padding-top: 130px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit {
    padding-bottom: 100px;
  }
}

#indexTop .recruit.scrollDown::before {
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit {
    height: auto;
    padding-top: 15.10417vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit {
    padding-top: 145px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit {
    height: auto;
    padding-top: 19.33333vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit {
    padding-top: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit {
    padding-bottom: 120px;
  }
}

#indexTop .recruit::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .8s;
  z-index: 1;
}

#indexTop .recruit::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  background-image: url(/images/index/recruit_bg_pc@2x.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit::after {
    top: auto;
    transform: none;
    background-image: url(/images/index/recruit_bg_sp@2x.png);
    height: 52.08333vw;
    max-height: 500px;
    bottom: 33.4375vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit::after {
    bottom: 321px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit::after {
    top: auto;
    transform: none;
    background-image: url(/images/index/recruit_bg_sp@2x.png);
    height: 66.66667vw;
    max-height: 500px;
    bottom: 42.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit::after {
    bottom: 321px;
  }
}

#indexTop .recruit .wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1306px;
  width: 100%;
  padding-left: 1.46413vw;
  padding-right: 6.8082vw;
  margin: 0 auto;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .wrap {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .wrap {
    padding-right: 93px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .wrap {
    flex-flow: column;
    padding-right: 4.16667vw;
    padding-left: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .wrap {
    padding-right: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .wrap {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .wrap {
    flex-flow: column;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .wrap {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .wrap {
    padding-left: 40px;
  }
}

#indexTop .recruit .txtBox {
  max-width: 39.481%;
  width: 100%;
  order: 2;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .txtBox {
    max-width: 100%;
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .txtBox {
    max-width: 100%;
    order: 1;
  }
}

#indexTop .recruit .imgBox {
  position: relative;
  width: 50.51245vw;
  max-width: 690px;
  height: 32.43045vw;
  max-height: 443px;
  order: 1;
}

#indexTop .recruit .imgBox img {
  width: 100%;
}

#indexTop .recruit .imgBox.anime .img:nth-of-type(2) {
  animation: spaceAnime01 3s linear infinite alternate;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .imgBox.anime .img:nth-of-type(2) {
    animation: spaceAnimeSp01 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .imgBox.anime .img:nth-of-type(2) {
    animation: spaceAnimeSp01 3s linear infinite alternate;
  }
}

#indexTop .recruit .imgBox .img:nth-of-type(1) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 45.02196vw;
  max-width: 615px;
}

#indexTop .recruit .imgBox .img:nth-of-type(2) {
  position: absolute;
  left: 0;
  top: 0;
  width: 18.00878vw;
  max-width: 246px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .imgBox {
    width: 69.79167vw;
    max-width: 670px;
    height: 50.41667vw;
    max-height: 484px;
    margin: 0 auto;
    margin-bottom: 10.41667vw;
    order: 2;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .imgBox {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .imgBox .img:nth-of-type(1) {
    width: 64.0625vw;
    max-width: 615px;
  }
  #indexTop .recruit .imgBox .img:nth-of-type(2) {
    width: 25.625vw;
    max-width: 246px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .imgBox {
    width: 89.33333vw;
    max-width: 670px;
    height: 64.53333vw;
    max-height: 484px;
    margin: 0 auto;
    margin-bottom: 13.33333vw;
    order: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .imgBox {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .imgBox .img:nth-of-type(1) {
    width: 82vw;
    max-width: 615px;
  }
  #indexTop .recruit .imgBox .img:nth-of-type(2) {
    width: 32.8vw;
    max-width: 246px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .btnWhite01 {
    order: 3;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .btnWhite01 {
    order: 3;
    margin: 0 auto;
  }
}

#indexTop .recruit .recruitTit {
  opacity: 0;
  margin-bottom: 2.34261vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .recruitTit {
    margin-bottom: 32px;
  }
}

#indexTop .recruit .recruitTit svg {
  width: 100%;
  height: auto;
}

#indexTop .recruit .recruitTit .cls-1 {
  fill: none;
  stroke: #333;
  stroke-width: 2px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTit {
    width: 48.54167vw;
    max-width: 466px;
    margin-bottom: 5.83333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTit {
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .recruitTit {
    width: 62.13333vw;
    max-width: 466px;
    margin-bottom: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTit {
    margin-bottom: 56px;
  }
}

#indexTop .recruit .fadeRecruit {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .fadeRecruit {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .fadeRecruit {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

#indexTop .recruit .recruitTitLead {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  color: #555;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead {
    margin-bottom: 30px;
  }
}

#indexTop .recruit .recruitTitLead::before {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 3.29429vw;
  max-width: 45px;
  height: 1px;
  margin-right: 1.0981vw;
  background-color: #555;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    margin-bottom: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTitLead::before {
    width: 4.6875vw;
    max-width: 45px;
    margin-right: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead::before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .recruit .recruitTitLead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .recruitTitLead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTitLead::before {
    margin-right: 20px;
  }
}

#indexTop .recruit .recruitTxt {
  max-width: 88.323%;
  width: 100%;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.96px;
  color: #333;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .recruitTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTxt {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .recruitTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .recruit .recruitTxt {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTxt {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    margin-bottom: 8.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTxt {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTxt {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #indexTop .recruit .recruitTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruit .recruitTxt {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #indexTop .recruit .recruitTxt {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .recruit .recruitTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .recruit .recruitTxt {
    margin-bottom: 80px;
  }
}

/* ==============================================
works トップページ
============================================== */
#worksTop {
  overflow-x: hidden;
}

#worksTop .spaceArea {
  background-image: url(/images/works/bg_outerspace.jpg);
  background-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .spaceArea {
    background-image: url(/images/works/bg_outerspace_sp.jpg);
  }
}

@media screen and (max-width: 767px) {
  #worksTop .spaceArea {
    background-image: url(/images/works/bg_outerspace_sp.jpg);
  }
}

#worksTop .mainview {
  position: relative;
  z-index: 1;
  padding-top: 12.88433vw;
  padding-bottom: 9.95608vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview {
    padding-top: 176px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview {
    padding-bottom: 136px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview {
    padding-top: 25.52083vw;
    padding-bottom: 2.60417vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .mainview {
    padding-top: 245px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .mainview {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1366px;
    width: 100%;
    height: 80vw;
    max-height: 768px;
    background-image: url(/images/works/bg_star_sp.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview {
    padding-top: 32.66667vw;
    padding-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .mainview {
    padding-top: 245px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .mainview {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1366px;
    width: 100%;
    height: 102.4vw;
    max-height: 768px;
    background-image: url(/images/works/bg_star_sp.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    z-index: -1;
  }
}

#worksTop .mainview .container {
  position: relative;
}

#worksTop .mainview .mainviewTit {
  opacity: 0;
  visibility: hidden;
  -webkit-text-stroke: 2px #fff;
  font-family: "Teko", sans-serif;
  font-size: 12rem;
  font-size: 8.33333vw;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: 3.6px;
  color: rgba(224, 236, 244, 0);
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview .mainviewTit {
    font-size: 12rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit {
    font-size: 12.5vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .mainviewTit {
    font-size: 16vw;
  }
}

#worksTop .mainview .mainviewTit.isShow {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s, visibility .3s;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit {
    letter-spacing: 1.8px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .mainviewTit {
    letter-spacing: 1.8px;
  }
}

#worksTop .mainview .mainviewTit .img {
  width: 26.42753vw;
  max-width: 361px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit .img {
    width: 37.60417vw;
    max-width: 361px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .mainviewTit .img {
    width: 48.13333vw;
    max-width: 361px;
  }
}

#worksTop .mainview .mainviewTit svg {
  max-width: 100%;
  width: 100%;
  height: auto;
  fill: transparent;
  stroke-width: 2px;
  stroke: #fff;
}

#worksTop .mainview .mainviewTit .lead {
  display: flex;
  align-items: center;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: normal;
  color: #fff;
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 2.4vw;
  }
}

#worksTop .mainview .mainviewTit .lead::before {
  content: "";
  display: block;
  width: 3.29429vw;
  max-width: 45px;
  height: 1px;
  background-color: #fff;
  margin-right: 1.0981vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit .lead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .mainview .mainviewTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .mainviewTit .lead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.60417vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .mainview .imgBox {
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .mainview .imgBox {
    margin-top: 25px;
  }
}

#worksTop .mainview .img01 {
  position: absolute;
  width: 18.66764vw;
  max-width: 255px;
  top: -1.83016vw;
  left: 37.4817vw;
  animation: spaceAnime01 3.5s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview .img01 {
    top: -25px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview .img01 {
    left: 512px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .img01 {
    width: 33.75vw;
    max-width: 324px;
    margin-top: 4.27083vw;
    margin-right: 2.60417vw;
    position: static;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .mainview .img01 {
    margin-top: 41px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .mainview .img01 {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .img01 {
    width: 43.2vw;
    max-width: 324px;
    margin-top: 5.46667vw;
    margin-right: 3.33333vw;
    position: static;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .mainview .img01 {
    margin-top: 41px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .mainview .img01 {
    margin-right: 25px;
  }
}

#worksTop .mainview .img02 {
  position: absolute;
  width: 18.52123vw;
  max-width: 253px;
  top: -4.39239vw;
  left: 57.61347vw;
  animation: spaceAnime03 3.5s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview .img02 {
    top: -60px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .mainview .img02 {
    left: 787px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .mainview .img02 {
    width: 33.4375vw;
    max-width: 321px;
    position: static;
    animation: spaceAnimeSp03 3.5s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .mainview .img02 {
    width: 42.8vw;
    max-width: 321px;
    position: static;
    animation: spaceAnimeSp03 3.5s linear infinite alternate;
  }
}

#worksTop .onAirArea {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 4.39239vw;
  padding-bottom: 7.32064vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea {
    padding-top: 21.04167vw;
    padding-bottom: 16.66667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea {
    padding-top: 202px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea {
    padding-top: 26.93333vw;
    padding-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea {
    padding-top: 202px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea {
    padding-bottom: 160px;
  }
}

#worksTop .onAirArea::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1366px;
  width: 100%;
  height: 56.22255vw;
  max-height: 768px;
  background-image: url(/images/works/bg_star_reverse.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  z-index: -1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea::before {
    height: 80vw;
    max-height: 768px;
    background-image: url(/images/works/bg_star_reverse_sp.png);
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea::before {
    height: 102.4vw;
    max-height: 768px;
    background-image: url(/images/works/bg_star_reverse_sp.png);
  }
}

#worksTop .onAirArea::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 3.14788vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1366px;
  width: 100%;
  height: 56.22255vw;
  max-height: 768px;
  background-image: url(/images/works/bg_star.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  z-index: -1;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea::after {
    bottom: 43px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea::after {
    position: absolute;
    content: "";
    display: block;
    background-image: url(/images/works/onair_camera@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 14.47917vw;
    max-width: 139px;
    height: 10.41667vw;
    max-height: 100px;
    bottom: 10.83333vw;
    left: 51.14583vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea::after {
    bottom: 104px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea::after {
    left: 491px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea::after {
    position: absolute;
    content: "";
    display: block;
    background-image: url(/images/works/onair_camera@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 18.53333vw;
    max-width: 139px;
    height: 13.33333vw;
    max-height: 100px;
    bottom: 13.86667vw;
    left: 65.46667vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea::after {
    bottom: 104px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea::after {
    left: 491px;
  }
}

#worksTop .onAirArea .onAirAreaTit {
  position: relative;
  -webkit-text-stroke: 1px #fff;
  font-family: "Teko", sans-serif;
  font-size: 8rem;
  font-size: 5.55556vw;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 2.4px;
  text-align: center;
  color: rgba(224, 236, 244, 0);
  padding-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 8.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    padding-bottom: 80px;
  }
}

#worksTop .onAirArea .onAirAreaTit .lead {
  display: block;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #fff;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 2.4vw;
  }
}

#worksTop .onAirArea .onAirAreaTit::before {
  position: absolute;
  content: "";
  display: block;
  background-image: url(/images/works/onair_ufo@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 10.54173vw;
  max-width: 144px;
  height: 8.19912vw;
  max-height: 112px;
  left: 12.29868vw;
  bottom: 1.75695vw;
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    left: 168px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    bottom: 24px;
  }
}

#worksTop .onAirArea .onAirAreaTit::after {
  position: absolute;
  content: "";
  display: block;
  background-image: url(/images/works/onair_heedphone@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 5.49048vw;
  max-width: 75px;
  height: 7.10102vw;
  max-height: 97px;
  right: 16.98389vw;
  bottom: 0.58565vw;
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    right: 232px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    bottom: 8px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 10.4rem;
    font-size: 7.22222vw;
    line-height: 1;
    letter-spacing: 1.56px;
    padding-bottom: 16.875vw;
    margin-bottom: 50vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 10.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 10.83333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 13.86667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    padding-bottom: 162px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    margin-bottom: 480px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    width: 13.85417vw;
    max-width: 133px;
    height: 10.83333vw;
    max-height: 104px;
    left: 0vw;
    bottom: 10.41667vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    left: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    bottom: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    width: 10.41667vw;
    max-width: 100px;
    height: 13.33333vw;
    max-height: 128px;
    bottom: auto;
    right: 3.125vw;
    top: -10.41667vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    right: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    top: -100px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 10.4rem;
    font-size: 7.22222vw;
    line-height: 1;
    letter-spacing: 1.56px;
    padding-bottom: 21.6vw;
    margin-bottom: 64vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 10.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 10.83333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit {
    font-size: 13.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    padding-bottom: 162px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit {
    margin-bottom: 480px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    width: 17.73333vw;
    max-width: 133px;
    height: 13.86667vw;
    max-height: 104px;
    left: 0vw;
    bottom: 13.33333vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::before {
    bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    width: 13.33333vw;
    max-width: 100px;
    height: 17.06667vw;
    max-height: 128px;
    bottom: auto;
    right: 4vw;
    top: -13.33333vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirAreaTit::after {
    top: -100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramListWrap {
    position: absolute;
    bottom: 16.66667vw;
    max-width: none;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    left: 0vw;
    width: 100%;
    height: 56.25vw;
    max-height: 540px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramListWrap {
    bottom: 160px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramListWrap {
    left: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramListWrap::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 8.33333vw;
    max-width: 80px;
    height: 100%;
    background-image: linear-gradient(90deg, #00232e 0%, rgba(0, 35, 46, 0.882791) 16%, rgba(0, 35, 46, 0) 100%);
    z-index: 1;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramListWrap {
    position: absolute;
    bottom: 21.33333vw;
    max-width: none;
    overflow: hidden;
    left: 0vw;
    width: 100%;
    height: 64vw;
    max-height: 480px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramListWrap {
    bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramListWrap {
    left: 0px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramListWrap::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 10.66667vw;
    max-width: 80px;
    height: 100%;
    background-image: linear-gradient(90deg, #00232e 0%, rgba(0, 35, 46, 0.882791) 16%, rgba(0, 35, 46, 0) 100%);
    z-index: 1;
  }
}

#worksTop .onAirArea .onAirProgramList {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 80.52709vw;
  max-width: 1100px;
}

#worksTop .onAirArea .onAirProgramList::after {
  position: absolute;
  content: "";
  display: block;
  background-image: url(/images/works/onair_camera@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 8.85798vw;
  max-width: 121px;
  height: 6.36896vw;
  max-height: 87px;
  bottom: 24.37775vw;
  left: -4.39239vw;
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList::after {
    bottom: 333px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList::after {
    left: -60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList {
    position: absolute;
    max-width: none;
    width: auto;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0 0 0 40%;
  }
  #worksTop .onAirArea .onAirProgramList::after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList {
    position: absolute;
    max-width: none;
    width: auto;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0 0 0 40%;
  }
  #worksTop .onAirArea .onAirProgramList::after {
    display: none;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgram {
  position: relative;
  width: 100%;
  max-width: 30.91%;
  z-index: 1;
  background-color: #fff;
  border-radius: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  width: 24.89019vw;
  max-width: 340px;
  height: 24.89019vw;
  max-height: 340px;
  margin-right: 2.78184vw;
  margin-bottom: 1.68375vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    margin-right: 38px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    margin-bottom: 23px;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n+1) {
  margin-top: 3.4407vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n+1) {
    margin-top: 47px;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
  margin-right: 0vw;
  margin-top: 3.4407vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-top: 47px;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgram a {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  border-radius: 100%;
  padding-top: 2.92826vw;
  padding-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    opacity: 1;
    transform: none;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 100%;
    width: 56.25vw;
    max-width: 540px;
    height: 56.25vw;
    max-height: 540px;
    margin-right: 6.25vw;
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    margin-right: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram::after {
    display: none;
  }
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n+1) {
    margin-top: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n+1) {
    margin-top: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-right: 6.25vw;
    margin-top: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-right: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-top: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    justify-content: center;
    padding-top: 2.08333vw;
    padding-bottom: 1.04167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    padding-top: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    opacity: 1;
    transform: none;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 100%;
    width: 64vw;
    max-width: 480px;
    height: 64vw;
    max-height: 480px;
    margin-right: 8vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    margin-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram::after {
    display: none;
  }
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n+1) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n+1) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-right: 8vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram:nth-of-type(3n) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    justify-content: center;
    padding-top: 2.66667vw;
    padding-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgram a {
    padding-bottom: 10px;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgramImg {
  width: 16.10542vw;
  max-width: 220px;
  height: 12.15227vw;
  max-height: 166px;
  margin-bottom: 1.31772vw;
  overflow: hidden;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramImg {
    margin-bottom: 18px;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgramImg img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramImg {
    width: 32.91667vw;
    max-width: 316px;
    height: 24.79167vw;
    max-height: 238px;
    margin-bottom: 1.5625vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramImg {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramImg {
    width: 42.13333vw;
    max-width: 316px;
    height: 31.73333vw;
    max-height: 238px;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramImg {
    margin-bottom: 15px;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgramTit {
  max-width: 88%;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  font-weight: bold;
  line-height: 1.64;
  color: #333;
  margin-bottom: 1.31772vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    margin-bottom: 18px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    line-height: 1.29;
    margin-bottom: 1.04167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    line-height: 1.29;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTit {
    margin-bottom: 10px;
  }
}

#worksTop .onAirArea .onAirProgramList .onAirProgramTag {
  max-width: 88%;
  font-size: 1.5rem;
  font-size: 1.04167vw;
  color: #555;
}

@media screen and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 2vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
    font-size: 1.66667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .onAirArea .onAirProgramList .onAirProgramTag {
    font-size: 3.2vw;
  }
}

#worksTop .archivesArea {
  padding-top: 7.32064vw;
  padding-bottom: 8.78477vw;
  background-image: linear-gradient(to bottom, rgba(159, 207, 211, 0.5), rgba(252, 249, 245, 0.5) 50%, rgba(230, 195, 216, 0.5));
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea {
    padding-top: 10.41667vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea {
    padding-top: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea {
    padding-top: 13.33333vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea {
    padding-bottom: 120px;
  }
}

#worksTop .archivesArea .archivesAreaTit {
  -webkit-text-stroke: 1px #333;
  font-family: "Teko", sans-serif;
  font-size: 8rem;
  font-size: 5.55556vw;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 2.4px;
  text-align: center;
  color: rgba(224, 236, 244, 0);
  margin-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 8.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit {
    margin-bottom: 80px;
  }
}

#worksTop .archivesArea .archivesAreaTit .lead {
  display: block;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #555;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1.2;
    letter-spacing: 1.5px;
    margin-bottom: 13.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 10rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit {
    margin-bottom: 126px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1.2;
    letter-spacing: 1.5px;
    margin-bottom: 16.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 10rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 10.41667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit {
    font-size: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit {
    margin-bottom: 126px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaTit .lead {
    font-size: 3.73333vw;
  }
}

#worksTop .archivesArea .archivesAreaList {
  position: relative;
  margin-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList {
    margin-bottom: 80px;
  }
}

#worksTop .archivesArea .archivesAreaList:last-of-type {
  margin-bottom: 0;
}

#worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
  position: absolute;
  content: "";
  display: block;
  background-image: url(/images/works/achieve_taco@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 11.49341vw;
  max-width: 157px;
  height: 8.19912vw;
  max-height: 112px;
  left: 6.29575vw;
  top: -5.85652vw;
  animation: spaceAnime02 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    left: 86px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    top: -80px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList {
    margin-bottom: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    width: 16.35417vw;
    max-width: 157px;
    height: 11.66667vw;
    max-height: 112px;
    left: 3.125vw;
    top: -8.95833vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    left: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    top: -86px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    width: 20.93333vw;
    max-width: 157px;
    height: 14.93333vw;
    max-height: 112px;
    left: 4vw;
    top: -11.46667vw;
    animation: spaceAnimeSp02 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList:nth-of-type(1)::after {
    top: -86px;
  }
}

#worksTop .archivesArea .archivesAreaList dd {
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList dd {
    margin-bottom: 40px;
  }
}

#worksTop .archivesArea .archivesAreaList dd:last-of-type {
  margin-bottom: 0;
}

#worksTop .archivesArea .archivesAreaList dd ul {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList dd {
    margin-bottom: 8.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList dd {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList dd ul {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList dd {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList dd {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList dd ul {
    display: block;
  }
}

#worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
  font-size: 2.8rem;
  font-size: 1.94444vw;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #333;
  border-radius: 10px;
  background-color: #fff;
  padding-top: 1.0981vw;
  padding-bottom: 1.0981vw;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    padding-top: 15px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 3.6rem;
    font-size: 2.5vw;
    background-color: #f7f7f7;
    padding-top: 2.08333vw;
    padding-bottom: 2.08333vw;
    margin-bottom: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 3.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 3.75vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    padding-top: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 3.6rem;
    font-size: 2.5vw;
    background-color: #f7f7f7;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 3.75vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .archivesAreaListTit {
    margin-bottom: 60px;
  }
}

#worksTop .archivesArea .archivesAreaList .programCategory {
  font-size: 1.6rem;
  font-size: 1.11111vw;
  font-weight: bold;
  line-height: 1.5;
  color: #333;
  border-bottom: solid 1px #ccc;
  padding-right: 1.46413vw;
  padding-left: 1.46413vw;
  padding-bottom: 1.0981vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    padding-right: 0vw;
    padding-left: 0vw;
    padding-bottom: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-right: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-left: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    padding-right: 0vw;
    padding-left: 0vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programCategory {
    padding-bottom: 30px;
  }
}

#worksTop .archivesArea .archivesAreaList .programName {
  position: relative;
  max-width: 50%;
  width: 100%;
  font-size: 1.4rem;
  font-size: 0.97222vw;
  line-height: 1.5;
  color: #333;
  margin-top: 1.02489vw;
  padding-right: 1.46413vw;
  padding-left: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    margin-top: 14px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    padding-left: 40px;
  }
}

#worksTop .archivesArea .archivesAreaList .programName::before {
  content: "";
  position: absolute;
  top: 0.58565vw;
  left: 1.68375vw;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background-color: #a1d5d8;
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    top: 8px;
  }
}

@media screen and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    left: 23px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    max-width: 100%;
    font-size: 2.8rem;
    font-size: 1.94444vw;
    padding-right: 0vw;
    padding-left: 3.125vw;
    margin-top: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    padding-right: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    padding-left: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    margin-top: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    top: 1.66667vw;
    left: 0vw;
    width: 1.25vw;
    max-width: 12px;
    height: 1.25vw;
    max-height: 12px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    top: 16px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    left: 0px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    max-width: 100%;
    font-size: 2.8rem;
    font-size: 1.94444vw;
    padding-right: 0vw;
    padding-left: 4vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    top: 2.13333vw;
    left: 0vw;
    width: 1.6vw;
    max-width: 12px;
    height: 1.6vw;
    max-height: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #worksTop .archivesArea .archivesAreaList .programName::before {
    left: 0px;
  }
}

#worksTop .fadeInworks {
  opacity: 0;
}

/* ==============================================
aboutUs トップページ
============================================== */
#aboutUsTop {
  overflow-x: hidden;
}

#aboutUsTop .spaceArea {
  position: relative;
  background-image: url(/images/about_us/bg_outerspace.jpg);
  background-size: contain;
  background-position: top center;
  z-index: 1;
}

#aboutUsTop .spaceArea::before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1366px;
  width: 100%;
  height: 56.22255vw;
  max-height: 768px;
  background-image: url(/images/about_us/bg_star_reverse.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  z-index: -1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .spaceArea {
    background-image: url(/images/about_us/bg_outerspace_sp.jpg);
  }
  #aboutUsTop .spaceArea::before {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .spaceArea {
    background-image: url(/images/about_us/bg_outerspace_sp.jpg);
  }
  #aboutUsTop .spaceArea::before {
    display: none;
  }
}

#aboutUsTop .mainview {
  position: relative;
  z-index: 1;
  padding-top: 12.88433vw;
  padding-bottom: 8.56515vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview {
    padding-top: 176px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview {
    padding-bottom: 117px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview {
    padding-top: 25.52083vw;
    padding-bottom: 2.60417vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainview {
    padding-top: 245px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainview {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview {
    padding-top: 32.66667vw;
    padding-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainview {
    padding-top: 245px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainview {
    padding-bottom: 25px;
  }
}

#aboutUsTop .mainview .container {
  position: relative;
}

#aboutUsTop .mainview .mainviewTit {
  opacity: 0;
  visibility: hidden;
  -webkit-text-stroke: 2px #fff;
  font-family: "Teko", sans-serif;
  font-size: 12rem;
  font-size: 8.33333vw;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: 3.6px;
  color: rgba(224, 236, 244, 0);
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview .mainviewTit {
    font-size: 12rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit {
    font-size: 12.5vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit {
    font-size: 16vw;
  }
}

#aboutUsTop .mainview .mainviewTit.isShow {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s, visibility .3s;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit {
    letter-spacing: 1.8px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit {
    letter-spacing: 1.8px;
  }
}

#aboutUsTop .mainview .mainviewTit .img {
  width: 34.69985vw;
  max-width: 474px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit .img {
    width: 49.375vw;
    max-width: 474px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit .img {
    width: 63.2vw;
    max-width: 474px;
  }
}

#aboutUsTop .mainview .mainviewTit svg {
  max-width: 100%;
  width: 100%;
  height: auto;
  fill: transparent;
  stroke-width: 2px;
  stroke: #e0ecf4;
}

#aboutUsTop .mainview .mainviewTit .lead {
  display: flex;
  align-items: center;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: normal;
  color: #fff;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 2.4vw;
  }
}

#aboutUsTop .mainview .mainviewTit .lead::before {
  content: "";
  display: block;
  width: 3.29429vw;
  max-width: 45px;
  height: 1px;
  background-color: #fff;
  margin-right: 1.0981vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit .lead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .mainviewTit .lead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.60417vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainview .imgBox {
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .imgBox {
    display: flex;
    align-items: center;
    margin-top: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainview .imgBox {
    margin-top: 25px;
  }
}

#aboutUsTop .mainview .img01 {
  position: absolute;
  width: 37.99414vw;
  max-width: 519px;
  top: -5.49048vw;
  left: 31.11274vw;
  animation: spaceAnime01 3.5s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview .img01 {
    top: -75px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview .img01 {
    left: 425px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .img01 {
    width: 64.0625vw;
    max-width: 615px;
    position: static;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .img01 {
    width: 82vw;
    max-width: 615px;
    position: static;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

#aboutUsTop .mainview .img02 {
  position: absolute;
  width: 14.05564vw;
  max-width: 192px;
  top: 6.95461vw;
  left: 62.07906vw;
  animation: spaceAnime03 3.5s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview .img02 {
    top: 95px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainview .img02 {
    left: 848px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainview .img02 {
    width: 19.89583vw;
    max-width: 191px;
    top: auto;
    left: auto;
    bottom: 2.08333vw;
    right: 4.16667vw;
    animation: spaceAnimeSp03 3.5s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainview .img02 {
    bottom: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainview .img02 {
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainview .img02 {
    width: 25.46667vw;
    max-width: 191px;
    top: auto;
    left: auto;
    bottom: 2.66667vw;
    right: 5.33333vw;
    animation: spaceAnimeSp03 3.5s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainview .img02 {
    bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainview .img02 {
    right: 40px;
  }
}

#aboutUsTop .mainviewTxtBox {
  padding-top: 4.39239vw;
  padding-bottom: 11.71303vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox {
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainviewTxtBox {
    padding-top: 5.20833vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox {
    padding-top: 50px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainviewTxtBox {
    padding-top: 6.66667vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox {
    padding-bottom: 120px;
  }
}

#aboutUsTop .mainviewTxtBox .mainviewTxt {
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.96px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    text-align: left;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .mainviewTxtBox .mainviewTxt {
    font-size: 4.26667vw;
  }
}

#aboutUsTop .conceptArea {
  position: relative;
  z-index: 1;
  padding-top: 5.85652vw;
  padding-bottom: 11.71303vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea {
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea {
    padding-top: 9.89583vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea {
    padding-top: 95px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea {
    padding-top: 12.66667vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea {
    padding-top: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea {
    padding-bottom: 120px;
  }
}

#aboutUsTop .conceptArea .conceptAreaTit {
  position: relative;
  -webkit-text-stroke: 1px #fff;
  font-family: "Teko", sans-serif;
  font-size: 8rem;
  font-size: 5.55556vw;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 2.4px;
  text-align: center;
  color: rgba(224, 236, 244, 0);
  padding-bottom: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 8.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    padding-bottom: 60px;
  }
}

#aboutUsTop .conceptArea .conceptAreaTit .lead {
  display: block;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #fff;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 10.4rem;
    font-size: 7.22222vw;
    line-height: 1;
    letter-spacing: 1.56px;
    padding-bottom: 7.8125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 10.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 10.83333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 13.86667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    padding-bottom: 75px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 10.4rem;
    font-size: 7.22222vw;
    line-height: 1;
    letter-spacing: 1.56px;
    padding-bottom: 10vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 10.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 10.83333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    font-size: 13.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit {
    padding-bottom: 75px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTit .lead {
    font-size: 3.73333vw;
  }
}

#aboutUsTop .conceptArea .conceptAreaLead {
  font-size: 5.6rem;
  font-size: 3.88889vw;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.83333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 7.46667vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.6rem;
    font-size: 3.88889vw;
    margin-bottom: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.83333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 7.46667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.6rem;
    font-size: 3.88889vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 5.83333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    font-size: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaLead {
    margin-bottom: 40px;
  }
}

#aboutUsTop .conceptArea .conceptAreaTxt {
  max-width: 620px;
  font-size: 2.2rem;
  font-size: 1.52778vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.96px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 2.29167vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 3.4rem;
    font-size: 2.36111vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 3.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 3.54167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 3.4rem;
    font-size: 2.36111vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 3.54167vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .conceptArea .conceptAreaTxt {
    font-size: 4.53333vw;
  }
}

#aboutUsTop .companyProfile {
  padding-top: 7.32064vw;
  padding-bottom: 8.78477vw;
  background-image: linear-gradient(to bottom, rgba(159, 207, 211, 0.5), rgba(252, 249, 245, 0.5) 50%, rgba(230, 195, 216, 0.5));
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile {
    padding-top: 12.5vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile {
    padding-top: 120px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile {
    padding-top: 16vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile {
    padding-bottom: 120px;
  }
}

#aboutUsTop .companyProfile .companyProfileTit {
  -webkit-text-stroke: 1px #333;
  font-family: "Teko", sans-serif;
  font-size: 8rem;
  font-size: 5.55556vw;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 2.4px;
  text-align: center;
  color: rgba(224, 236, 244, 0);
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 8.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    margin-bottom: 40px;
  }
}

#aboutUsTop .companyProfile .companyProfileTit .lead {
  display: block;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #555;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1;
    letter-spacing: 1.5px;
    margin-bottom: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 10rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
    margin-top: 1.5625vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1;
    letter-spacing: 1.5px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 10rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 10.41667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    font-size: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
    margin-top: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .companyProfileTit .lead {
    margin-top: 15px;
  }
}

#aboutUsTop .companyProfile .dataList {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  margin: 0 auto;
  padding-top: 2.92826vw;
  padding-right: 1.46413vw;
  padding-left: 1.46413vw;
  margin-bottom: 7.32064vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList {
    padding-top: 4.16667vw;
    padding-right: 0vw;
    padding-left: 0vw;
    margin-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-top: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-right: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-left: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList {
    padding-top: 5.33333vw;
    padding-right: 0vw;
    padding-left: 0vw;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList {
    margin-bottom: 120px;
  }
}

#aboutUsTop .companyProfile .dataList .dataListTit {
  max-width: 26.316%;
  width: 100%;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  line-height: 2;
  letter-spacing: 0.96px;
  color: #333;
  margin-right: 1.46413vw;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.48px;
    margin-bottom: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListTit.access {
    max-width: 100%;
    margin-bottom: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit.access {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.48px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListTit.access {
    max-width: 100%;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListTit.access {
    margin-bottom: 30px;
  }
}

#aboutUsTop .companyProfile .dataList .dataListCon {
  max-width: 71.053%;
  width: 100%;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  line-height: 2;
  letter-spacing: 0.96px;
  color: #333;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    margin-bottom: 40px;
  }
}

#aboutUsTop .companyProfile .dataList .dataListCon span {
  display: block;
  font-size: 1.4rem;
  font-size: 0.97222vw;
  line-height: 1.5;
  letter-spacing: 0.84px;
  margin-bottom: 0.36603vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    margin-bottom: 5px;
  }
}

#aboutUsTop .companyProfile .dataList .dataListCon span:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.48px;
    margin-bottom: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 0.42px;
    margin-bottom: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon.access {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.48px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 0.42px;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList .dataListCon span {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList .dataListCon.access {
    max-width: 100%;
  }
}

#aboutUsTop .companyProfile .dataList iframe {
  max-width: 100%;
  width: 100%;
  height: 21.96193vw;
  max-height: 300px;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList iframe {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .dataList iframe {
    height: 38.75vw;
    max-height: 372px;
    margin-bottom: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList iframe {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .dataList iframe {
    height: 49.6vw;
    max-height: 372px;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .dataList iframe {
    margin-bottom: 20px;
  }
}

#aboutUsTop .companyProfile .clientList {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  margin: 0 auto;
  padding-top: 2.92826vw;
  padding-bottom: 1.0981vw;
  padding-right: 1.46413vw;
  padding-left: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-left: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .clientList {
    display: block;
    padding-top: 4.16667vw;
    padding-bottom: 1.04167vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-top: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-right: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .clientList {
    display: block;
    padding-top: 5.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList {
    padding-left: 0px;
  }
}

#aboutUsTop .companyProfile .clientList .list {
  max-width: 50%;
  width: 100%;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.96px;
  color: #333;
  padding-right: 0.73206vw;
  margin-bottom: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .clientList .list {
    max-width: 100%;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    padding-right: 0vw;
    margin-bottom: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    padding-right: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #aboutUsTop .companyProfile .clientList .list {
    max-width: 100%;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    padding-right: 0vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutUsTop .companyProfile .clientList .list {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutUsTop .companyProfile .clientList .list {
    margin-bottom: 30px;
  }
}

#aboutUsTop .fadeInAbout {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity .8s .6s, transform .5s .5s;
  backface-visibility: hidden;
}

#aboutUsTop .fadeInAbout.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* ==============================================
aboutUs トップページ
============================================== */
#recruitTop {
  overflow-x: hidden;
}

#recruitTop .mainviewSeen {
  position: fixed;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: #00232E;
  transition: opacity .8s, visibility .8s;
  z-index: 999;
}

@media screen and (min-width: 961px) and (max-width: 1024px) {
  #recruitTop .mainviewSeen {
    max-height: 90vh;
  }
}

#recruitTop .mainviewSeen.none {
  display: none;
}

#recruitTop .mainviewSeen.hidden {
  opacity: 0;
  visibility: hidden;
}

#recruitTop .mainviewSeen .scroll {
  opacity: 0;
  position: absolute;
  bottom: 2.56223vw;
  left: 50%;
  transform: translateX(-50%);
  width: 3.3675vw;
  max-width: 46px;
  height: 3.3675vw;
  max-height: 46px;
  border: 1px solid #7594cf;
  font-family: "Teko", sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  letter-spacing: 0.72px;
  text-align: center;
  color: #7594cf;
  background-color: #7594cf;
  border-radius: 100%;
  z-index: 9;
  mix-blend-mode: revert;
  transition: opacity .8s;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .scroll {
    bottom: 35px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 2.4vw;
  }
}

#recruitTop .mainviewSeen .scroll.isShow {
  opacity: 1;
}

#recruitTop .mainviewSeen .scroll.isGradation {
  mix-blend-mode: multiply;
}

#recruitTop .mainviewSeen .scroll span {
  display: block;
  margin-top: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .scroll span {
    margin-top: 50px;
  }
}

#recruitTop .mainviewSeen .scroll::after {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  content: '';
  width: 1.1713vw;
  max-width: 16px;
  height: 1.1713vw;
  max-height: 16px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  box-sizing: border-box;
}

#recruitTop .mainviewSeen .scroll::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(117, 148, 207, 0.4);
  border-radius: 100%;
  opacity: 0;
  animation: sdb03 1.5s infinite;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .scroll {
    bottom: 14.0625vw;
    width: 6.45833vw;
    max-width: 62px;
    height: 6.45833vw;
    max-height: 62px;
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.35px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .scroll {
    bottom: 135px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 3rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .scroll span {
    display: none;
  }
  #recruitTop .mainviewSeen .scroll::after {
    width: 2.29167vw;
    max-width: 22px;
    height: 2.29167vw;
    max-height: 22px;
  }
  #recruitTop .mainviewSeen .scroll::before {
    animation: sdbSp03 1.5s infinite;
    width: 6.04167vw;
    max-width: 58px;
    height: 6.04167vw;
    max-height: 58px;
    background-color: inherit;
    box-shadow: 0 0 0 0 rgba(117, 148, 207, 0.8);
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .scroll {
    bottom: 22vw;
    width: 8.26667vw;
    max-width: 62px;
    height: 8.26667vw;
    max-height: 62px;
    font-size: 3rem;
    font-size: 2.08333vw;
    letter-spacing: 0.35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .scroll {
    bottom: 165px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .mainviewSeen .scroll {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .scroll span {
    display: none;
  }
  #recruitTop .mainviewSeen .scroll::after {
    width: 2.93333vw;
    max-width: 22px;
    height: 2.93333vw;
    max-height: 22px;
  }
  #recruitTop .mainviewSeen .scroll::before {
    animation: sdbSp03 3s infinite;
    width: 7.73333vw;
    max-width: 58px;
    height: 7.73333vw;
    max-height: 58px;
  }
}

#recruitTop .mainviewSeen .commonSeen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: opacity .8s;
}

#recruitTop .mainviewSeen .commonSeen.hide {
  opacity: 0;
}

#recruitTop .mainviewSeen .commonSeen.isShow::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

#recruitTop .mainviewSeen .commonSeen::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  width: 150vmax;
  height: 150vmax;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #9fcfd3, #fcf9f5 49%, #e6c3d8);
  transform: translate(-50%, -50%) scale(0, 0);
  transition: transform 1s,opacity .8s;
  backface-visibility: hidden;
  perspective: 1000;
}

#recruitTop .mainviewSeen .commonSeen .wrap {
  position: absolute;
  max-width: 1366px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 56.22255vw;
  max-height: 768px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .commonSeen .wrap {
    max-height: 100vh;
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .commonSeen .wrap {
    max-height: 100vh;
    height: 100vh;
  }
}

#recruitTop .mainviewSeen .commonSeen img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#recruitTop .mainviewSeen .seen05 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#recruitTop .mainviewSeen .seen05 .backImg {
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s_5_bg_pc@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen05 .backImg {
    background-image: url(/images/mainview/s_5_bg_sp@2x.png);
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen05 .backImg {
    background-image: url(/images/mainview/s_5_bg_sp@2x.png);
  }
}

#recruitTop .mainviewSeen .seen05 .mainviewTit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
  transition: transform .4s;
  width: 95.38799vw;
  max-width: 1303px;
  max-height: 100vh;
  z-index: 1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen05 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 82%;
    top: 0.52083vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .mainviewTit {
    top: 5px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen05 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen05 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 82%;
    top: 0.66667vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .mainviewTit {
    top: 5px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen05 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

#recruitTop .mainviewSeen .seen05 .imgFade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: opacity;
}

#recruitTop .mainviewSeen .seen05 .img01 {
  position: absolute;
  bottom: 2.92826vw;
  left: 50%;
  transform: translateX(-50%);
  width: 80.52709vw;
  max-width: 1100px;
  max-height: 89.5%;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img01 {
    bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen05 .img01 {
    bottom: calc(50% - 33.91667vw);
    left: 50%;
    transform: translateX(-50%);
    width: 73.95833vw;
    max-width: 710px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen05 .img01 {
    transform: none;
    bottom: auto;
    top: calc(50% - 35.33333vw);
    left: 2.66667vw;
    width: 94.66667vw;
    max-width: 710px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img01 {
    left: 20px;
  }
}

#recruitTop .mainviewSeen .seen05 .img02 {
  position: absolute;
  bottom: 2.92826vw;
  left: 50%;
  transform: translateX(-50%);
  width: 20.4978vw;
  max-width: 280px;
  max-height: 60.2%;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img02 {
    bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen05 .img02 {
    bottom: auto;
    top: 50.41667vw;
    left: 50%;
    transform: translateX(-50%);
    width: 25.625vw;
    max-width: 246px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img02 {
    top: 484px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen05 .img02 {
    transform: none;
    bottom: auto;
    top: 50.41667vw;
    left: 33.6vw;
    width: 32.8vw;
    max-width: 246px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img02 {
    top: 484px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img02 {
    left: 252px;
  }
}

#recruitTop .mainviewSeen .seen05 .img03 {
  position: absolute;
  animation: spaceAnime02 3s linear infinite alternate;
  top: 3.00146vw;
  right: 30.16105vw;
  width: 13.90922vw;
  max-width: 190px;
  max-height: 25.3%;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img03 {
    top: 41px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img03 {
    right: 412px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen05 .img03 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    top: 23.02083vw;
    right: 10.10417vw;
    width: 16.14583vw;
    max-width: 155px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img03 {
    top: 221px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img03 {
    right: 97px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen05 .img03 {
    animation: spaceAnimeSp02 3s linear infinite alternate;
    top: calc(50% - 58.8vw);
    right: 12.93333vw;
    width: 20.66667vw;
    max-width: 155px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen05 .img03 {
    right: 97px;
  }
}

#recruitTop .mainviewSeen .seen06 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
}

#recruitTop .mainviewSeen .seen06 .wrap {
  max-height: 768px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen06 .wrap {
    max-height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen06 .wrap {
    max-height: 100vh;
  }
}

#recruitTop .mainviewSeen .seen06 .backImg {
  width: 100%;
  height: 100%;
  background-image: url(/images/mainview/s6_bg_pc.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen06 .backImg {
    background-image: url(/images/mainview/s_6_bg_sp@2x.png);
    background-size: 97.2%;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen06 .backImg {
    background-image: url(/images/mainview/s_6_bg_sp@2x.png);
    background-size: 97.2%;
  }
}

#recruitTop .mainviewSeen .seen06 .mainviewTit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
  transition: transform .4s;
  width: 98.8287vw;
  max-width: 1350px;
  max-height: 100vh;
  z-index: 1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen06 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 80%;
    top: 0vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .mainviewTit {
    top: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen06 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen06 .mainviewTit {
    display: block;
    max-width: 100%;
    height: 80%;
    top: 0vw;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .mainviewTit {
    top: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen06 .mainviewTit img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

#recruitTop .mainviewSeen .seen06 .imgFade {
  opacity: 0;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: opacity;
}

#recruitTop .mainviewSeen .seen06 .img01 {
  position: absolute;
  bottom: 4.46559vw;
  right: 16.03221vw;
  width: 62.59151vw;
  max-width: 855px;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img01 {
    bottom: 61px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img01 {
    right: 219px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen06 .img01 {
    bottom: calc(50% - 35.875vw);
    right: 0vw;
    width: 83.33333vw;
    max-width: 800px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img01 {
    right: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen06 .img01 {
    bottom: calc(50% - 48.4vw);
    right: 0vw;
    width: 100vw;
    max-width: 750px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img01 {
    right: 0px;
  }
}

#recruitTop .mainviewSeen .seen06 .img02 {
  position: absolute;
  animation: spaceAnime01 3s linear infinite alternate;
  top: 7.9063vw;
  right: 2.04978vw;
  width: 27.96486vw;
  max-width: 382px;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    top: 108px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    right: 28px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    top: 42.5vw;
    right: 3.22917vw;
    width: 31.04167vw;
    max-width: 298px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    top: 408px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    right: 31px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    animation: spaceAnimeSp01 3s linear infinite alternate;
    top: 66.4vw;
    right: 4.13333vw;
    width: 33.06667vw;
    max-width: 248px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    top: 498px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainviewSeen .seen06 .img02 {
    right: 31px;
  }
}

#recruitTop .spaceArea {
  position: relative;
  background-image: url(/images/recruit/bg_outerspace.jpg);
  background-size: contain;
  background-position: top center;
  z-index: 1;
}

#recruitTop .spaceArea::before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1366px;
  width: 100%;
  height: 56.22255vw;
  max-height: 768px;
  background-image: url(/images/recruit/bg_star_reverse.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  z-index: -1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .spaceArea {
    background-image: url(/images/recruit/bg_outerspace_sp.jpg);
  }
  #recruitTop .spaceArea::before {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .spaceArea {
    background-image: url(/images/recruit/bg_outerspace_sp.jpg);
  }
  #recruitTop .spaceArea::before {
    display: none;
  }
}

#recruitTop .gradationArea {
  padding-top: 7.32064vw;
  padding-bottom: 8.78477vw;
  background-image: linear-gradient(to bottom, rgba(159, 207, 211, 0.5), rgba(252, 249, 245, 0.5) 50%, rgba(230, 195, 216, 0.5));
}

@media screen and (min-width: 1366px) {
  #recruitTop .gradationArea {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .gradationArea {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .gradationArea {
    padding-top: 11.45833vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .gradationArea {
    padding-top: 110px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .gradationArea {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .gradationArea {
    padding-top: 14.66667vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .gradationArea {
    padding-top: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .gradationArea {
    padding-bottom: 120px;
  }
}

#recruitTop .mainview {
  position: relative;
  z-index: 1;
  padding-top: 12.88433vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainview {
    padding-top: 176px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview {
    padding-top: 25.52083vw;
    padding-bottom: 4.16667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainview {
    padding-top: 245px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainview {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview {
    padding-top: 32.66667vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainview {
    padding-top: 245px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainview {
    padding-bottom: 40px;
  }
}

#recruitTop .mainview .container {
  position: relative;
}

#recruitTop .mainview .mainviewTit {
  opacity: 0;
  visibility: hidden;
  -webkit-text-stroke: 2px #fff;
  font-family: "Teko", sans-serif;
  font-size: 12rem;
  font-size: 8.33333vw;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: 3.6px;
  color: rgba(224, 236, 244, 0);
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainview .mainviewTit {
    font-size: 12rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit {
    font-size: 12.5vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit {
    font-size: 16vw;
  }
}

#recruitTop .mainview .mainviewTit.isShow {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s, visibility .3s;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit {
    letter-spacing: 1.8px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit {
    letter-spacing: 1.8px;
  }
}

#recruitTop .mainview .mainviewTit .img {
  width: 29.13616vw;
  max-width: 398px;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit .img {
    width: 41.45833vw;
    max-width: 398px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit .img {
    width: 53.06667vw;
    max-width: 398px;
  }
}

#recruitTop .mainview .mainviewTit svg {
  max-width: 100%;
  width: 100%;
  height: auto;
  fill: transparent;
  stroke-width: 2px;
  stroke: #fff;
}

#recruitTop .mainview .mainviewTit .lead {
  display: flex;
  align-items: center;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: normal;
  color: #fff;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 2.4vw;
  }
}

#recruitTop .mainview .mainviewTit .lead::before {
  content: "";
  display: block;
  width: 3.29429vw;
  max-width: 45px;
  height: 1px;
  background-color: #fff;
  margin-right: 1.0981vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit .lead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .mainviewTit .lead::before {
    width: 6vw;
    max-width: 45px;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainview .mainviewTit .lead::before {
    margin-right: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .imgBox {
    display: flex;
    align-items: center;
  }
}

#recruitTop .mainview .img01 {
  position: absolute;
  width: 8.85798vw;
  max-width: 121px;
  top: 3.51391vw;
  right: 4.39239vw;
  animation: spaceAnime01 3.5s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainview .img01 {
    top: 48px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .mainview .img01 {
    right: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .mainview .img01 {
    width: 12.60417vw;
    max-width: 121px;
    top: auto;
    bottom: -4.6875vw;
    right: 4.16667vw;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainview .img01 {
    bottom: -45px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .mainview .img01 {
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .mainview .img01 {
    width: 16.13333vw;
    max-width: 121px;
    top: auto;
    bottom: -6vw;
    right: 5.33333vw;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainview .img01 {
    bottom: -45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .mainview .img01 {
    right: 40px;
  }
}

#recruitTop .recruitAbout {
  padding-top: 32.57687vw;
  padding-bottom: 8.78477vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .recruitAbout {
    padding-top: 445px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .recruitAbout {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .recruitAbout {
    padding-top: 57.08333vw;
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .recruitAbout {
    padding-top: 548px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .recruitAbout {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .recruitAbout {
    padding-top: 73.06667vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .recruitAbout {
    padding-top: 548px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .recruitAbout {
    padding-bottom: 120px;
  }
}

#recruitTop .recruitAbout .recruitAboutTitArea {
  position: absolute;
  top: -5.27086vw;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(/images/recruit/recruit_fv_01_pc@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
  width: 57.54026vw;
  max-width: 786px;
  height: 34.26061vw;
  max-height: 468px;
}

@media screen and (min-width: 1366px) {
  #recruitTop .recruitAbout .recruitAboutTitArea {
    top: -72px;
  }
}

#recruitTop .recruitAbout .recruitAboutTitArea.isShow {
  opacity: 1;
  visibility: visible;
  transition: opacity .8s, visibility .8s;
}

#recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit {
  position: absolute;
  bottom: 4.90483vw;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  width: 42.24012vw;
  max-width: 577px;
}

@media screen and (min-width: 1366px) {
  #recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit {
    bottom: 67px;
  }
}

#recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit.isShow::after {
  transform: translateX(100%);
}

#recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -50%;
  width: 150%;
  height: 100%;
  transform: translateX(0);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 30%);
  transition: transform 2s;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .recruitAbout .recruitAboutTitArea {
    top: 0;
    background-image: url(/images/recruit/recruit_fv_01_sp@2x.png);
    width: 69.79167vw;
    max-width: 670px;
    height: 48.75vw;
    max-height: 468px;
  }
  #recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit {
    bottom: 6.66667vw;
    width: 55.41667vw;
    max-width: 532px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit {
    bottom: 64px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .recruitAbout .recruitAboutTitArea {
    top: 0;
    background-image: url(/images/recruit/recruit_fv_01_sp@2x.png);
    width: 89.33333vw;
    max-width: 670px;
    height: 62.4vw;
    max-height: 468px;
  }
  #recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit {
    bottom: 8.53333vw;
    width: 70.93333vw;
    max-width: 532px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .recruitAbout .recruitAboutTitArea .recruitAboutTit {
    bottom: 64px;
  }
}

#recruitTop .recruitAbout .recruitAboutTxt {
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 1.08px;
  text-align: center;
  color: #fff;
}

@media screen and (min-width: 1366px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    text-align: left;
    letter-spacing: .96px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    text-align: left;
    letter-spacing: .96px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .recruitAbout .recruitAboutTxt {
    font-size: 4.26667vw;
  }
}

#recruitTop .workFlow {
  padding-bottom: 4.02635vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow {
    padding-bottom: 55px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow {
    padding-bottom: 36.77083vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow {
    padding-bottom: 353px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow {
    padding-bottom: 47.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow {
    padding-bottom: 353px;
  }
}

#recruitTop .workFlow .container {
  position: relative;
  z-index: 0;
}

#recruitTop .workFlow .container::before {
  position: absolute;
  top: 5.49048vw;
  left: 0vw;
  content: "";
  display: block;
  background-image: url(/images/recruit/recruit_flow_direct@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 18.37482vw;
  max-width: 251px;
  height: 16.39824vw;
  max-height: 224px;
  z-index: -1;
  animation: spaceAnime01 3.5s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    top: 75px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    left: 0px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    left: -8.27233vw;
  }
}

@media screen and (min-width: 1366px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    left: -113px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .container::before {
    top: auto;
    bottom: -36.77083vw;
    left: 8.33333vw;
    width: 35.52083vw;
    max-width: 341px;
    height: 31.5625vw;
    max-height: 303px;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    bottom: -353px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    left: 80px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .container::before {
    top: auto;
    bottom: -47.06667vw;
    left: 10.66667vw;
    width: 45.46667vw;
    max-width: 341px;
    height: 40.4vw;
    max-height: 303px;
    animation: spaceAnimeSp01 3.5s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    bottom: -353px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::before {
    left: 80px;
  }
}

#recruitTop .workFlow .container::after {
  position: absolute;
  bottom: -2.92826vw;
  right: -0.73206vw;
  content: "";
  display: block;
  background-image: url(/images/recruit/recruit_flow_taco@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 7.9063vw;
  max-width: 108px;
  height: 6.14934vw;
  max-height: 84px;
  animation: spaceAnime03 3.5s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .container::after {
    bottom: -40px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .container::after {
    right: -10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .container::after {
    top: auto;
    bottom: -29.27083vw;
    right: 12.70833vw;
    width: 15.3125vw;
    max-width: 147px;
    height: 11.875vw;
    max-height: 114px;
    animation: spaceAnimeSp03 3.5s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::after {
    bottom: -281px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::after {
    right: 122px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .container::after {
    top: auto;
    bottom: -37.46667vw;
    right: 16.26667vw;
    width: 19.6vw;
    max-width: 147px;
    height: 15.2vw;
    max-height: 114px;
    animation: spaceAnimeSp03 3.5s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::after {
    bottom: -281px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .container::after {
    right: 122px;
  }
}

#recruitTop .workFlow .workFlowTit {
  -webkit-text-stroke: 1px #333;
  font-family: "Teko", sans-serif;
  font-size: 8rem;
  font-size: 5.55556vw;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 2.4px;
  text-align: center;
  color: rgba(224, 236, 244, 0);
  margin-bottom: 6.44217vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 8.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit {
    margin-bottom: 88px;
  }
}

#recruitTop .workFlow .workFlowTit .lead {
  display: block;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #555;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 12rem;
    font-size: 8.33333vw;
    letter-spacing: 1.8px;
    margin-bottom: 12.1875vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 12rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 12.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 16vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit {
    margin-bottom: 117px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 12rem;
    font-size: 8.33333vw;
    letter-spacing: 1.8px;
    margin-bottom: 15.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 12rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 12.5vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit {
    font-size: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit {
    margin-bottom: 117px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowTit .lead {
    font-size: 3.73333vw;
  }
}

#recruitTop .workFlow .workFlowCon {
  max-width: 980px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-image: url(/images/recruit/recruit_flow_line_pc.svg);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  margin: 0 auto;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon {
    display: block;
    background-image: none;
    position: relative;
    z-index: 0;
  }
  #recruitTop .workFlow .workFlowCon::after {
    content: "";
    display: block;
    position: absolute;
    top: 5%;
    right: 5.20833vw;
    width: 1.5625vw;
    max-width: 15px;
    height: 90%;
    background-color: #a1d5d8;
    z-index: -1;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon::after {
    right: 50px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon {
    display: block;
    background-image: none;
    position: relative;
    z-index: 0;
  }
  #recruitTop .workFlow .workFlowCon::after {
    content: "";
    display: block;
    position: absolute;
    top: 5%;
    right: 6.66667vw;
    width: 2vw;
    max-width: 15px;
    height: 90%;
    background-color: #a1d5d8;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon::after {
    right: 50px;
  }
}

#recruitTop .workFlow .workFlowCon .box {
  max-width: 23%;
  width: 100%;
  border-radius: 20px;
  border: solid 1px #e0ecf4;
  background-color: #fff;
  padding-top: 1.46413vw;
  padding-bottom: 2.19619vw;
  padding-right: 1.46413vw;
  padding-left: 1.46413vw;
  margin-right: 2.04978vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    margin-right: 28px;
  }
}

#recruitTop .workFlow .workFlowCon .box:last-of-type {
  margin-right: 0;
  margin-bottom: 0;
}

#recruitTop .workFlow .workFlowCon .box:nth-of-type(even) {
  margin-top: 4.17277vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(even) {
    margin-top: 57px;
  }
}

#recruitTop .workFlow .workFlowCon .box:nth-of-type(1) .workFlowConTit {
  padding-left: 2.78184vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(1) .workFlowConTit {
    padding-left: 38px;
  }
}

#recruitTop .workFlow .workFlowCon .box:nth-of-type(4) .workFlowConTit {
  padding-left: 3.80673vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(4) .workFlowConTit {
    padding-left: 52px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .box {
    max-width: 100%;
    padding-top: 3.125vw;
    padding-bottom: 4.16667vw;
    padding-right: 3.125vw;
    padding-left: 3.125vw;
    margin-right: 0vw;
    margin-bottom: 7.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-top: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-right: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-left: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    margin-right: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    margin-bottom: 76px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(even) {
    margin-top: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(even) {
    margin-top: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(1) .workFlowConTit {
    padding-left: 5.52083vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(1) .workFlowConTit {
    padding-left: 53px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(4) .workFlowConTit {
    padding-left: 7.39583vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(4) .workFlowConTit {
    padding-left: 71px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .box {
    max-width: 100%;
    padding-top: 4vw;
    padding-bottom: 5.33333vw;
    padding-right: 4vw;
    padding-left: 4vw;
    margin-right: 0vw;
    margin-bottom: 10.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box {
    margin-bottom: 76px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(even) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(even) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(1) .workFlowConTit {
    padding-left: 7.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(1) .workFlowConTit {
    padding-left: 53px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(4) .workFlowConTit {
    padding-left: 9.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .box:nth-of-type(4) .workFlowConTit {
    padding-left: 71px;
  }
}

#recruitTop .workFlow .workFlowCon .workFlowConTit {
  position: relative;
  font-size: 2rem;
  font-size: 1.38889vw;
  font-weight: 500;
  line-height: 1.2;
  color: #333;
  padding-left: 3.51391vw;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    padding-left: 48px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    margin-bottom: 30px;
  }
}

#recruitTop .workFlow .workFlowCon .workFlowConTit .num {
  position: absolute;
  top: -3.51391vw;
  left: 0;
  font-family: "Teko", sans-serif;
  font-size: 10rem;
  font-size: 6.94444vw;
  font-weight: normal;
  line-height: 1;
  color: #a1d5d8;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    top: -48px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 10rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 10.41667vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 3.6rem;
    font-size: 2.5vw;
    font-weight: bold;
    line-height: 1.33;
    padding-left: 6.875vw;
    margin-bottom: 3.125vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 3.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 3.75vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    padding-left: 66px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    top: -6.25vw;
    font-size: 14rem;
    font-size: 9.72222vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    top: -60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 14rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 14.58333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 18.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 3.6rem;
    font-size: 2.5vw;
    font-weight: bold;
    line-height: 1.33;
    padding-left: 8.8vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 3.75vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    padding-left: 66px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    top: -8vw;
    font-size: 14rem;
    font-size: 9.72222vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    top: -60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 14rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 14.58333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTit .num {
    font-size: 18.66667vw;
  }
}

#recruitTop .workFlow .workFlowCon .workFlowConTxt {
  font-size: 1.4rem;
  font-size: 0.97222vw;
  line-height: 1.75;
  color: #333;
}

@media screen and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.32px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .workFlow .workFlowCon .workFlowConTxt {
    font-size: 4.26667vw;
  }
}

#recruitTop .wanted {
  padding-bottom: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted {
    padding-bottom: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted {
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted {
    padding-bottom: 60px;
  }
}

#recruitTop .wanted .wantedTit {
  -webkit-text-stroke: 1px #333;
  font-family: "Teko", sans-serif;
  font-size: 8rem;
  font-size: 5.55556vw;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 2.4px;
  text-align: center;
  color: rgba(224, 236, 244, 0);
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit {
    font-size: 8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit {
    font-size: 8.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedTit {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit {
    margin-bottom: 40px;
  }
}

#recruitTop .wanted .wantedTit .lead {
  display: block;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #555;
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1.3;
    letter-spacing: 1.5px;
    margin-bottom: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit {
    font-size: 10rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit {
    font-size: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .wanted .wantedTit {
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1.3;
    letter-spacing: 1.5px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit {
    font-size: 10rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit {
    font-size: 10.41667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .wanted .wantedTit {
    font-size: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .wanted .wantedTit .lead {
    font-size: 3.73333vw;
  }
}

#recruitTop .wanted .wantedList {
  max-width: 980px;
  display: flex;
  flex-wrap: wrap;
  border-top: solid 1px #ccc;
  margin: 0 auto;
  padding-top: 2.92826vw;
  padding-left: 8.05271vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedList {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedList {
    padding-left: 110px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList {
    padding-top: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList {
    padding-top: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedList {
    padding-top: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList {
    padding-left: 0px;
  }
}

#recruitTop .wanted .wantedList .wantedListTit {
  max-width: 22.99%;
  width: 100%;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  line-height: 2;
  letter-spacing: 0.96px;
  color: #333;
  margin-right: 1.46413vw;
  margin-bottom: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    margin-bottom: 25px;
  }
}

#recruitTop .wanted .wantedList .wantedListTit:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    max-width: 100%;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.32px;
    padding-top: 4.16667vw;
    padding-bottom: 3.125vw;
    margin-right: 0vw;
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    padding-top: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    margin-right: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    max-width: 100%;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.32px;
    padding-top: 5.33333vw;
    padding-bottom: 4vw;
    margin-right: 0vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListTit {
    margin-bottom: 0px;
  }
}

#recruitTop .wanted .wantedList .wantedListCon {
  max-width: 74.713%;
  width: 100%;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.96px;
  color: #333;
  margin-bottom: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    margin-bottom: 25px;
  }
}

#recruitTop .wanted .wantedList .wantedListCon:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    max-width: 100%;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.32px;
    border-bottom: solid 1px #ccc;
    padding-bottom: 3.125vw;
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 3.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    max-width: 100%;
    font-size: 3.2rem;
    font-size: 2.22222vw;
    line-height: 1.5;
    letter-spacing: 0.32px;
    border-bottom: solid 1px #ccc;
    padding-bottom: 4vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .wanted .wantedList .wantedListCon {
    margin-bottom: 0px;
  }
}

#recruitTop .application {
  padding-top: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application {
    padding-top: 50px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application {
    padding-top: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application {
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application {
    padding-top: 60px;
  }
}

#recruitTop .application .container {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
  padding-top: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .container {
    padding-top: 30px;
  }
}

#recruitTop .application .container::before {
  position: absolute;
  top: 6.29575vw;
  right: 3.4407vw;
  content: "";
  display: block;
  background-image: url(/images/recruit/recruit_apply_illu_02@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 8.78477vw;
  max-width: 120px;
  height: 6.36896vw;
  max-height: 87px;
  animation: spaceAnime01 3s linear infinite alternate;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .container::before {
    top: 86px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .container::before {
    right: 47px;
  }
}

#recruitTop .application .container::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 96.492%;
  height: 26.86676vw;
  max-height: 367px;
  border-radius: 184px;
  background-color: white;
  background-image: url(/images/recruit/recruit_apply_bg_pc.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .container {
    flex-flow: column;
    padding-top: 4.6875vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .container {
    padding-top: 45px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .container::before {
    top: auto;
    bottom: 29.47917vw;
    right: 6.25vw;
    width: 16.25vw;
    max-width: 156px;
    height: 11.77083vw;
    max-height: 113px;
    animation: spaceAnimeSp01 3s linear infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .container::before {
    bottom: 283px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .container::before {
    right: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .container::after {
    width: calc(100% - 40px);
    height: 96.875vw;
    max-height: 930px;
    border-radius: 15px;
    background-image: url(/images/recruit/recruit_apply_bg_sp@2x.png);
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .container {
    flex-flow: column;
    padding-top: 6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .container {
    padding-top: 45px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .container::before {
    top: auto;
    bottom: 37.73333vw;
    right: 8vw;
    width: 20.8vw;
    max-width: 156px;
    height: 15.06667vw;
    max-height: 113px;
    animation: spaceAnimeSp01 3s linear infinite alternate;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .container::before {
    bottom: 283px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .container::before {
    right: 60px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .container::after {
    width: calc(100% - 40px);
    height: 124vw;
    max-height: 930px;
    border-radius: 15px;
    background-image: url(/images/recruit/recruit_apply_bg_sp@2x.png);
    background-size: contain;
  }
}

#recruitTop .application .imgBox {
  width: 39.09224vw;
  max-width: 534px;
  margin-top: 6.58858vw;
  margin-right: 3.07467vw;
  margin-left: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    margin-right: 42px;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    margin-left: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .imgBox {
    order: 2;
    margin: 0 auto;
    width: 69.79167vw;
    max-width: 670px;
    padding-right: 4.16667vw;
    padding-left: 4.16667vw;
    margin-top: 10.20833vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    padding-right: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    padding-left: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    margin-top: 98px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .imgBox {
    order: 2;
    margin: 0 auto;
    width: 89.33333vw;
    max-width: 670px;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 13.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .imgBox {
    margin-top: 98px;
  }
}

#recruitTop .application .txtBox {
  margin-right: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .txtBox {
    margin-right: 80px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .txtBox {
    order: 1;
    padding-right: 4.16667vw;
    padding-left: 4.16667vw;
    margin-right: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .txtBox {
    padding-right: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .txtBox {
    padding-left: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .txtBox {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .txtBox {
    order: 1;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .txtBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .txtBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .txtBox {
    margin-right: 0px;
  }
}

#recruitTop .application .applicationTit {
  -webkit-text-stroke: 1px #333;
  font-family: "Teko", sans-serif;
  font-size: 6rem;
  font-size: 4.16667vw;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 1.8px;
  color: rgba(224, 236, 244, 0);
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationTit {
    font-size: 6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit {
    font-size: 6.25vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationTit {
    font-size: 8vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationTit {
    margin-bottom: 20px;
  }
}

#recruitTop .application .applicationTit .lead {
  display: flex;
  align-items: center;
  -webkit-text-stroke: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 1.25vw;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #555;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 2.4vw;
  }
}

#recruitTop .application .applicationTit .lead::before {
  content: "";
  display: block;
  width: 1.46413vw;
  max-width: 20px;
  height: 1px;
  background-color: #707070;
  margin-right: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationTit .lead::before {
    margin-right: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1.1;
    letter-spacing: 1.5px;
    margin-bottom: 6.25vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationTit {
    font-size: 10rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit {
    font-size: 10.41667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .application .applicationTit {
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationTit {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
    margin-top: 1.04167vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationTit .lead {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationTit {
    font-size: 10rem;
    font-size: 6.94444vw;
    line-height: 1.3;
    letter-spacing: 1.5px;
    text-align: center;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationTit {
    font-size: 10rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit {
    font-size: 10.41667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .application .applicationTit {
    font-size: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationTit .lead {
    display: block;
    font-size: 2.8rem;
    font-size: 1.94444vw;
    letter-spacing: 0.42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .application .applicationTit .lead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationTit .lead::before {
    display: none;
  }
}

#recruitTop .application .applicationList {
  counter-reset: listCounter;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationList {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList {
    margin-bottom: 2.08333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationList {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationList {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationList {
    margin-bottom: 20px;
  }
}

#recruitTop .application .applicationList .list {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-size: 1.11111vw;
  line-height: 2.29;
  color: #333;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationList .list {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list {
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationList .list {
    font-size: 2.13333vw;
  }
}

#recruitTop .application .applicationList .list span {
  font-size: 1.4rem;
  font-size: 0.97222vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationList .list span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list span {
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationList .list span {
    font-size: 1.86667vw;
  }
}

#recruitTop .application .applicationList .list:before {
  content: counter(listCounter);
  counter-increment: listCounter;
  border: 1px solid;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.2rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  width: 1.1713vw;
  max-width: 16px;
  height: 1.1713vw;
  max-height: 16px;
  margin-right: 0.14641vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 1.6vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationList .list:before {
    margin-right: 2px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    align-items: baseline;
    line-height: 1.5;
    margin-bottom: 0.52083vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .application .applicationList .list {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list span {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list span {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list span {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .application .applicationList .list span {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 2.4rem;
    font-size: 1.66667vw;
    line-height: 2;
    width: 3.125vw;
    max-width: 30px;
    height: 3.125vw;
    max-height: 30px;
    margin-right: 0.20833vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list:before {
    margin-right: 2px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationList .list {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    align-items: baseline;
    line-height: 1.5;
    margin-bottom: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .application .applicationList .list {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationList .list span {
    font-size: 2.8rem;
    font-size: 1.94444vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list span {
    font-size: 2.91667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .application .applicationList .list span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 2.4rem;
    font-size: 1.66667vw;
    line-height: 2;
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
    margin-right: 0.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .application .applicationList .list:before {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationList .list:before {
    margin-right: 2px;
  }
}

#recruitTop .application .applicationTxt {
  font-size: 1.3rem;
  font-size: 0.90278vw;
  line-height: 1.38;
  color: #555;
  border-top: solid 1px #e5e5e5;
  padding-top: 1.0981vw;
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationTxt {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTxt {
    font-size: 1.35417vw;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationTxt {
    font-size: 1.73333vw;
  }
}

@media screen and (min-width: 1366px) {
  #recruitTop .application .applicationTxt {
    padding-top: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTxt {
    font-size: 2.8rem;
    font-size: 1.94444vw;
    line-height: 1.52;
    padding-top: 0vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationTxt {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTxt {
    font-size: 2.91667vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (max-width: 767px) {
  #recruitTop .application .applicationTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 960px) and (min-width: 1366px) {
  #recruitTop .application .applicationTxt {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  #recruitTop .application .applicationTxt {
    font-size: 2.1rem;
    font-size: 1.45833vw;
    line-height: 1.52;
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationTxt {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) and (max-width: 960px) {
  #recruitTop .application .applicationTxt {
    font-size: 2.1875vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recruitTop .application .applicationTxt {
    font-size: 2.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #recruitTop .application .applicationTxt {
    padding-top: 0px;
  }
}

#recruitTop .fadeInRecruit {
  opacity: 0;
}
