@charset "utf-8";

/* ==============================================
aboutUs トップページ
============================================== */
#aboutUsTop {
  overflow-x: hidden;

  .spaceArea {
    position: relative;
    background-image: url(/images/about_us/bg_outerspace.jpg);
    background-size: contain;
    background-position: top center;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1366px;
      width: 100%;
      @include props(h, 768);
      background-image: url(/images/about_us/bg_star_reverse.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      z-index: -1;
    }

    @include mq(tabMin) {
      background-image: url(/images/about_us/bg_outerspace_sp.jpg);

      &::before {
        display: none;
      }
    }
    @include mq(md) {
      background-image: url(/images/about_us/bg_outerspace_sp.jpg);

      &::before {
        display: none;
      }
    }
  }

  .mainview {
    position: relative;
    z-index: 1;
    @include props(pt, 176);
    @include props(pb, 117);

    @include mq(tabMin) {
      @include props(pt, 245, 960);
      @include props(pb, 25, 960);
    }

    @include mq(md) {
      @include props(pt, 245, 750);
      @include props(pb, 25, 750);
    }

    .container{
      position: relative;
    }

    .mainviewTit {
      opacity: 0;
      visibility: hidden;
      -webkit-text-stroke: 2px #fff;
      font-family: $Teko;
      @include fz_vw(120);
      font-weight: bold;
      line-height: 1.08;
      letter-spacing: 3.6px;
      color: rgba($color: #e0ecf4, $alpha: 0);

      &.isShow{
        opacity: 1;
        visibility: visible;
        transition: opacity .3s, visibility .3s;
      }

      @include mq(tabMin) {
        letter-spacing: 1.8px;
      }
  
      @include mq(md) {
        letter-spacing: 1.8px;
      }

      .img{
        @include props(w, 474);

        @include mq(tabMin) {
          @include props(w, 474, 960);
        }
    
        @include mq(md) {
          @include props(w, 474, 750);
        }
      }

      svg{
        max-width: 100%;
        width: 100%;
        height: auto;
        fill: transparent;
        stroke-width: 2px;
        stroke: #e0ecf4;
      }

      .lead {
        display: flex;
        align-items: center;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: normal;
        color: #fff;

        &::before {
          content: "";
          display: block;
          @include props(w, 45);
          height: 1px;
          background-color: #fff;
          @include props(mr, 15);
        }

        @include mq(tabMin) {
          @include fz_vw(28);

          &::before {
            @include props(w, 45, 750);
            @include props(mr, 15, 750);
          }
        }
    
        @include mq(md) {
          @include fz_vw(28);

          &::before {
            @include props(w, 45, 750);
            @include props(mr, 15, 750);
          }
        }
      }
    }

    .imgBox{
      @include mq(tabMin) {
        display: flex;
        align-items: center;
        justify-content: center;
        @include props(mt, 25, 960);
      }
  
      @include mq(md) {
        display: flex;
        align-items: center;
        @include props(mt, 25, 750);
      }
    }

    .img01{
      position: absolute;
      @include props(w, 519);
      @include props(t, -75);
      @include props(l, 425);
      animation: spaceAnime01 3.5s linear infinite alternate;

      @include mq(tabMin) {
        @include props(w, 615, 960);
        position: static;
        animation: spaceAnimeSp01 3.5s linear infinite alternate;
      }
  
      @include mq(md) {
        @include props(w, 615, 750);
        position: static;
        animation: spaceAnimeSp01 3.5s linear infinite alternate;
      }
    }
    .img02{
      position: absolute;
      @include props(w, 192);
      @include props(t, 95);
      @include props(l, 848);
      animation: spaceAnime03 3.5s linear infinite alternate;

      @include mq(tabMin) {
        @include props(w, 191, 960);
        top: auto;
        left: auto;
        @include props(b, 20, 960);
        @include props(r, 40, 960);
        animation: spaceAnimeSp03 3.5s linear infinite alternate;
      }
  
      @include mq(md) {
        @include props(w, 191, 750);
        top: auto;
        left: auto;
        @include props(b, 20, 750);
        @include props(r, 40, 750);
        animation: spaceAnimeSp03 3.5s linear infinite alternate;
      }
    }
  }

  .mainviewTxtBox{
    @include props(pt, 60);
    @include props(pb, 160);
    
    @include mq(tabMin) {
      @include props(pt, 50, 960);
      @include props(pb, 120, 960);
    }
  
    @include mq(md) {
      @include props(pt, 50, 750);
      @include props(pb, 120, 750);
    }

    .mainviewTxt{
      @include fz_vw(18);
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.96px;
      text-align: center;
      color: #fff;
      margin: 0 auto;
  
      @include mq(tabMin) {
        @include fz_vw(32);
        text-align: left;
      }
    
      @include mq(md) {
        @include fz_vw(32);
        text-align: left;
      }
    }
  } 

  .conceptArea{
    position: relative;
    z-index: 1;
    @include props(pt, 80);
    @include props(pb, 160);

    @include mq(tabMin) {
      @include props(pt, 95, 960);
      @include props(pb, 120, 960);
    }

    @include mq(md) {
      @include props(pt, 95, 750);
      @include props(pb, 120, 750);
    }

    .conceptAreaTit {
      position: relative;
      -webkit-text-stroke: 1px #fff;
      font-family: $Teko;
      @include fz_vw(80);
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 2.4px;
      text-align: center;
      color: rgba($color: #e0ecf4, $alpha: 0);
      @include props(pb, 60);

      .lead {
        display: block;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: 0.54px;
        color: #fff;
      }

      @include mq(tabMin) {
        @include fz_vw(104);
        line-height: 1;
        letter-spacing: 1.56px;
        @include props(pb, 75, 960);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
  
      @include mq(md) {
        @include fz_vw(104);
        line-height: 1;
        letter-spacing: 1.56px;
        @include props(pb, 75, 750);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
        }
      }
    }

    .conceptAreaLead{
      @include fz_vw(56);
      font-weight: bold;
      text-align: center;
      color: #fff;
      @include props(mb, 30);

      @include mq(tabMin) {
        @include fz_vw(56);
        @include props(mb, 40, 960);
      }
  
      @include mq(md) {
        @include fz_vw(56);
        @include props(mb, 40, 750);
      }
    }

    .conceptAreaTxt{
      max-width: 620px;
      @include fz_vw(22);
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.96px;
      text-align: center;
      color: #fff;
      margin: 0 auto;

      @include mq(tabMin) {
        @include fz_vw(34);
      }
  
      @include mq(md) {
        @include fz_vw(34);
      }
    }
  }

  .companyProfile{
    @include props(pt, 100);
    @include props(pb, 120);
    background-image: linear-gradient(to bottom, rgba(159, 207, 211, .5), rgba(252, 249, 245, .5) 50%, rgba(230, 195, 216, .5));

    @include mq(tabMin) {
      @include props(pt, 120, 960);
      @include props(pb, 120, 960);
    }

    @include mq(md) {
      @include props(pt, 120, 750);
      @include props(pb, 120, 750);
    }

    .companyProfileTit {
      -webkit-text-stroke: 1px #333;
      font-family: $Teko;
      @include fz_vw(80);
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 2.4px;
      text-align: center;
      color: rgba($color: #e0ecf4, $alpha: 0);
      @include props(mb, 40);

      .lead {
        display: block;
        -webkit-text-stroke: 0;
        font-family: $base-font;
        @include fz_vw(18);
        font-weight: 500;
        letter-spacing: 0.54px;
        color: #555;
      }

      @include mq(tabMin) {
        @include fz_vw(100);
        line-height: 1;
        letter-spacing: 1.5px;
        @include props(mb, 60, 960);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
          @include props(mt, 15, 960);
        }
      }
  
      @include mq(md) {
        @include fz_vw(100);
        line-height: 1;
        letter-spacing: 1.5px;
        @include props(mb, 60, 750);

        .lead {
          @include fz_vw(28);
          letter-spacing: 0.42px;
          @include props(mt, 15, 750);
        }
      }
    }


    .dataList{
      max-width: 800px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      margin: 0 auto;
      @include props(pt, 40);
      @include props(pr, 20);
      @include props(pl, 20);
      @include props(mb, 100);

      @include mq(tabMin) {
        @include props(pt, 40, 960);
        @include props(pr, 0);
        @include props(pl, 0);
        @include props(mb, 120, 960);
      }
  
      @include mq(md) {
        @include props(pt, 40, 750);
        @include props(pr, 0);
        @include props(pl, 0);
        @include props(mb, 120, 750);
      }

      .dataListTit{
        max-width: 26.316%;
        width: 100%;
        @include fz_vw(16);
        line-height: 2;
        letter-spacing: 0.96px;
        color: #333;
        @include props(mr, 20);
        @include props(mb, 40);

        @include mq(tabMin) {
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.48px;
          @include props(mb, 40, 960);

          &.access{
            max-width: 100%;
            @include props(mb, 30, 960);
          }
        }
    
        @include mq(md) {
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: 0.48px;
          @include props(mb, 40, 750);

          &.access{
            max-width: 100%;
            @include props(mb, 30, 750);
          }
        }
      }

      .dataListCon{
        max-width: 71.053%;
        width: 100%;
        @include fz_vw(16);
        line-height: 2;
        letter-spacing: 0.96px;
        color: #333;
        @include props(mb, 40);

        span{
          display: block;
          @include fz_vw(14);
          line-height: 1.5;
          letter-spacing: 0.84px;
          @include props(mb, 5);

          &:last-of-type{
            margin-bottom: 0;
          }
        }

        @include mq(tabMin) {
          @include fz_vw(32);
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.48px;
          @include props(mb, 40, 960);

          span{
            @include fz_vw(28);
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: 0.42px;
            @include props(mb, 20, 960);
          }

          &.access{
            max-width: 100%;
          }
        }
    
        @include mq(md) {
          @include fz_vw(32);
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.48px;
          @include props(mb, 40, 750);

          span{
            @include fz_vw(28);
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: 0.42px;
            @include props(mb, 20, 750);
          }

          &.access{
            max-width: 100%;
          }
        }
      }

      iframe{
        max-width: 100%;
        width: 100%;
        @include props(h, 300);
        @include props(mb, 10);

        @include mq(tabMin) {
          @include props(h, 372, 960);
          @include props(mb, 20, 960);
        }

        @include mq(md) {
          @include props(h, 372, 750);
          @include props(mb, 20, 750);
        }
      }
    }

    .clientList{
      max-width: 800px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      margin: 0 auto;
      @include props(pt, 40);
      @include props(pb, 15);
      @include props(pr, 20);
      @include props(pl, 20);

      @include mq(tabMin) {
        display: block;
        @include props(pt, 40, 960);
        @include props(pb, 10, 960);
        @include props(pr, 0);
        @include props(pl, 0);
      }
  
      @include mq(md) {
        display: block;
        @include props(pt, 40, 750);
        @include props(pb, 10, 750);
        @include props(pr, 0);
        @include props(pl, 0);
      }

      .list{
        max-width: 50%;
        width: 100%;
        @include fz_vw(16);
        font-weight: 500;
        line-height: 2;
        letter-spacing: 0.96px;
        color: #333;
        @include props(pr, 10);
        @include props(mb, 25);

        @include mq(tabMin) {
          max-width: 100%;
          @include fz_vw(32);
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: 0.32px;
          @include props(pr, 0);
          @include props(mb, 30, 960);
        }
    
        @include mq(md) {
          max-width: 100%;
          @include fz_vw(32);
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: 0.32px;
          @include props(pr, 0);
          @include props(mb, 30, 750);
        }
      }
    }
  }

  .fadeInAbout {
    opacity: 0;
    visibility:hidden;
    transform: translateY(30px);
    transition: opacity .8s .6s, transform .5s .5s;
    backface-visibility: hidden;
    
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}