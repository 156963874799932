@charset "utf-8";

/* =====================================================
フッター
===================================================== */
.bottom{
  #footer{
    background-color: rgba(230, 195, 216, .5);
  }
}
#footer{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
  height: 90vh;
  z-index: 1;
  @include props(pt, 102);
  @include props(pb, 10);
  transition: all .8s;
  background-color: #e6c3d8;

  &.scrollDown{
    background-color: #fff;
  }

  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/images/common/footer_bg_01.png);
    background-position: center;
    background-repeat: repeat-x;
    z-index: -1;
    animation: spaceFooterAnime01 3s linear infinite alternate;
  }
  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/images/common/footer_bg_02.png);
    background-repeat: repeat-x;
    background-position: center;
    z-index: -1;
    animation: spaceFooterAnime02 3s linear infinite alternate;
  }

  @include mq(tabMin) {
    @include props(pt, 100, 960);
    @include props(pb, 25, 960);

    &::before{
      background-image: url(/images/common/footer_bg_01_sp.png);
      background-size: contain;
    }
    &::after{
      background-image: url(/images/common/footer_bg_02_sp.png);
      background-size: contain;
    }
  }

  @include mq(md) {
    @include props(pt, 100, 750);
    @include props(pb, 25, 750);

    &::before{
      background-image: url(/images/common/footer_bg_01_sp.png);
      background-size: contain;
    }
    &::after{
      background-image: url(/images/common/footer_bg_02_sp.png);
      background-size: contain;
    }
  }

  .footerLogo{
    margin: 0 auto;
    @include props(w, 200);
    @include props(mb, 60);

    img{
      width: 100%;
    }

    @include mq(tabMin) {
      @include props(w, 270, 960);
      @include props(mb, 95, 960);
    }

    @include mq(md) {
      @include props(w, 270, 750);
      @include props(mb, 95, 750);
    }
  }

  .footerNavArea{
    display: flex;
    align-items: center;
    justify-content: center;
    @include props(mb, 20);

    .footerNav{
      font-family: $Teko;
      @include fz_vw(24);
      font-weight: 300;
      letter-spacing: 0.72px;
      color: #555555;
      @include props(mr, 15);
      @include props(ml, 15);

      a{
        color: #555555;
      }
    }

    @include mq(tabMin) {
      @include props(mb, 56, 960);

      .footerNav{
        @include fz_vw(46);
        letter-spacing: .69px;
        @include props(mr, 25, 960);
        @include props(ml, 25, 960);
      }
    }

    @include mq(md) {
      @include props(mb, 56, 750);

      .footerNav{
        @include fz_vw(46);
        letter-spacing: .69px;
        @include props(mr, 25, 750);
        @include props(ml, 25, 750);
      }
    }
  }

  .mailBtn{
    @include props(mb, 25);

    @include mq(tabMin) {
      @include props(mb, 80, 960);
    }

    @include mq(md) {
      @include props(mb, 80, 750);
    }
  }

  .snsBtn{
    @include props(w, 30);
    margin: 0 auto;
    @include props(mb, 84);

    @include mq(tabMin) {
      @include props(w, 55, 960);
      @include props(mb, 100, 960);
    }

    @include mq(md) {
      @include props(w, 55, 750);
      @include props(mb, 100, 750);
    }
  }

  .scrPageTop{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-family: $Teko;
    @include fz_vw(20);
    line-height: 1;
    letter-spacing: 0.6px;
    color: #808080;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    cursor: pointer;
    transition: transform .3s;
    @include props(pt, 51);
    @include props(pb, 80);
    @include props(r, 133);
    z-index: 20;

    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      @include props(l, 10);
      background-image: url(/images/common/footer_pagetop_arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      @include props(w, 6);
      @include props(h, 41);
      transition: all .3s;
    }
    &::after{
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-image: url(/images/common/footer_pagetop_taco@2x.png);
      background-repeat: no-repeat;
      background-size: contain;
      @include props(w, 85);
      @include props(h, 59);
      transition: all .3s;
    }

    &:hover{
      transform: translateY(-55%);
    }

    @include mq(tabMin) {
      transform: none;
      @include fz_vw(30);
      letter-spacing: 0.45px;
      @include props(pt, 83, 960);
      @include props(pb, 67, 960);
      @include props(t, 40, 960);
      @include props(r, 40, 960);

      &::before{
        @include props(l, 15, 960);
        @include props(w, 12, 960);
        @include props(h, 52, 960);
      }
      &::after{
        @include props(w, 73, 960);
        @include props(h, 52, 960);
      }

      &:hover{
        transform: translateY(-15%);
      }
    }

    @include mq(md) {
      transform: none;
      @include fz_vw(30);
      letter-spacing: 0.45px;
      @include props(pt, 83, 750);
      @include props(pb, 67, 750);
      @include props(t, 40, 750);
      @include props(r, 40, 750);

      &::before{
        @include props(l, 15, 750);
        @include props(w, 12, 750);
        @include props(h, 52, 750);
      }
      &::after{
        @include props(w, 73, 750);
        @include props(h, 52, 750);
      }

      &:hover{
        transform: translateY(-15%);
      }
    }
  }

  .copyRight{
    display: block;
    @include fz_vw(10);
    text-align: center;
    color: #808080;

    @include mq(tabMin) {
      position: absolute;
      bottom: 0;
      width: 100%;
      @include fz_vw(20);
      border-top: solid 1px #cccccc;
      background-color: #fff;
      @include props(pt, 20, 960);
      @include props(pb, 20, 960);
    }

    @include mq(md) {
      position: absolute;
      bottom: 0;
      width: 100%;
      @include fz_vw(20);
      border-top: solid 1px #cccccc;
      background-color: #fff;
      @include props(pt, 20, 750);
      @include props(pb, 20, 750);
    }
  }
}